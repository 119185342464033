// Importar librerías
import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Layout, Progress, Row, Tabs, Typography } from 'antd';
import _ from 'lodash';

// Importar otros componentes
import ProfileMenu from './ProfileMenu';
import MortageCalculator from '../Home/MortageCalculator';
import ConsumeCalculator from '../Home/ConsumeCalculator';
import ProfilePersonalInfo from './ProfilePersonalInfo';
import { fields } from './ProfilePersonalInfo';
import ProfileFinancialInfo from './ProfileFinancialInfo';
import ProfileNews from './ProfileNews';
import ProfileEducation from './ProfileEducation';
import ProfileApplications from './ProfileApplications';
import ProfileShowApplication from './ProfileShowApplication';
import ProfileHomeApplications from './ProfileHomeApplications';
import ProfileMortgageApplicationForm from './ProfileMortgageApplicationForm';
import ProfileConsumeApplicationForm from './ProfileConsumeApplicationForm';
import ProfilePortabilityConsumoAppForm from './ProfilePortabilityConsumoAppForm';
import ProfilePortabilityMortgageAppForm from './ProfilePortabilityMortgageAppForm';
import ProfileAutomotiveCreditAppForm from './ProfileAutomotiveCreditAppForm';

// Importar context de usuario autenticado
import { AuthUserContext } from '../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponentes Title y Content
const { Title } = Typography;
const { Content } = Layout;

const ProfileHomeView = ({ name }) => {
  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <Layout id="profile-content-wrapper">
      <Title style={{ fontSize: '32px' }}>{name}</Title>
      <Row gutter={[0, windowWidth < 992 ? 24 : 48]}>
        <Col flex="auto">
          <ProfileHomeApplications />
        </Col>
      </Row>
      <Row gutter={[windowWidth < 992 ? 0 : 8, windowWidth < 992 ? 12 : 48]}>
        <Col span={windowWidth < 992 ? 24 : 12} id="profile-calculator-col">
          <MortageCalculator shadow={true} />
        </Col>
        <Col span={windowWidth < 992 ? 24 : 12} id="profile-calculator-col">
          <ConsumeCalculator shadow={true} />
        </Col>
      </Row>
      <Row
        gutter={[windowWidth < 992 ? 0 : 8, windowWidth < 992 ? 24 : 48]}
        id="profile-news-education-row"
      >
        <Col span={windowWidth < 992 ? 24 : 12} id="profile-news-col">
          <ProfileNews />
        </Col>
        {windowWidth >= 992 && (
          <Col span={12} id="profile-education-col">
            <ProfileEducation />
          </Col>
        )}
      </Row>
    </Layout>
  );
};

const ProfilePersonalView = () => {
  // Obtener context de user
  const authUser = useContext(AuthUserContext);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Obtener número de campos completados por el usuario en su perfil
  const completedFields = fields.filter((f) => {
    return (
      _.get(authUser, f.name) &&
      (_.get(authUser, f.name).length > 0 || _.get(authUser, f.name).seconds)
    );
  }).length;

  // Renderizar componente extra
  const loadBar = (
    <div id="profile-info-title-icons">
      {completedFields !== fields.length && (
        <div id="profile-progress-wrapper">
          <span>{`${Math.round((100 * completedFields) / fields.length)}%`}</span>
          <Progress
            percent={(100 * completedFields) / fields.length}
            strokeColor="#E81687"
            showInfo={false}
            style={{ width: '200px' }}
          />
        </div>
      )}
    </div>
  );

  // Renderizar componente
  return (
    <Layout id="profile-content-wrapper">
      <Row gutter={[0, 24]}>
        <Col flex="auto">
          <Content id="profile-application-show-content" className="with-shadow">
            {windowWidth < 768 ? (
              <div className="profile-box-phone">
                <Title>Mi Perfil</Title>
                {loadBar}
              </div>
            ) : (
              <Title>Mi Perfil</Title>
            )}
            <Tabs
              defaultActiveKey="1"
              tabBarExtraContent={windowWidth >= 768 && loadBar}
            >
              <Tabs.TabPane tab="Información Personal" key="1">
                <ProfilePersonalInfo />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Información Financiera" key="2">
                <ProfileFinancialInfo />
              </Tabs.TabPane>
            </Tabs>
          </Content>
        </Col>
      </Row>
    </Layout>
  );
};

const ProfileShowApplicationView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfileShowApplication />
      </Col>
    </Row>
  </Layout>
);

const ProfileApplicationsView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfileApplications />
      </Col>
    </Row>
  </Layout>
);

const ProfileMortgageApplicationFormView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfileMortgageApplicationForm />
      </Col>
    </Row>
  </Layout>
);

const ProfileConsumeApplicationFormView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfileConsumeApplicationForm />
      </Col>
    </Row>
  </Layout>
);

const ProfilePortabilityMortgageAppFormView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfilePortabilityMortgageAppForm />
      </Col>
    </Row>
  </Layout>
);

const ProfilePortabilityConsumeAppFormView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfilePortabilityConsumoAppForm />
      </Col>
    </Row>
  </Layout>
);

const ProfileAutomotiveCreditAppFormView = () => (
  <Layout id="profile-content-wrapper">
    <Row gutter={[0, 24]}>
      <Col flex="auto">
        <ProfileAutomotiveCreditAppForm />
      </Col>
    </Row>
  </Layout>
);

const Profile = () => {
  // Obtener context de user
  const authUser = useContext(AuthUserContext);

  // Obtener nombre del usuario
  let name = `${authUser.firstName} ${authUser.lastName}`;
  if (name.length === 0) name = 'Tu Perfil';

  // Aplicar effect para mover el scroll de la pantalla
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Renderizar componente
  return (
    <Layout id="profile-wrapper">
      <ProfileMenu />
      <Switch>
        <Route
          path={ROUTES.PROFILE_HOME}
          render={() => <ProfileHomeView name={name} />}
        ></Route>
        <Route
          path={`${ROUTES.PROFILE_APPLICATIONS}/:id`}
          render={() => <ProfileShowApplicationView />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_INFO}
          render={() => <ProfilePersonalView authUser={authUser} />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_APPLICATIONS}
          render={() => <ProfileApplicationsView />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_APPLICATION_FORM_HIP}
          render={() => <ProfileMortgageApplicationFormView />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_APPLICATION_FORM_CON}
          render={() => <ProfileConsumeApplicationFormView />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_APPLICATION_FORM_POR_HIP}
          render={() => <ProfilePortabilityMortgageAppFormView />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_APPLICATION_FORM_POR_CON}
          render={() => <ProfilePortabilityConsumeAppFormView />}
        ></Route>
        <Route
          path={ROUTES.PROFILE_APPLICATION_FORM_AUTO_CREDIT}
          render={() => <ProfileAutomotiveCreditAppFormView />}
        ></Route>
      </Switch>
    </Layout>
  );
};

export default Profile;
