// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Divider, Form, InputNumber, Row, Radio } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import _ from 'lodash';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar otros componentes
import toPesos from '../../utils/toPesos';

// Definir campos del formulario
const fields = {
  heritage: [
    [
      {
        name: 'bienesRaices',
        label: 'Bienes Raíces',
      },
      {
        name: 'vehiculos',
        label: 'Vehículos',
      },
    ],
    [
      {
        name: 'inversiones',
        label: 'Inversiones',
      },
      {
        name: 'otrosActivos',
        label: 'Otros',
      },
    ],
  ],
  debts: [
    [
      {
        name: 'hipotecario',
        label: 'Dividendo o Arriendo',
      },
      {
        name: 'tarjetasLineasCredito',
        label: 'Tarjetas y Líneas de Crédito',
      },
    ],
    [
      {
        name: 'creditosConsumo',
        label: 'Créditos de Corto Plazo',
      },
      {
        name: 'otrosGastos',
        label: 'Otros Gastos',
      },
    ],
  ],
};

// Definir constante con los campos que manejan UF
const UFFields = ['bienesRaices', 'hipotecario'];

// Obtener información de la sesión actual
const { sessionStorage } = window;

const FinancialForm = ({
  formInstance,
  style,
  handlePreviousButtonClick,
  uf,
  sizeWindow,
}) => {
  // Obtener información del context
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [totalHeritage, setTotalHeritage] = useState(0);
  const [totalDebt, setTotalDebt] = useState(0);
  const [, setValorFocus] = useState(false);
  const [isUf, setIsUf] = useState(true);

  // TODO: Validar que hacen estos arreglos
  const heritageFields = fields.heritage.flat(1).map((f) => f.name);
  const debtsFields = fields.debts.flat(1).map((f) => f.name);

  // Definir effect para setear los valores provenientes de la BD
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglo de campos del formulario
    const fields = [
      ...heritageFields,
      ...debtsFields,
      'totalDeudas',
      'totalPatrimonio',
      'bestAnswerParam',
    ];

    // Setear valores en caso de que exista el campo lastApplicationInfo
    if (authUser && authUser.lastApplicationInfo) {
      // Obtener objeto
      const { lastApplicationInfo } = authUser;

      // Setear valores según sea el caso
      fields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (item && !_.isNaN(item)) {
          values[f] = Number(item);

          if (
            f === 'bienesRaices' &&
            lastApplicationInfo.valorMoneda &&
            lastApplicationInfo.valorMoneda !== 1
          ) {
            values[f] = values[f] / uf;
          }
        }
      });

      // Setear totales
      values['totalDeudas'] =
        (values['hipotecario'] || 0) +
        (values['tarjetasLineasCredito'] || 0) +
        (values['creditosConsumo'] || 0) +
        (values['otrosGastos'] || 0);
      values['totalPatrimonio'] =
        (values['vehiculos'] || 0) +
        (values['inversiones'] || 0) +
        (values['otrosActivos'] || 0) +
        (values['bienesRaices'] || 0);
    }

    // Setear valores en caso de que haya información en la variable sessionStorage
    fields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item === null) values[f] = Number(item);
    });

    // Setear totales
    if (values['totalDeudas']) {
      setTotalDebt(values['totalDeudas']);
    }
    if (values['totalPatrimonio']) {
      setTotalHeritage(values['totalPatrimonio']);
    }

    // Setear valores en el formulario
    formInstance.setFieldsValue(values);
    // eslint-disable-next-line
  }, [formInstance, authUser]);

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Escucha y modifica los valores asociados al valor numérico del formulario.
   */
  const onChangeValue = (changedValue, allValues) => {
    const field = Object.keys(changedValue)[0];
    const { valorMoneda, bienesRaices } = allValues;

    if (field === 'valorMoneda') {
      if (bienesRaices && valorMoneda) {
        formInstance.setFieldsValue({
          bienesRaices: bienesRaices / valorMoneda / valorMoneda / uf,
        });
        setIsUf(!isUf);
      }
    }

    if (heritageFields.includes(field)) {
      const newHeritage = heritageFields.reduce(
        (acc, field) =>
          acc +
          allValues[field] *
            (UFFields.includes(field) && valorMoneda === 1 ? uf : 1),
        0
      );
      if (!isNaN(newHeritage)) {
        formInstance.setFieldsValue({ totalPatrimonio: newHeritage });
        setTotalHeritage(newHeritage);
      }
    } else if (debtsFields.includes(field)) {
      const newDebt = debtsFields.reduce((acc, field) => acc + allValues[field], 0);
      if (!isNaN(newDebt)) {
        formInstance.setFieldsValue({ totalDeudas: newDebt });
        setTotalDebt(newDebt);
      }
    }
  };

  // Renderizar
  return (
    <Form
      name="financial-form"
      initialValues={{ valorMoneda: 1 }}
      form={formInstance}
      onValuesChange={onChangeValue}
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <h3>Activos</h3>
      {fields.heritage.map((r, i) => (
        <Row gutter={(0, 12)} key={i}>
          {r.map((f) => (
            <>
              <Col
                span={
                  sizeWindow < 992
                    ? f.name === 'bienesRaices'
                      ? 16
                      : 24
                    : f.name === 'bienesRaices'
                    ? 9
                    : 12
                }
                key={f.name}
              >
                <Form.Item
                  name={f.name}
                  key={f.name}
                  label={`${f.label} ${f.name === 'bienesRaices' ? '' : '(CLP)'}`}
                  hasFeedback
                  initialValue={0}
                  rules={[
                    {
                      message: 'Debes ingresar un valor en este campo',
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={
                      f.name === 'bienesRaices'
                        ? isUf
                          ? 9999999
                          : 999999999999
                        : 999999999999
                    }
                    precision={0}
                    style={{ width: '100%' }}
                    formatter={toPesos}
                    parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                    onClick={(e) => e.target.select()}
                    onFocus={() => setValorFocus(true)}
                    onBlur={() => setValorFocus(false)}
                  />
                </Form.Item>
              </Col>
              {f.name === 'bienesRaices' && (
                <Col span={sizeWindow < 992 ? 8 : 3}>
                  <Form.Item name="valorMoneda" label=" ">
                    <Radio.Group>
                      <Radio.Button value={1}>UF</Radio.Button>
                      <Radio.Button value={1 / uf}>CLP</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
            </>
          ))}
        </Row>
      ))}

      <div className="application-form-figure">
        <span>Total Activos</span>
        <span>{`CLP ${toPesos(totalHeritage)}`}</span>
      </div>

      <Divider style={{ borderColor: '#050358', opacity: 0.5, marginTop: '16px' }} />

      <h3>Gastos Mensuales</h3>
      {fields.debts.map((r, i) => (
        <Row gutter={(0, 12)} key={i}>
          {r.map((f) => (
            <Col span={sizeWindow < 768 ? 24 : 12} key={f.name}>
              <Form.Item
                name={f.name}
                key={f.name}
                label={`${f.label} (CLP)`}
                hasFeedback
                initialValue={0}
                rules={[
                  {
                    message: 'Debes ingresar un valor en este campo',
                    required: true,
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={999999999999}
                  style={{ width: '100%' }}
                  precision={0}
                  formatter={toPesos}
                  parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                  onClick={(e) => e.target.select()}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      ))}
      <div className="application-form-figure">
        <span>Total Gastos</span>
        <span>{`CLP ${toPesos(totalDebt)}`}</span>
      </div>

      <Divider style={{ borderColor: '#050358', opacity: 0.5, marginTop: '16px' }} />

      <Form.Item name="totalDeudas" style={{ display: 'none' }} initialValue={0} />
      <Form.Item
        name="totalPatrimonio"
        style={{ display: 'none' }}
        initialValue={0}
      />

      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <Button
            className="application-form-button application-form-button-secondary"
            onClick={handlePreviousButtonClick}
          >
            <ArrowLeftOutlined />
            Anterior
          </Button>
        </Col>
        <Col>
          <Button className="application-form-button" htmlType="submit">
            Siguiente
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FinancialForm;
