// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
} from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

// Importar context
import { FirebaseContext } from '../../context';

// Importar otros componentes
import Loading from '../Loading';
import { ReactComponent as DataCreditIcon } from './icons/dataCredit.svg';
import { ReactComponent as TimeLeftIcon } from './icons/timeLeft.svg';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Establecer la moneda local
moment.locale('es');

// Definir un objeto con valores necesarios para la renderización
const paramToValue = {
  cuota: 'cuotaMensual1',
  credito: 'costoTotal1',
};

const ProfileShowConsumeApplicationResult = () => {
  // Definir nueva instancia de route
  const route = useRouteMatch();

  // Obtener context de firebases
  const firebase = useContext(FirebaseContext);

  // Obtener ruta de la aplicación
  const applicationUid = route.params.id;

  // Obtener información de la BD
  const [value] = useDocument(firebase.applicationLogic.application(applicationUid));

  // Definir state
  const [data, setData] = useState({});
  const [bestAnswer, setBestAnswer] = useState();
  const [loading, setLoading] = useState(true);
  const [modalStatus, setModalStatus] = useState();
  const [offerURL, setOfferURL] = useState('');
  const [sendingReport, setSendingReport] = useState(false);
  const [commonRequirements, setCommonRequirements] = useState([]);

  // Definir effect para setear la información obtenida de la BD
  useEffect(() => {
    if (value) {
      setData(value.data());
      if (value.data().answers.length) {
        const param = paramToValue[value.data().bestAnswerParam];
        const selectedAnswer = value
          .data()
          .answers.filter((a) => a.status !== 'noOffer')
          .reduce((prev, current) =>
            prev.answer[param] > current.answer[param] ? current : prev
          );

        if (!_.isEmpty(selectedAnswer)) setBestAnswer(selectedAnswer);
      }

      setLoading(false);
    }
  }, [value]);

  // TODO: Definir uso de este effect
  useEffect(() => {
    if (data.winner && data.winner.answer) {
      firebase.getDownloadURL(data.winner.answer.answerFiles[0]).then((url) => {
        setOfferURL(url);
      });
    }
  }, [data.winner, firebase]);

  // Definir effect para obtener información del ejecutivo ganador
  useEffect(() => {
    const getRequirementsBestExecutive = async () => {
      // Obtener información del ejecutivo
      const executive = await firebase.db
        .doc(`users/${bestAnswer.executiveId}`)
        .get();
      setCommonRequirements(executive.get('commonRequirements'));
    };

    if (bestAnswer) {
      getRequirementsBestExecutive();
    }
  }, [firebase, bestAnswer]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Actualiza la información de la BD con una oferta aceptada.
   */
  const acceptOffer = async () => {
    // Setear estado de carga
    setModalStatus('loading');

    // Intentar actualizar la información en la BD
    try {
      // Actualizar información de los requerimientos
      if (!_.isEmpty(commonRequirements)) {
        const values = {
          applicationId: value.id,
          commonRequirements,
        };
        await firebase.applicationLogic.loadRequirements(values);
      }

      // Actualizar información del ganador
      await value.ref.update({
        winner: bestAnswer,
        status: 'accepted',
      });

      // Setear estado de éxito
      setModalStatus('success');
    } catch (error) {
      console.log(error.message);
      setModalStatus('error');
    }
  };

  /**
   * Actualiza la información de la BD con una oferta rechazada.
   */
  const rejectOffer = () => {
    setModalStatus('rejectLoading');
    value.ref
      .update({
        status: 'userRejected',
        loser: { executiveId: bestAnswer.executiveId },
      })
      .then(() => {
        setModalStatus('rejectSuccess');
      })
      .catch((e) => {
        console.log(e);
        setModalStatus('error');
      });
  };

  // Renderizar diversas partes del componente actual
  const creditDataTitle = () => {
    return (
      <div>
        <DataCreditIcon />
        <span>Datos del Crédito</span>
      </div>
    );
  };

  const answerDescriptionTitle = () => {
    return <div>Dividendo</div>;
  };

  /**
   *
   * @param {*} report
   * Envía un reporte vía correo sobre la oferta de una cotización.
   */
  const report = ({ report }) => {
    setSendingReport(true);
    const hide = message.loading('Enviando reporte...');
    firebase.functions
      .httpsCallable('reportProblemMailer')({
        applicationId: applicationUid,
        body: report,
      })
      .then(() => {
        hide();
        setModalStatus();
        message.success('¡Su mensaje ha sido enviado!');
      })
      .catch((err) => {
        hide();
        message.error('Hubo un error al enviar su mensaje');
      })
      .finally(() => {
        setSendingReport(false);
      });
  };

  // Renderizar ventana modal según sea la oferta de una cotización
  const ModalContent = () => {
    let resultProps;
    switch (modalStatus) {
      case 'loading':
        resultProps = {
          icon: <LoadingOutlined />,
          title: 'Aceptando Cotización',
          subTitle: 'Por favor, espere unos segundos...',
        };
        return <Result {...resultProps} />;

      case 'success':
        resultProps = {
          status: 'success',
          title: '¡Oferta Aceptada!',
          subTitle: 'Su oferta ha sido aceptada exitosamente.',
          extra: [<Button onClick={() => window.open(offerURL)}>Descargar</Button>],
        };
        return <Result {...resultProps} />;

      case 'error':
        resultProps = {
          status: 'error',
          title: 'Ocurrio un Error',
          subTitle:
            'Por favor, intenta nuevamente. Si el error persiste, contáctate con nuestro equipo.',
        };
        return <Result {...resultProps} />;

      case 'rejectLoading':
        resultProps = {
          icon: <LoadingOutlined />,
          title: 'Rechazando Cotización',
          subTitle: 'Por favor, espere unos segundos...',
        };
        return <Result {...resultProps} />;

      case 'rejectSuccess':
        resultProps = {
          status: 'success',
          title: 'Oferta Rechazada',
          subTitle: 'Su oferta ha sido rechazada exitosamente.',
        };
        return <Result {...resultProps} />;

      case 'cancel':
        resultProps = {
          title: '¡Importante!',
          subTitle: 'Esta acción no se puede deshacer',
          extra: [<Button onClick={() => rejectOffer()}>Rechazar oferta</Button>],
        };
        return <Result {...resultProps} />;

      case 'confirm':
        resultProps = {
          title: '¡Importante!',
          subTitle:
            'Al aceptar la solicitud estarás gestionando el crédito a través de Flit, por lo tanto, cualquier contacto con tu ejecutivo por otro medio cancelará el proceso.',
          extra: [<Button onClick={() => acceptOffer()}>Aceptar</Button>],
        };
        return <Result {...resultProps} />;

      case 'report':
        return (
          <div className="application-result-modal">
            <h3>Reportar un Problema</h3>
            <p>
              Si la información que has recibido no coincide con la oferta del banco,
              haz click en el siguiente botón y nos pondremos en contacto contigo.
            </p>
            <Form layout="vertical" onFinish={report}>
              <Form.Item
                name="report"
                label="Motivo del reporte (max 200 caracteres)"
                type="string"
                rules={[
                  {
                    required: true,
                    message: 'Debes escribir el motivo de tu reporte',
                  },
                  {
                    max: 200,
                    message: 'Tu reporte no puede superar los 200 caracteres',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <div className="flex-center">
                <Button
                  htmlType="submit"
                  className="primary-button"
                  loading={sendingReport}
                >
                  Reportar
                </Button>
              </div>
            </Form>
          </div>
        );

      default:
        resultProps = {
          icon: <></>,
        };
        return <Result {...resultProps} />;
    }
  };

  // Renderizar comoponente Loading en caso de que no hay información
  if (loading) return <Loading />;

  // Obtiene el día de una fecha actual y lo extiende a 3
  const validUntil = moment(data.endDate.toDate()).add(3, 'd');

  // Renderizar componente
  return (
    <>
      <Modal
        visible={modalStatus}
        footer={false}
        onCancel={() => setModalStatus()}
        className="application-result-modal"
        width={windowWidth < 768 ? 350 : 520}
      >
        <ModalContent />
      </Modal>

      {/* Credit Data */}
      <Descriptions
        title={creditDataTitle()}
        layout="vertical"
        className="application-show-descriptions application-credit-data"
        column={{ xs: 2, md: 4 }}
        colon={false}
      >
        <Descriptions.Item label="Banco">{bestAnswer.bankName}</Descriptions.Item>
        <Descriptions.Item label="Monto del Crédito">
          $ {toPesos(data.credito)}
        </Descriptions.Item>
        <Descriptions.Item label="Cuotas">{data.plazo}</Descriptions.Item>
        {data.fechaPrimerPago ? (
          <Descriptions.Item label="Fecha Primer Pago">
            {moment(data.fechaPrimerPago.toDate()).format('L')}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label="Crédito Bullet">
            Un Solo Pago Final
          </Descriptions.Item>
        )}
      </Descriptions>

      {/* Answer description */}
      <Descriptions
        title={answerDescriptionTitle()}
        layout="vertical"
        className="application-show-descriptions application-answer-description"
        column={{ xs: 2, md: 4 }}
        colon={false}
      >
        <Descriptions.Item label="Tasa Mensual">
          {bestAnswer.answer.tasaMensual}%
        </Descriptions.Item>
        <Descriptions.Item label="CAE Anual">
          {bestAnswer.answer.cae1}%
        </Descriptions.Item>
        <Descriptions.Item label="Cuota Mensual">
          $ {toPesos(bestAnswer.answer.cuotaMensual1)}
        </Descriptions.Item>
        <Descriptions.Item label="Costo Total Crédito">
          $ {toPesos(bestAnswer.answer.costoTotal1)}
        </Descriptions.Item>
      </Descriptions>

      <Row>
        {/* <Col span={14}>
          <Table
            title={expensesDescriptionTitle}
            className="application-show-table application-expenses-description"
            columns={columns}
            dataSource={expensesData}
            pagination={false}
          />
        </Col> */}
        <Col
          span={windowWidth < 768 ? 24 : windowWidth < 992 ? 16 : 10}
          offset={windowWidth < 768 ? 0 : windowWidth < 992 ? 4 : 7}
        >
          {bestAnswer && data.status === 'open' && moment().isBefore(validUntil) && (
            <>
              <div className="time-left">
                <TimeLeftIcon />
                <p>Oferta válida hasta: </p>
                <p>{validUntil.calendar()}</p>
              </div>
              <div className="content-info-text">
                <p>
                  Tienes 72 horas para aceptar la oferta. Cualquier duda, puedes
                  comunicarte con tu ejecutivo a través del chat.
                </p>
              </div>
              <Divider style={{ margin: '20px 0 16px' }} />

              <Row style={{ justifyContent: 'space-around' }}>
                <Col>
                  <Button
                    className="white-button"
                    onClick={() => setModalStatus('cancel')}
                  >
                    Rechazar oferta
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="primary-button"
                    onClick={() => setModalStatus('confirm')}
                  >
                    Aceptar oferta
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {bestAnswer && data.status === 'open' && moment().isAfter(validUntil) && (
            <>
              <div className="time-left" style={{ marginBottom: '16px' }}>
                <TimeLeftIcon />
                <p>Su oferta caducó</p>
              </div>
            </>
          )}

          {bestAnswer && data.status === 'accepted' && (
            <>
              <p
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: '8px',
                  color: '#01003A',
                }}
              >
                Confirma esta información con la oferta del banco
              </p>
              <div
                className="time-left clickable"
                onClick={() => window.open(offerURL)}
              >
                <p>Descargar Oferta Banco</p>
                <DownloadOutlined style={{ position: 'static', height: 'auto' }} />
              </div>
              <Divider style={{ margin: '32px 0 16px' }} />
              <Row style={{ justifyContent: 'center' }}>
                <Col>
                  <Button
                    className="primary-button"
                    onClick={() => setModalStatus('report')}
                    style={{ width: '100%' }}
                  >
                    Reportar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProfileShowConsumeApplicationResult;
