import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, List } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { withFirebase } from '../../utils';
import * as ROUTES from '../../constants/routes';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;

const renderArticle = (item) => {
  const { userRef, createdAt, ...rest } = item;

  const time = moment(createdAt.toDate());

  return (
    <div className="news-card">
      <img alt="news" src={item.photoUrl} />
      <div className="news-card-info">
        <div className="date">{time.format('D [de] MMMM').toUpperCase()}</div>
        <div className="title">{item.title}</div>
        <div className="subtitle">{item.summary}</div>
      </div>
      {rest.type === 'link' ? (
        <a href={rest.link} target="_blank" rel="noopener noreferrer">
          <ArrowRightOutlined />
        </a>
      ) : (
        <NavLink
          target="_blank"
          rel="noopener noreferrer"
          to={{
            pathname: `${ROUTES.ARTICLES_PAGE}/${rest.uid}`,
            state: { article: rest },
          }}
        >
          <ArrowRightOutlined />
        </NavLink>
      )}
    </div>
  );
};
class ProfileNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      articles: [],
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.articleLogic
      .articles()
      .orderBy('createdAt', 'desc')
      .limit(4)
      .onSnapshot((snapshot) => {
        let articles = [];
        let counter = 0;
        snapshot.forEach((doc) => {
          let article = { ...doc.data(), uid: doc.id, key: counter++ };
          article.userRef.onSnapshot((snap) => {
            const userData = snap.data();
            article.userData = userData;
          });

          articles.push(article);
        });

        this.setState({
          articles,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { articles, loading } = this.state;

    return (
      <Content span={8} id="profile-news-container" className="with-shadow">
        <h1>
          <NavLink to={ROUTES.HOME}>Noticias</NavLink>
        </h1>
        {loading && <div>Loading ...</div>}
        <List
          itemLayout="vertical"
          size="large"
          dataSource={articles.slice(0, 3)}
          footer={<div></div>}
          renderItem={renderArticle}
        />
      </Content>
    );
  }
}

export default withFirebase(ProfileNews);
