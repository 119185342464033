// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Row,
  Slider,
  Tooltip,
  Input,
} from 'antd';
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import _ from 'lodash';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar context de user
import { AuthUserContext } from '../../context';

// Definir información del ícono de un sólo pago
const iconMessage =
  'Este es un crédito donde el monto prestado y los intereses mensuales se pagan todo juntos al final del periodo';

// Definir arreglo con las opciones del Checkbox para el manejo de los seguros
export const segurosOptions = [
  { value: 'desgravamen', label: 'Desgravamen' },
  { value: 'cesantia', label: 'Cesantía' },
  { value: 'vida', label: 'Vida + Hospitalización' },
];

// Definir arreglo con las opciones del Radio para las condiciones de pago del crédito
const paymentConditionOptions = [
  { value: 'cuotas', label: 'Cuotas' },
  { value: 'pago unico', label: 'Un Solo Pago' },
];

// Obtener información de la sesión
const { sessionStorage } = window;

const CreditInfoForm = (props) => {
  // Definir información del context
  const authUser = useContext(AuthUserContext);

  /// Aplicar destructuración
  const { formInstance, style, sizeWindow } = props;

  // Definir state local
  const [paymentOption, setPaymentOption] = useState('cuotas');

  // Definir effect para setear los valores del formulario
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglo de campos del formulario
    const fields = [
      'estadoLaboral',
      'seguros',
      'plazo',
      'credito',
      'bestAnswerParam',
    ];

    // Setear valores en caso de que exista el campo lastApplicationInfo
    if (authUser && authUser.lastApplicationInfo) {
      // Obtener objeto
      const { lastApplicationInfo } = authUser;

      // Setear valores según sea el caso
      fields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (item && !_.isNaN(item)) {
          if (Number(item)) {
            values[f] = Number(item);
          }
        }
      });
    }

    // Setear valores en caso de que haya información en la variable sessionStorage
    fields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item && !_.isNaN(item)) {
        if (Number(item)) {
          values[f] = Number(item);
        }
      }
    });

    // Fijar Plazo en caso de que no esté definido
    if (!values.plazo) values.plazo = 12;

    // Colocar valores en formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  // Renderizar componente
  return (
    <Form
      name="creedit-status-form"
      id="credit-status-form"
      form={formInstance}
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="nombre"
            label="Nombre de la Cotización"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Coloca el nombre de la cotización',
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="credito"
            label="Monto del Crédito (CLP)"
            type="number"
            hasFeedback
            rules={[
              {
                message: 'Ingresa el monto de tu crédito',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Row>
            <Col span={24} style={sizeWindow < 768 ? null : { marginTop: '20px' }}>
              <Row>
                <Col span={18}>
                  <Form.Item
                    name="paymentCondition"
                    label={'¿Cómo te gustaría pagar tu crédito?'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecciona el parametro',
                      },
                    ]}
                    style={{ alignItems: 'center' }}
                    initialValue={'cuotas'}
                  >
                    <Radio.Group
                      options={paymentConditionOptions}
                      onChange={(e) => setPaymentOption(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                  }}
                >
                  {paymentOption === 'pago unico' && (
                    <Form.Item>
                      <Tooltip
                        placement="top"
                        title={iconMessage}
                        style={{ width: sizeWindow < 768 ? '50px' : '70px' }}
                        color="#e81687"
                      >
                        <ExclamationCircleOutlined className="info-icon-style" />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: '10px' }}>
              {paymentOption === 'cuotas' && (
                <Form.Item
                  name="fechaPrimerPago"
                  label="Fecha Primer Pago"
                  type="date"
                  hidden={paymentOption !== 'cuotas'}
                  hasFeedback
                  rules={[
                    {
                      message: 'Ingresa la fecha de tu primer pago',
                      required: true,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} locale={locale} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Row style={sizeWindow < 768 ? { marginTop: '15px' } : null}>
            <Col span={24}>
              <Form.Item name="plazo" label="Plazo (meses)">
                <Slider
                  marks={{
                    1: '1 mes',
                    30: '30 meses',
                    60: '60 meses',
                  }}
                  min={1}
                  max={60}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginTop: '10px' }}>
              <Form.Item
                name="seguros"
                label="Seguros *"
                help="* No es obligatorio elegir seguros en los créditos de consumo"
                initialValue={[]}
              >
                <Checkbox.Group options={segurosOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ justifyContent: 'flex-end' }}>
        <Col>
          <Button className="application-form-button" htmlType="submit">
            Siguiente
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditInfoForm;
