// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Menu } from 'antd';
import {
  BarChartOutlined,
  HomeFilled,
  LogoutOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

// Importar context de firebase y de user
import { FirebaseContext, AuthUserContext } from '../../context';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import { fields } from './ProfilePersonalInfo';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const ProfileMenu = () => {
  // Definir nueva instancia de useHistory
  const history = useHistory();

  // Definir state
  const [showWarningIcon, setShowWarningIcon] = useState(false);

  // Definir context
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Arreglo de rutas que muestran cierta información dentro de la vista actual
  const pathToKey = {
    [`${ROUTES.PROFILE_HOME}`]: ROUTES.PROFILE_HOME,
    [`${ROUTES.PROFILE_INFO}`]: ROUTES.PROFILE_INFO,
    [`${ROUTES.PROFILE_APPLICATIONS}`]: ROUTES.PROFILE_APPLICATIONS,
    [`${ROUTES.PROFILE_APPLICATION_FORM_HIP}`]: ROUTES.PROFILE_APPLICATIONS,
  };

  // Almacena la subruta actual de la vista. Sus valores son '/perfil/home'
  // o 'perfil/info' o 'perfil/cotizaciones'
  const pathnameDepth2 = history.location.pathname
    .split('/')
    .splice(0, 3)
    .join('/');

  // Definir effect para obtener el valor de las solicitudes
  useEffect(() => {
    const getApps = async () => {
      // Obtener información de las cotizaciones
      const values = await firebase.db
        .collection('applications')
        .where('user.id', '==', authUser.uid)
        .get();

      // Validar si existen solicitudes y setear el valor del booleano que muestra el ícono
      // si se dan las condiciones
      if (values.docs.length) {
        const appWarnings = values.docs.filter(
          (item) =>
            item.get('status') === 'accepted' &&
            item.get('chat').length >= 1 &&
            item.get('chat')[item.get('chat').length - 1].senderId !== authUser.uid
        );
        setShowWarningIcon(appWarnings.length >= 1);
      }
    };

    if (pathnameDepth2 === '/perfil/cotizaciones') {
      getApps();
    }
  }, [pathnameDepth2, authUser, firebase]);

  // Almacena la longitud de un arreglo cuya información corresponde a los
  // campos completados por el usuario en su Perfil
  const completedFields = fields.filter(
    (f) =>
      authUser[f.name] && (authUser[f.name].length > 0 || authUser[f.name].seconds)
  ).length;

  // Almacena el % de Mi Perfil según el usuario vaya completando su información
  const profilePercent =
    completedFields !== fields
      ? ` (${Math.round((100 * completedFields) / fields.length)}%)`
      : '';

  /**
   *
   * @param {*} e
   * Agrega una nueva ruta al path histórico de rutas que permite la navegación dentro
   * de la app con la finalidad de mostrar la información del usuario según sea el caso.
   */
  const handleClick = (e) => history.push(e.key);

  // Renderizar componente
  return (
    <Menu
      mode="inline"
      className="profile-menu"
      onClick={handleClick}
      selectedKeys={[pathToKey[pathnameDepth2]]}
    >
      <Menu.Item key={ROUTES.PROFILE_HOME}>
        <HomeFilled />
        Inicio
      </Menu.Item>
      <Menu.Item key={ROUTES.PROFILE_INFO}>
        <UserOutlined />
        Mi Perfil
        <p className="magenta">{profilePercent}</p>
      </Menu.Item>
      <Menu.Item key={ROUTES.PROFILE_APPLICATIONS}>
        <BarChartOutlined />
        Cotizaciones
        {showWarningIcon && (
          <ExclamationCircleOutlined
            className="info-icon-style"
            style={{ marginLeft: '5px', marginTop: '3px' }}
          />
        )}
      </Menu.Item>
      {windowWidth >= 992 && (
        <Menu.Item>
          <Divider />
        </Menu.Item>
      )}
      <Menu.Item key="logout" onClick={() => firebase.auth.signOut()}>
        <LogoutOutlined />
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );
};

export default ProfileMenu;
