// Iportar librerías
import React, { useState, useEffect, useContext } from 'react';
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Slider,
  Checkbox,
  message,
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react';
import config from '../../config/firebase';
import _ from 'lodash';

// Importar otros componentes
import { ReactComponent as BancoBiceIcon } from '../PortabilityConsumeAppForm/icons/bancobice.svg';
import { ReactComponent as BCIIcon } from '../PortabilityConsumeAppForm/icons/bci.svg';
import { ReactComponent as BancoConsorcioIcon } from '../PortabilityConsumeAppForm/icons/bancoconsorcio.svg';
import { ReactComponent as BancoChileIcon } from '../PortabilityConsumeAppForm/icons/bancochile.svg';
import { ReactComponent as BancoEstadoIcon } from '../PortabilityConsumeAppForm/icons/bancoestado.svg';
import { ReactComponent as BancoFalabellaIcon } from '../PortabilityConsumeAppForm/icons/bancofalabella.svg';
import { ReactComponent as BancoInternacionalIcon } from '../PortabilityConsumeAppForm/icons/bancointernacional.svg';
import { ReactComponent as BancoSecurityIcon } from '../PortabilityConsumeAppForm/icons/bancosecurity.svg';
import { ReactComponent as CooperativaAhorroCreditoIcon } from '../PortabilityConsumeAppForm/icons/cooperativaahorroycredito.svg';
import { ReactComponent as ItauIcon } from '../PortabilityConsumeAppForm/icons/itau.svg';
import { ReactComponent as SantanderIcon } from '../PortabilityConsumeAppForm/icons/santander.svg';
import { ReactComponent as ScotiabankIcon } from '../PortabilityConsumeAppForm/icons/scotiabank.svg';
import { ReactComponent as OtroIcon } from '../PortabilityConsumeAppForm/icons/otro.svg';

// Importar otros componentes
import toPesos from '../../utils/toPesos';

// Importar constantes
import banks from '../../constants/banks';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar subcomopnente del compoente Select
const { Option } = Select;

// Obtener información de la sesión actual
const { sessionStorage } = window;

// Definir información de select para las propiedades
const propiedadOptions = [
  { value: 'casa', label: 'Casa' },
  { value: 'comercial', label: 'Comercial' },
  { value: 'departamento', label: 'Departamento' },
  { value: 'oficina', label: 'Oficina' },
  { value: 'terreno', label: 'Terreno' },
  { value: 'otro', label: 'Otro' },
];

// Definir información del select para el estado de las propiedades
const propertyConditionOptions = [
  { value: 'nueva', label: 'Nueva' },
  { value: 'usada', label: 'Usada' },
];

// Definir información de los seguros para el checkbox
export const insuranceOptions = [
  { value: 'desgravamen', label: 'Desgravamen', disabled: true },
  { value: 'incendios', label: 'Incendios', disabled: true },
  { value: 'sismos', label: 'Sismos *', disabled: false },
];

// Definir coordenadas y zoon por defecto para el componente GoogleMapReact
const DEFAULT_CENTER = {
  lat: -33.4472,
  lng: -70.6506,
};

const DEFAULT_ZOOM = 10;

const GeneralForm = ({ formInstance, style, uf, sizeWindow }) => {
  // Obtner información del context
  const authUser = useContext(AuthUserContext);

  // Definir state local
  const [adressInputStatus, setAdressInputStatus] = useState('');
  const [adressOptions, setAdressOptions] = useState([]);
  const [map, setMap] = useState({});
  const [maps, setMaps] = useState({});
  const [mapMarker, setMapMarker] = useState();
  const [placesService, setPlacesService] = useState({});
  const [, setValorFocus] = useState(false);
  const [credit, setCredit] = useState();
  const [isUfValue, setIsUfValue] = useState(true);
  const [bankSmallNameFlit, setBankSmallNameFlit] = useState(null);
  const [bankBigNameFlit, setBankBigNameFlit] = useState(null);

  // Definir nueva instancia del servicio de autocompletado de GoogleMap
  const autocompleteService = new window.google.maps.places.AutocompleteService();

  // Definir effect para setear losvalores iniciales del formulario
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglos de campos numéricos y strings
    const numberFields = ['plazo', 'valorMoneda', 'credito', 'banco'];
    const stringFields = ['nombre', 'propiedad', 'direccion', 'propertyCondition'];

    // Setear valores en caso de que haya información en la variable sessionStorage
    numberFields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item && !_.isNaN(item)) {
        values[f] = Number(item);
      }
    });

    stringFields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item) values[f] = item;
    });

    // Fijar Plazo en caso de que no esté definido
    if (!values.plazo) values.plazo = 30;

    // Actualizar valores de los etsados inputStatus y credit
    if (values['direccion']) setAdressInputStatus('success');
    if (values['credito']) setCredit(values['credito']);

    // Setear valores en el formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Escucha y modifica los valores asociados al valor numérico del formulario.
   */
  const onChangeValue = (changedValue, allValues) => {
    const field = Object.keys(changedValue)[0];
    const { credito, valorMoneda } = allValues;

    // Cambiar valores según sea el caso
    if (field === 'valorMoneda') {
      if (credito && valorMoneda) {
        const credit = credito / valorMoneda / valorMoneda / uf;
        formInstance.setFieldsValue({
          credito: credit,
        });
        if (valorMoneda === 1) {
          setCredit(credit);
        }
      }
      setIsUfValue(!isUfValue);
    }

    if (field === 'credito') {
      if (valorMoneda === 1) {
        setCredit(credito);
      } else {
        setCredit(credito / uf);
      }
    }
  };

  /**
   * Se utiliza para autocompletar la dirección dada por el usuario.
   */
  const getAdressOptions = () =>
    adressOptions.map((a) => ({
      value: a.description,
    }));

  /**
   * Setea un error en caso de que la información de la dirección no sea correcta.
   */
  const handleAdressBlur = () => {
    if (adressInputStatus !== 'success') setAdressInputStatus('error');
  };

  /**
   *
   * @param {*} newValue
   * Ajusta la dirección en el mapa en caso de que el usuario la haya cambiado.
   */
  // Map functions
  const handleAdressChange = (newValue) => {
    setAdressInputStatus('');
    map.setCenter(DEFAULT_CENTER);
    map.setZoom(DEFAULT_ZOOM);
    if (mapMarker) mapMarker.setMap(null);
    if (newValue === '') return;
    autocompleteService.getPlacePredictions({ input: newValue }, (res) =>
      setAdressOptions(res || [])
    );
  };

  /**
   *
   * @param {*} e
   * Escucha por la dirección seleccionada de la lista de sugerencias y hace
   * los ajustes y actualizaciones correspondientes.
   */
  const handleAdressSelect = (e) => {
    setAdressInputStatus('success');
    var placeId = '';
    adressOptions.forEach((a) => {
      if (a.description === e) {
        placeId = a.place_id;
      }
    });
    placesService.getDetails(
      {
        placeId: placeId,
      },
      getPlaceDetailsCallback
    );
  };

  /**
   *
   * @param {*} details
   * Establece un marcador en el mapa según la longitud y latitud de una dirección dada.
   */
  const getPlaceDetailsCallback = (details) => {
    setMapMarker(
      new maps.Marker({
        position: {
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        },
        map,
      })
    );
    map.setZoom(15);
    map.setCenter({
      lat: details.geometry.location.lat(),
      lng: details.geometry.location.lng(),
    });
  };

  /**
   *
   * @param {*} map
   * @param {*} maps
   * Setea la configuración del mapa con la dirección actual al igual que el servicio.
   */
  const handleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
    setPlacesService(new window.google.maps.places.PlacesService(map));
  };

  /**
   * Valida si hay una dirección correta y ajusta el estado de la variable
   * addressInputStatus.
   */
  // Check if there is a valid adress on submit
  const handleSubmit = () => {
    formInstance.validateFields();
    if (adressInputStatus === 'success') formInstance.submit();
    else {
      setAdressInputStatus('error');
      message.info('Aún no se ha seleccionado una dirección');
    }
  };

  // Definir componente a renderizar
  const CardBanksComponent = () => {
    return (
      <div className="container-card-bank">
        <div className="card-bank">
          <BancoBiceIcon />
          <Radio value="Banco BICE" />
        </div>
        <div className="card-bank">
          <BCIIcon />
          <Radio value="BCI" />
        </div>
        <div className="card-bank">
          <BancoConsorcioIcon />
          <Radio value="Banco Consorcio" />
        </div>
        <div className="card-bank">
          <BancoChileIcon />
          <Radio value="Banco de Chile" />
        </div>
        <div className="card-bank">
          <BancoEstadoIcon />
          <Radio value="Banco Estado" />
        </div>
        <div className="card-bank">
          <BancoFalabellaIcon />
          <Radio value="Banco Falabella" />
        </div>
        <div className="card-bank">
          <BancoInternacionalIcon />
          <Radio value="Banco Internacional" />
        </div>
        <div className="card-bank">
          <BancoSecurityIcon />
          <Radio value="Banco Security" />
        </div>
        <div className="card-bank">
          <CooperativaAhorroCreditoIcon />
          <Radio value="Cooperativa de Ahorro y Crédito" />
        </div>
        <div className="card-bank">
          <ItauIcon />
          <Radio value="Itaú" />
        </div>
        <div className="card-bank">
          <SantanderIcon />
          <Radio value="Santander" />
        </div>
        <div className="card-bank">
          <ScotiabankIcon />
          <Radio value="Scotiabank" />
        </div>
        <div className="card-bank">
          <OtroIcon />
          <p>
            <b>Otro Banco</b>
          </p>
          <Radio value="Otro" />
        </div>
      </div>
    );
  };

  /**
   *
   * @param {*} errorFields
   * Muestra un mensaje de error en caso de que haya habido un problema con la información
   * del formulario.
   */
  const onFinishFailed = (errorFields) => {
    console.log(errorFields);
    message.info('Existen campos del formulario que aún no han sido completados');
  };

  // Renderizar
  return (
    <Form
      name="general-form"
      form={formInstance}
      initialValues={{ banco: 'Banco BICE', valorMoneda: 1 }}
      onValuesChange={onChangeValue}
      onFinishFailed={onFinishFailed}
      id="application-general-form"
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="nombre"
            label="Nombre de la Cotización"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona el nombre de la propiedad',
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 7}>
          <Form.Item
            name="propiedad"
            label="¿Qué tipo de propiedad quieres refinanciar?"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona un tipo de propiedad',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {propiedadOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 5}>
          <Form.Item
            name="propertyCondition"
            label="Condición de la propiedad"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona la condición de la propiedad',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {propertyConditionOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="direccion"
        label="Dirección"
        hasFeedback
        validateStatus={adressInputStatus}
        rules={[
          {
            required: true,
            message: 'Selecciona una dirección',
          },
        ]}
        help="Selecciona una dirección desde la lista de sugerencias"
      >
        <AutoComplete
          options={getAdressOptions()}
          onBlur={handleAdressBlur}
          onChange={handleAdressChange}
          onSelect={handleAdressSelect}
        ></AutoComplete>
      </Form.Item>

      <div className="application-form-map-wrapper">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.apiKey }}
          defaultCenter={DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      </div>

      <Row gutter={(0, 12)}>
        {sizeWindow < 768 && (
          <>
            <Col span={24}>
              <Form.Item
                name="banco"
                label="Banco Actual del Crédito"
                hasFeedback
                rules={[
                  {
                    message: 'Selecciona el banco actual del crédito',
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona"
                  onSelect={(value) => setBankSmallNameFlit(value)}
                >
                  {banks.map((bank) => (
                    <Option key={bank} value={bank}>
                      {bank}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {bankSmallNameFlit === 'Otro' && (
              <Col span={24}>
                <Form.Item
                  name="otroBanco"
                  label="Nombre del Banco"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Coloca el nombre del banco',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </>
        )}

        {sizeWindow >= 768 && (
          <>
            <Col span={24}>
              <Form.Item
                name="banco"
                label="Banco Actual del Crédito"
                rules={[
                  {
                    required: true,
                    message: 'Selecciona el nombre del banco',
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setBankBigNameFlit(e.target.value)}>
                  <CardBanksComponent />
                </Radio.Group>
              </Form.Item>
            </Col>
            {bankBigNameFlit === 'Otro' && (
              <Col span={24}>
                <Form.Item
                  name="otroBanco"
                  label="Nombre del Banco"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Coloca el nombre del banco',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 992 ? 24 : 12}>
          <Row
            gutter={(0, 12)}
            style={sizeWindow < 992 ? { marginBottom: '20px' } : ''}
          >
            <Col span={sizeWindow < 992 ? 16 : 17}>
              <Form.Item
                name="credito"
                label="Monto Vigente del Crédito"
                hasFeedback
                rules={[
                  {
                    message: 'Selecciona el valor del crédito',
                    required: true,
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  step={1000}
                  max={isUfValue ? 9999999 : 999999999999}
                  precision={0}
                  onFocus={() => setValorFocus(true)}
                  onBlur={() => setValorFocus(false)}
                  formatter={(value) => toPesos(value)}
                  parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                  onClick={(e) => e.target.select()}
                />
              </Form.Item>
            </Col>
            <Col span={sizeWindow < 992 ? 8 : 6}>
              <Form.Item name="valorMoneda" label=" ">
                <Radio.Group>
                  <Radio.Button value={1}>UF</Radio.Button>
                  <Radio.Button value={1 / uf}>CLP</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={sizeWindow < 992 ? 24 : 12}>
          <Form.Item name="plazo" label="Plazo Crédito Actual (años)">
            <Slider
              marks={{
                0: '0 años',
                15: '15 años',
                30: '30 años',
              }}
              max={30}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={sizeWindow < 992 ? 24 : 12}>
          <Form.Item
            name="insurance"
            label="Seguros"
            help="* No es obligatorio elegir el seguro de sismos"
            initialValue={['desgravamen', 'incendios']}
          >
            <Checkbox.Group options={insuranceOptions} />
          </Form.Item>
        </Col>
        <Col
          span={sizeWindow < 992 ? 24 : 12}
          style={sizeWindow < 992 ? { marginTop: '10px' } : null}
        >
          <div className="application-form-figure" style={{ textAlign: 'center' }}>
            <span>Crédito a Refinanciar</span>
            <span>{credit && `UF ${toPesos(credit)}`}</span>
          </div>
        </Col>
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Col>
          <Button className="application-form-button" onClick={handleSubmit}>
            Siguiente
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default GeneralForm;
