import React from 'react';
import { FirebaseContext } from './../context';

// Una funcion de utilidad que nos permite tener firebase en los props, para no tener que pasar FirebaseContext.Consumer
const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default withFirebase;
