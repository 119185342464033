// Importar librerías
import { compose } from 'recompose';

// Importar otros componentes
import ApplicationForm from './ApplicationForm';

// Importar decoradores y constantes
import { withAuthorization, withEmailVerification } from '../../utils';
import { authenticatedUserCondition } from '../../constants/conditions';

export default compose(
  withEmailVerification,
  withAuthorization(authenticatedUserCondition)
)(ApplicationForm);
