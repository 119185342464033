// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useCollection } from 'react-firebase-hooks/firestore';
import {
  Button,
  ConfigProvider,
  Layout,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
  Tooltip,
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import { ReactComponent as EmptyIcon } from '../Profile/icons/emptyIcon.svg';
import { AuthUserContext, FirebaseContext } from '../../context';
import NewApplicationModal from './NewApplicationModal';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomoponentes Content, Title y confirm
const { Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

// Definir información del ícono de nuevos mensajes
const iconMessage = 'Hay nuevos mensajes del ejecutivo asociados a esta solicitud';

/**
 *
 * @param {*} word
 * Retorna una cadena con la primera letra en mayúscula.
 */
const capitalize = (word) => {
  let newWord = '';
  word.split(' ').forEach((item) => {
    newWord += item[0].toUpperCase() + item.slice(1) + ' ';
  });
  return newWord;
};

export const renderTag = (application) => {
  if (application.endDate && moment(application.endDate.toDate()) < moment()) {
    if (application.answers.filter((a) => a.status !== 'noOffer').length === 0) {
      return <Tag color="red">SIN OFERTAS</Tag>;
    }
    if (application.status) {
      if (
        application.status === 'userRejected' ||
        application.status === 'bankRejected'
      ) {
        return <Tag color="red">RECHAZADA</Tag>;
      }
    }
    if (application.status === 'accepted') return <Tag color="green">ACEPTADA</Tag>;
    if (
      moment(application.endDate.toDate())
        .add(3, 'd')
        .isBefore(moment())
    )
      return <Tag color="red">FUERA DE PLAZO</Tag>;
    return <Tag color="purple">OFERTA DISPONIBLE</Tag>;
  }

  if (application.requirements) {
    application.requirements.forEach((r) => {
      if (!r.answer) {
        return <Tag color="cyan">REQUIERE INFORMACIÓN</Tag>;
      }
    });
  }

  return <Tag color="blue">INGRESADA</Tag>;
};

const ProfileApplications = () => {
  // Definir context de user y firebase
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);

  // Obtener información de la BD
  const [values, loading] = useCollection(
    firebase.applicationLogic.applications().where('user.id', '==', authUser.uid)
  );

  // Definir state
  const [data, setData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  // Definir effect para setear los valores obtenidos de la BD
  useEffect(() => {
    if (values)
      setData(
        values.docs
          .map((doc) => ({ ...doc.data(), uid: doc.id }))
          .sort((a, b) => b.createdAt - a.createdAt)
      );
  }, [values]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} _
   * @param {*} row
   * Rederiza un componente con las acciones a realizar en el listado de cotizaciones.
   */
  const renderActions = (_, row) => {
    return (
      <Space>
        <Link
          to={`${ROUTES.PROFILE_APPLICATIONS}/${row.uid}`}
          disabled={!row.endDate}
        >
          Ver
        </Link>
        <DeleteOutlined onClick={() => handleApplicationDelete(row.uid)} />
      </Space>
    );
  };

  // Definir columnas que va a contener la tabla de cotizaciones
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'name',
      render: (text, row) => {
        // Definir booleano para manejar el ícono de correos
        let showWarningIcon = false;

        // Obtener último elemento del chat y id del usuario
        const chatLastItem =
          row.chat.length >= 1 ? row.chat[row.chat.length - 1] : null;
        const userId = row.user.id;

        // Cambiar el estado del booleano si es necesario
        if (
          row.status === 'accepted' &&
          chatLastItem &&
          chatLastItem.senderId !== userId
        ) {
          showWarningIcon = true;
        }

        // Renderizar
        return (
          <Space>
            {showWarningIcon && (
              <Tooltip placement="top" title={iconMessage} color="#e81687">
                <ExclamationCircleOutlined className="info-icon-style" />
              </Tooltip>
            )}
            {text}
          </Space>
        );
      },
      show: true,
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'date',
      render: (text) => (text ? moment(text.toDate()).format('DD[/]MM[/]YYYY') : ''),
      show: true,
    },
    {
      title: 'Hora',
      dataIndex: 'createdAt',
      key: 'hour',
      render: (text) => (text ? moment(text.toDate()).format('HH[:]mm [hrs]') : ''),
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (_, row) => renderTag(row),
      show: true,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text) => capitalize(text.replace('-', ' ')),
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'Última Actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) =>
        text ? moment(text.toDate()).format('DD[/]MM[/]YYYY - HH[:]mm [hrs]') : '',
      show: windowWidth < 768 ? false : true,
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: renderActions,
      show: true,
    },
  ];

  // Renderizar componente para el botón de nueva cotización
  const ProfileApplicationsTitle = () => {
    return (
      <div className={'table-header'}>
        <div className={'table-header-text'}>
          <Title>Tus Cotizaciones</Title>
        </div>
        <Button
          className={'table-header-button'}
          onClick={() => setModalVisible(true)}
        >
          + Nueva Cotización
        </Button>
      </div>
    );
  };

  /**
   *
   * @param {*} uid
   * Elimina una cotización del listado de cotizaciones
   */
  const handleApplicationDelete = (uid) => {
    confirm({
      title: '¿Estás seguro que deseas eliminar esta cotización?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción es irreversible',
      width: windowWidth < 768 ? 350 : 420,
      okText: 'Sí',
      cancelText: 'No',
      onOk() {
        return firebase.applicationLogic
          .application(uid)
          .delete()
          .catch(() => alert('Ocurrió un error al eliminar tu cotización'));
      },
    });
  };

  // Renderizar componente para cuando no existan cotizaciones de ningún tipo
  const EmptyData = () => {
    if (loading) return <div id="applications-empty" />;
    return (
      <div id="applications-empty">
        <EmptyIcon />
        <div className="title">Tus Cotizaciones</div>
        <div className="subtitle">No Tienes Cotizaciones</div>
      </div>
    );
  };

  // Renderizar componente
  return (
    <Content className="with-shadow">
      <NewApplicationModal
        visible={modalVisible}
        setVisible={setModalVisible}
        sizeWindow={windowWidth}
      />
      <ProfileApplicationsTitle />
      <ConfigProvider renderEmpty={EmptyData}>
        <Table
          columns={columns.filter((column) => column.show === true)}
          dataSource={data}
          loading={loading}
          rowKey="uid"
          className="applications-table"
          size={windowWidth < 768 ? 'middle' : 'large'}
        />
      </ConfigProvider>
    </Content>
  );
};

export default ProfileApplications;
