class applicationLogic {
  constructor(db, storage, fieldValue, Timestamp) {
    this.db = db;
    this.storage = storage;
    this.fieldValue = fieldValue;
    this.Timestamp = Timestamp;
  }

  application = (uid) => this.db.doc(`applications/${uid}`);

  applications = () => this.db.collection('applications');

  createApplication = async (data) => {
    const newApplicationRef = this.applications().doc();
    const storageRef = this.storage.ref(newApplicationRef.id);

    const vouchersCedula = await Promise.all(
      data.cedula.map((f) => {
        const fileStorageRef = storageRef.child(f.name);
        return fileStorageRef
          .put(f.originFileObj)
          .then((snapshot) => snapshot.ref.fullPath);
      })
    );
    const vouchersLiquidaciones = await Promise.all(
      data.liquidaciones.map((f) => {
        const fileStorageRef = storageRef.child(f.name);
        return fileStorageRef
          .put(f.originFileObj)
          .then((snapshot) => snapshot.ref.fullPath);
      })
    );
    const vouchersCertificadoAFP = await Promise.all(
      data.certificadoAFP.map((f) => {
        const fileStorageRef = storageRef.child(f.name);
        return fileStorageRef
          .put(f.originFileObj)
          .then((snapshot) => snapshot.ref.fullPath);
      })
    );

    return newApplicationRef.set({
      ...data,
      cedula: vouchersCedula,
      liquidaciones: vouchersLiquidaciones,
      certificadoAFP: vouchersCertificadoAFP,
      answers: [],
      assignedExecutives: [],
      changes: [],
      requestedChanges: [],
    });
  };

  addChange = async (applicationId, newChange, newFiles, oldFilePaths = []) => {
    const applicationRef = this.application(applicationId);
    const storageRef = this.storage.ref(applicationRef.id);
    let changeFiles;
    if (newFiles[0]) {
      changeFiles = await Promise.all(
        newFiles.map((f) => {
          const fileStorageRef = storageRef.child(f.name);
          return fileStorageRef
            .put(f.originFileObj)
            .then((snapshot) => snapshot.ref.fullPath);
        })
      );
    }

    if (!changeFiles) changeFiles = [];

    return applicationRef.update({
      changes: this.fieldValue.arrayUnion({
        bankName: newChange.bankName,
        change: newChange.change,
        changeFiles: [...changeFiles, ...oldFilePaths],
      }),
    });
  };

  editChange = (applicationId, oldChange, newChange, newFiles) => {
    const oldFilePaths = oldChange.changeFiles;
    return this.removeChange(applicationId, oldChange).then(() => {
      return this.addChange(applicationId, newChange, newFiles, oldFilePaths);
    });
  };

  removeChange = (applicationId, oldChange) => {
    const applicationRef = this.application(applicationId);
    return applicationRef.update({
      changes: this.fieldValue.arrayRemove(oldChange),
    });
  };

  addChatMessage = (executiveUserId, applicationId, message) => {
    const applicationRef = this.application(applicationId);

    return applicationRef.update({
      chat: this.fieldValue.arrayUnion({
        senderId: executiveUserId,
        message,
        createdAt: this.Timestamp.now(),
      }),
    });
  };

  /**
   *
   * @param {*} data
   * Precarga la información de los requerimientos asociado al ejecutivo
   * ganador de la oferta.
   */
  loadRequirements = async (data) => {
    // Obtener valores de entrada
    const { applicationId, commonRequirements } = data;

    // Obtener referencia de la applicacion
    const applicationRef = this.application(applicationId);

    // Crear referencia padre para aplicación
    const appStorageRef = this.storage.ref(applicationId);

    // Subir todos los archivos que estén cargados en el perfil del ejecutivo
    commonRequirements.forEach(async (req) => {
      // Definir variable para el path del archivo de la solicitud
      let uploadFile = null;

      // Subir archivo si es el caso
      if (req.file) {
        // Obtener información del id del ejecutivo y del nombre del archivo
        const executivePath = req.file.split('/');

        // Crear referencias padre e hija para ejecutivos
        const executiveStorageRef = this.storage.ref(executivePath[0]);
        const executiveFileStorageRef = executiveStorageRef.child(executivePath[1]);

        // Crear referencia hija para aplicación
        const appFileStorageRef = appStorageRef.child(executivePath[1]);

        // Obtener url del archivo del ejecutivo
        const url = await executiveFileStorageRef.getDownloadURL();

        // Descargar archivo y subirlo nuevamente hacia la solicitud
        try {
          // Obtener respuesta del servidor y archivo blob
          const response = await fetch(url);
          const blob = await response.blob();

          // Subir información al servidor
          const uploadPromise = appFileStorageRef
            .put(blob)
            .then((snapshot) => snapshot.ref.fullPath);
          uploadFile = await uploadPromise;
        } catch (error) {
          console.log(error);
        }
      }

      // Actualizar información de la solicitud
      await applicationRef.update({
        requirements: this.fieldValue.arrayUnion({
          answer: {},
          file: uploadFile,
          message: req.message,
          title: req.title,
          createdAt: this.Timestamp.now(),
        }),
      });
    });
  };
}

export default applicationLogic;
