// Importar librerías
import React from 'react';

// Importar otros componentes
import IndicatorsCharts from './IndicatorsCharts';
import TradeData from './TradeData';

const InterestData = () => {
  // Renderizar componente
  return (
    <>
      <p className="section-phone-title">Actualidad</p>
      <div id="actualidad-container">
        <p className="section-title">Actualidad</p>
        <IndicatorsCharts />
        <TradeData />
      </div>
    </>
  );
};

export default InterestData;
