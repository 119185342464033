// Importar librerías
import React from 'react';
import { Row, Col } from 'antd';
import ReactPlayer from 'react-player';

// Importar otros componentes
import { ReactComponent as FlitLogo } from '../Session/icons/big-logo.svg';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const LandingExtraInfo = () => {
  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar Componente
  return (
    <div id="landing-extra-container">
      <Row>
        <Col span={windowWidth < 768 ? 24 : 12}>
          <div id="landing-extra-info-box">
            <h1>
              "FLIT es la plataforma que encuentra la mejor alternativa al producto
              financiero que estás buscando"
            </h1>
            <FlitLogo className="landing-extra-logo" />
          </div>
        </Col>
        <Col span={windowWidth < 768 ? 24 : 12}>
          <div id="landing-extra-video-box">
            <ReactPlayer controls={true} url="flit.mp4" height="100%" width="100%" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LandingExtraInfo;
