// Importar librerías
import React, { useState, useContext } from 'react';
import { Button, Form, Input, message } from 'antd';

// Importar otros componentes
import { ReactComponent as MailIcon } from './icons/mail.svg';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Definir información del contacto
const contactData = [
  {
    value: 'contacto@flit.cl',
    icon: <MailIcon />,
  },
];

// Rederizar componente con la información del contacto
const ContactDataDisplay = () => (
  <div>
    {contactData.map((data, index) => (
      <div className="contact-us-info-row" key={index}>
        {data.icon}
        <p>{data.value}</p>
      </div>
    ))}
  </div>
);

const ContactUs = () => {
  // Definir state
  const [loading, setLoading] = useState(false);

  // Definir nueva instancia de Form
  const [contactUsFormInstance] = Form.useForm();

  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  /**
   *
   * @param {*}
   * Envía la información obtenida del formulario de contacto.
   */
  const onFinish = ({ fullName, email, body }) => {
    setLoading(true);

    firebase.functions
      .httpsCallable('contactUsMailer')({
        fullName,
        email,
        body,
      })
      .then(() => {
        message.success('¡Su mensaje ha sido enviado!');
        contactUsFormInstance.resetFields();
      })
      .catch((err) => {
        console.log(err);
        message.error('Hubo un error al enviar su mensaje');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Renderizar componente
  return (
    <div id="contact-us-container">
      <p id="contact-us-title">Contáctanos</p>
      <div id="contact-us-content">
        <Form id="contact-us-form" onFinish={onFinish} form={contactUsFormInstance}>
          <div className="contact-us-form-row">
            <div className="contact-us-form-col">
              <p>Nombre completo</p>
              <Form.Item
                name="fullName"
                rules={[{ required: true, message: 'Debes ingresar tu nombre' }]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="contact-us-form-col">
              <p>Correo electrónico</p>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Debes ingresar tu correo' },
                  { type: 'email', message: 'Tu correo no es válido' },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="contact-us-form-row">
            <div className="contact-us-form-col">
              <p>¿En qué podemos ayudarte?</p>
              <Form.Item
                name="body"
                rules={[{ required: true, message: 'Debes escribir un mensaje' }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
          </div>

          <div className="contact-us-container-button">
            <Button
              type="primary"
              htmlType="submit"
              className="small-button"
              loading={loading}
            >
              ENVIAR
            </Button>
          </div>
        </Form>
        <div id="contact-us-info">
          <p id="contact-us-info-title">Información de contacto</p>
          <ContactDataDisplay />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
