// Importar librerías
import React from 'react';
import { Layout, Typography } from 'antd';

// Importar otros componentes
import PortabilityConsumeAppForm from '../PortabilityConsumeAppForm';

// Importar subcomponentes Content y Title
const { Content } = Layout;
const { Title } = Typography;

const ProfilePortabilityConsumoApps = () => {
  // Renderizar componente
  return (
    <Content id="profile-application-form" className="with-shadow">
      <div className={'profile-application-title'}>
        <Title>Nueva Cotización Portabilidad de Consumo</Title>
      </div>
      <PortabilityConsumeAppForm />
    </Content>
  );
};

export default ProfilePortabilityConsumoApps;
