// Importar librerías
import React from 'react';
import ReactDOM from 'react-dom';

// Importar estilos
import './style/index.scss';

// Importar componente de servicios offline
import * as serviceWorker from './serviceWorker';

// Importar componente principal
import App from './components/App';

// Importar context de firebase
import Firebase, { FirebaseContext } from './context';

ReactDOM.render(
  // Aqui estamos pasando FirebaseContext (con value una instancia singleton de Firebase) que puede ser usada en cualquier punto mas abajo del arbol con FirebaseContext.Consumer
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
