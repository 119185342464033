// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { animateScroll } from 'react-scroll';
import { Input, message } from 'antd';
import moment from 'moment';

// Importar context
import { FirebaseContext, AuthUserContext } from '../../context';

// Importar otros componentes
import { ReactComponent as SendIcon } from './icons/send.svg';
import Loading from '../Loading';

// Definir formato de fecha
const DATE_FORMAT = 'DD/MM/YYYY - HH:mm';

// Definir parámetros de filtrado
const paramToValue = {
  cuota: 'cuotaMensual1',
  credito: 'costoTotal1',
};

// Importar subcomponente Search
const { Search } = Input;

const ProfileShowApplicationChat = () => {
  // Definir nueva instancia de route
  const route = useRouteMatch();

  // Obtener información del context
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Obtener id de la url actual
  const applicationUid = route.params.id;

  // Obtener información de la aplicación de la BD
  const [application] = useDocument(
    firebase.applicationLogic.application(applicationUid)
  );

  // Definir state
  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [executiveEmail, setExecutiveEmail] = useState(null);

  // Definir effect para setear mensaje del ejecutivo
  useEffect(() => {
    if (application) {
      // Obtener mejor respuesta
      let param = null;
      if (application.get('type') === 'consumo') {
        param = paramToValue[application.get('bestAnswerParam')];
      } else {
        param = application.get('bestAnswerParam');
      }
      const selectedAnswer = application
        .data()
        .answers.filter((a) => a.status !== 'noOffer')
        .reduce((prev, current) =>
          prev.answer[param] > current.answer[param] ? current : prev
        );

      // Obtener correo del ejecutivo asignado
      const executive = application
        .get('executives')
        .filter((executive) => executive.id === selectedAnswer.executiveId)[0];

      // Definir mensaje inicial
      const initialText = `Hola ${authUser.firstName} ${authUser.lastName}, soy tu ejecutivo asignado para esta solicitud. Cualquier duda sobre la oferta que ${executive.bankName} te ha entregado, acá estoy para responderla. Puedes escribirme por este medio o al correo ${executive.email}`;

      if (application.get('chat').length === 0) {
        // Llamar a la función de chat
        sendMessage(initialText, executive.id);
      }

      // Setear email del ejecutivo
      setExecutiveEmail(executive.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, authUser]);

  /**
   *
   * @param {*} messageVal
   * @param {*} executiveId
   * Almacena la información del mensaje enviado por un usuario o inicializa un mensaje
   * vacío por parte del ejecutivo.
   */
  const sendMessage = async (messageVal, executiveId) => {
    // Validar que hay un mensaje
    if (!messageVal) return false;

    // Habilitar spinner de carga
    setLoading(true);

    // Intentar almacenar en la BD
    try {
      await firebase.applicationLogic.addChatMessage(
        typeof executiveId === 'string' ? executiveId : authUser.uid,
        application.id,
        messageVal
      );

      // Reinicializar valor del mensaje
      setMessageText('');

      // Enviar notificación al usuario
      const data = {
        email: executiveEmail,
        body: `
        En el chat tienes un nuevo mensaje del usuario <b>${authUser.firstName} ${authUser.lastName}</b>, para verlo ingresa a tus solicitudes asignadas en:
        <br><br>
        https://bank.flit.cl/applications/${application.id}
        `,
      };
      await firebase.functions.httpsCallable('sendInfoMailer')(data);
    } catch (error) {
      message.error('Hubo un problema al intentar enviar el mensaje');
    }

    // Deshabilitar spinner de carga
    setLoading(false);
  };

  // Renderizar componente de scroll de chat
  const scrollToBottom = () => {
    if (application) {
      animateScroll.scrollToBottom({
        containerId: 'chat',
      });
    }
  };

  // Definir effect para animar el scroll
  useEffect(scrollToBottom, [application]);

  // Renderizar componente Loading en caso de que no hay información
  if (!application) return <Loading />;

  // Obtener información del chat
  const chat = application.get('chat');

  // Renderizar
  return (
    <div id={'chat-container'}>
      <h4>
        {authUser.firstName} {authUser.lastName}
      </h4>
      <div className={'chat'} id={'chat'}>
        {chat.map((d, i) => {
          const isAuthor = d.senderId === authUser.uid;
          return (
            <div key={d.message + i} className={isAuthor ? 'bank' : 'user'}>
              <div className={'message'}>
                <p>{d.message}</p>
                <span>{moment(d.createdAt.toDate()).format(DATE_FORMAT)}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className={'send'}>
        <Search
          onChange={(e) => setMessageText(e.target.value)}
          value={messageText}
          className={'send-message'}
          placeholder="Escribe tu mensaje aqui"
          enterButton={<SendIcon />}
          size="middle"
          onSearch={sendMessage}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ProfileShowApplicationChat;
