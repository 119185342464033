// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import { Divider, Form, Steps } from 'antd';
import shortid from 'shortid';

// Importar context de user y firebase
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar otros componentes
import GeneralForm from './GeneralForm';
import FinancialForm from './FinancialForm';
import UserStatusForm from './UserStatusForm';
import Loading from '../Loading';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomopnente Step
const { Step } = Steps;

// Obtener información de la sessión actual
const { sessionStorage } = window;

const ApplicationForm = () => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [createStatus, setCreateStatus] = useState();
  const [uf, setUf] = useState();

  // Crear instancias de useForm
  const [generalFormInstance] = Form.useForm();
  const [financialFormInstance] = Form.useForm();
  const [userStatusFormInstance] = Form.useForm();

  // Definir effect para obtener información de una API externa
  useEffect(() => {
    axios
      .get('https://mindicador.cl/api')
      .then((response) => {
        setUf(response.data.uf.valor);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Almacena la información de una nueva cotización en la BD en caso de haberse
   * completado todos los pasos de los formularios.
   */
  const handleFormFinish = async () => {
    if (currentStep < 2) {
      animateScroll.scrollToTop();
      setCurrentStep(currentStep + 1);
    } else {
      const generalFormInfo = generalFormInstance.getFieldsValue();
      const financialFormInfo = financialFormInstance.getFieldsValue();
      const userStatusFormInfo = userStatusFormInstance.getFieldsValue();
      const { certificadoAFP, liquidaciones, cedula } = userStatusFormInfo;
      const data = {
        ...generalFormInfo,
        ...financialFormInfo,
        ...userStatusFormInfo,
        valor:
          generalFormInfo.valorMoneda !== 1
            ? Math.round(generalFormInfo.valor / uf)
            : generalFormInfo.valor,
        user: {
          id: authUser.uid,
          email: authUser.email,
          firstName: authUser.firstName,
          lastName: authUser.lastName,
        },
        answers: [],
        executives: [],
        requirements: [],
        chat: [],
        certificadoAFP: certificadoAFP || [],
        cedula: cedula || [],
        liquidaciones: liquidaciones || [],
        type: 'hipotecario',
        status: 'open',
        subsidio: generalFormInfo.withSubsidio ? generalFormInfo.subsidio : 0,
        idCostQuote: shortid.generate(),
      };
      try {
        setCreateStatus('loading');
        await firebase.applicationLogic.createApplication(data);
        setCreateStatus('success');
        sessionStorage.clear();
      } catch (e) {
        console.log(e);
        setCreateStatus('error');
      }
    }
  };

  /**
   * Actualiza el estado de la variable currentStep
   */
  const handlePreviousButtonClick = () => {
    setCurrentStep(currentStep - 1);
  };

  /**
   *
   * @param {*} formName
   * @param {*} info
   * Escucha por los cambios que se dan en los campos de un formulario.
   */
  const handleFormChange = (formName, info) => {
    Object.values(info.forms).forEach((f) => {
      Object.entries(f.getFieldsValue()).forEach(([k, v]) => {
        if (v || v === 0) sessionStorage.setItem(k, v);
      });
    });
  };

  // Renderizar componente loading en caso deque no haya información
  if (loading) {
    return <Loading />;
  }

  // Renderizar componente
  return (
    <Form.Provider onFormFinish={handleFormFinish} onFormChange={handleFormChange}>
      <Steps
        current={currentStep}
        id="application-form-steps"
        direction={windowWidth < 768 ? 'vertical' : 'horizontal'}
        size={windowWidth < 768 ? 'small' : 'default'}
      >
        <Step title="Información General" />
        <Step title="Información Financiera" />
        <Step title="Información Personal" />
      </Steps>
      <Divider style={{ marginTop: windowWidth < 768 ? '0' : '24px', opacity: 1 }} />
      <GeneralForm
        formInstance={generalFormInstance}
        style={{ display: currentStep === 0 ? 'block' : 'none' }}
        uf={uf}
        sizeWindow={windowWidth}
      />
      <FinancialForm
        formInstance={financialFormInstance}
        style={{ display: currentStep === 1 ? 'block' : 'none' }}
        uf={uf}
        handlePreviousButtonClick={handlePreviousButtonClick}
        sizeWindow={windowWidth}
      />
      <UserStatusForm
        formInstance={userStatusFormInstance}
        style={{ display: currentStep === 2 ? 'block' : 'none' }}
        handlePreviousButtonClick={handlePreviousButtonClick}
        createStatus={createStatus}
        sizeWindow={windowWidth}
      />
    </Form.Provider>
  );
};

export default ApplicationForm;
