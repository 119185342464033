// Importar librerías
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';

// Importar otros componentes
import FullHeader from './Layout/Header';
import FullFooter from './Layout/Footer';
import HomePage from './Home';
import Profile from './Profile';
import SignInPage from './Session/signIn';
import PasswordForgetPage from './Session/passwordForget';

// Componentes que trabajan de forma local y no en produccón
import Articles from './Articles';
import ArticleItem from './Articles/ArticleItem';
import FinancialEducation from './Home/FinancialEducationItem';

// Importar rutas y componente de ruta privada
import * as ROUTES from './../constants/routes';
import PrivateRoute from './PrivateRoute';

// Importar utilidades
import { withAuthentication } from '../utils';

// Importar subcomponente Content
const { Content } = Layout;

// Definir toda la información de la aplicación
const HeaderLayout = () => (
  <div id="app-wrapper">
    <FullHeader />
    <Content className={'app-content'}>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <PrivateRoute path={ROUTES.PROFILE} component={Profile} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.ARTICLES_PAGE} component={Articles} />
        <Route path={ROUTES.ARTICLE_PAGE} component={ArticleItem} />
        <Route
          path={ROUTES.FINANCIAL_EDUCATION_PAGE}
          component={FinancialEducation}
        />
      </Switch>
    </Content>
    <FullFooter />
  </div>
);

// Renderizar componente principal
const App = () => (
  <Router>
    <Layout>
      <Switch>
        <PrivateRoute
          exact
          path={ROUTES.SIGN_IN}
          component={SignInPage}
          loged={false}
        />
        <Route path="/" component={HeaderLayout} />
      </Switch>
    </Layout>
  </Router>
);

export default withAuthentication(App);
