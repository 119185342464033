// *** Financial Data API ***

class financialDataLogic {
  constructor(db) {
    this.db = db;
  }

  financialData = () => this.db.collection('financialData');
}

export default financialDataLogic;
