// Importar librerías
import React, { useContext } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useLocation } from 'react-router-dom';

// Importar context de firebase
import { FirebaseContext } from './../../context';

// Importar otros componentes
import ReactMarkdown from 'react-markdown';

const FinancialEducation = () => {
  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener id de la noticia actual
  const location = useLocation().pathname.split('educacion')[1];

  // Obtener información de la BD
  const [value, loading, error] = useDocument(
    firebase.db.doc(`financialEducation${location}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (value) {
    console.log(value.get('content'));
  }

  // Renderizar
  return (
    <div id="financial-article">
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Document: Loading...</span>}
      {value && (
        <span>
          <ReactMarkdown
            className={'markdown-container'}
            escapeHtml={false}
            source={value.get('content').replace(/_n/gm, '\n')}
          />
        </span>
      )}
    </div>
  );
};

export default FinancialEducation;
