// Importar librerías
import React from 'react';
import { Layout, Typography } from 'antd';

// Importar otros componentes
import AutomotiveCreditAppForm from '../AutomotiveCreditAppForm';

// Importar subcomponentes Content y Title
const { Content } = Layout;
const { Title } = Typography;

const ProfileAutomotiveCreditApps = () => {
  // Renderizar componente
  return (
    <Content id="profile-application-form" className="with-shadow">
      <div className={'profile-application-title'}>
        <Title>Nueva Cotización de Crédito Automotriz</Title>
      </div>
      <AutomotiveCreditAppForm />
    </Content>
  );
};

export default ProfileAutomotiveCreditApps;
