// Importar librerías
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout } from 'antd';

// Obtener imagen del logo
import { ReactComponent as FlitLogo } from './icons/flit-logo.svg';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar Subcomponente Footer
const { Footer } = Layout;

// Definir elementos del footer
const footerData = [
  {
    title: 'Sitemap',
    items: [
      <NavLink
        to={{ pathname: ROUTES.HOME, scrollTo: 'what-we-do' }}
        className="nav-text"
      >
        Qué Hacemos
      </NavLink>,

      <NavLink
        to={{ pathname: ROUTES.HOME, scrollTo: 'calculator' }}
        className="nav-text"
      >
        Simula Tu Crédito
      </NavLink>,

      <NavLink
        to={{ pathname: ROUTES.HOME, scrollTo: 'interest-data' }}
        className="nav-text"
      >
        Actualidad
      </NavLink>,

      <NavLink
        to={{ pathname: ROUTES.HOME, scrollTo: 'contact-us' }}
        className="nav-text"
      >
        Contáctanos
      </NavLink>,
    ],
  },
  {
    title: '¡Síguenos!',
    items: [
      <a
        href="https://www.facebook.com/Flit-109920777464559"
        className="nav-text"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </a>,
      <a
        href="https://www.instagram.com/flit_cl/?hl=es-la"
        className="nav-text"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>,
      <a
        href="https://www.linkedin.com/company/flit-cl/"
        className="nav-text"
        target="_blank"
        rel="noopener noreferrer"
      >
        LinkedIn
      </a>,
    ],
  },
  {
    title: 'Documentos',
    items: [
      <a
        href={ROUTES.TERMS}
        className="nav-text"
        target="_blank"
        rel="noopener noreferrer"
      >
        Términos y Condiciones
      </a>,
      'Cookies',
    ],
  },
];

function FullFooter() {
  // Renderizar componente
  return (
    <Footer id="footer">
      <FlitLogo id="footer-logo" />
      <div id="footer-data">
        {footerData.map((section, index) => (
          <div
            key={`${section.title}-${index.toString()}`}
            className="footer-data-section"
          >
            <p className="footer-data-section-title">{section.title}</p>
            {section.items.map((item, index) => (
              <p
                key={`${section.title}-${index.toString()}`}
                className="footer-data-section-item"
              >
                {item}
              </p>
            ))}
          </div>
        ))}
      </div>
    </Footer>
  );
}
export default FullFooter;
