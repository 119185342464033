// Importar librerías
import React, { useState, useEffect } from 'react';
import { Form, InputNumber, Slider } from 'antd';
import axios from 'axios';
import Bounce from 'react-reveal/Bounce';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar otros componentes
import { ReactComponent as House1 } from './icons/house1.svg';
import { ReactComponent as House2 } from './icons/house2.svg';
import { ReactComponent as House3 } from './icons/house3.svg';
import { ReactComponent as House4 } from './icons/house4.svg';

// Inicializar valores
const initialValues = {
  valorPropiedad: 10000,
  piePercentage: 0.2,
  pie: 10000 * 0.2,
  mortageDuration: 15,
  anualInterest: 0.05,
};

/**
 *
 * @param {*} value
 * @param {*} type
 * @param {*} setPie
 * @param {*} setPiePercentage
 * @param {*} valorPropiedad
 * Actauliza el valor de la variables piePercentage y pie según el valor del type de entrada.
 */
const changePie = (value, type, setPie, setPiePercentage, valorPropiedad) => {
  switch (type) {
    case 'percentage':
      setPiePercentage(value / 100);
      setPie(valorPropiedad * (value / 100));
      break;
    case 'total':
      setPie(value);
      setPiePercentage(value / valorPropiedad);
      break;
    default:
      break;
  }
};

/**
 *
 * @param {*} rate
 * @param {*} nper
 * @param {*} pv
 * @param {*} uf
 * Actualiza el valor de la barra de tiempo según el usuario vaya ajustando el valor.
 */
const pmt = (rate, nper, pv, uf) => {
  let arriba = pv * rate * uf;
  let abajo = 1 - Math.pow(1 / (1 + rate), nper);
  if (rate === 0) {
    arriba = pv * uf;
    abajo = nper;
  }
  return isNaN(arriba / abajo) ? '-' : arriba / abajo;
};

const MortageCalculator = ({ shadow = false }) => {
  // Definir state
  const [valorPropiedad, setValorPropiedad] = useState(initialValues.valorPropiedad);
  const [uf, setUf] = useState(0);
  const [loading, setLoading] = useState(true);

  const [mortageDuration, setMortageDuration] = useState(
    initialValues.mortageDuration
  );

  const [anualInterest, setAnualInterest] = useState(
    initialValues.anualInterest * 100
  );
  const [anualInterestFocus, setAnualInterestFocus] = useState(false);

  const [piePercentage, setPiePercentage] = useState(initialValues.piePercentage);
  const [pie, setPie] = useState(initialValues.pie);
  const [pieFocus, setPieFocus] = useState(false);

  // Obtener otros valores a mostrar en el simulador hipotecario
  const monthlyInterest = Math.pow(1 + anualInterest * 0.01, 1 / 12) - 1;
  const cuoteNumber = 12 * mortageDuration;
  const presentValue = valorPropiedad - pie;

  const monthlyRate = pmt(monthlyInterest, cuoteNumber, presentValue, uf);

  const house1Display = valorPropiedad < 5000 ? true : false;
  const house2Display =
    valorPropiedad >= 5000 && valorPropiedad < 10000 ? true : false;
  const house3Display =
    valorPropiedad >= 10000 && valorPropiedad < 20000 ? true : false;
  const house4Display = valorPropiedad >= 20000 ? true : false;

  // Definir effect para consultar a una API externa
  useEffect(() => {
    axios
      .get('https://mindicador.cl/api')
      .then((response) => {
        setUf(response.data.uf.valor);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Definir effect para setear el valor del % cada vez que cambie el valor pie o valorPropiedad.
  useEffect(() => {
    setPiePercentage(pie / valorPropiedad);
  }, [pie, valorPropiedad]);

  // Renderizar componente
  return (
    <div id="calculator-container" className={shadow ? 'with-shadow' : ''}>
      <div id="calculator-center-form">
        <p className="calculator-title">Crédito Hipotecario</p>
        <div id="calculator-house-wrapper">
          <Bounce right opposite when={house1Display}>
            <House1 />
          </Bounce>
          <Bounce right opposite when={house2Display}>
            <House2 />
          </Bounce>
          <Bounce right opposite when={house3Display}>
            <House3 />
          </Bounce>
          <Bounce right opposite when={house4Display}>
            <House4 />
          </Bounce>
        </div>
        <div id="calculator-slider-legends">
          <p>UF 1000</p>
          <p id="current-slider-value">UF {toPesos(valorPropiedad)}</p>
          <p>UF 30.000</p>
        </div>
        <Slider
          className={'antdslider'}
          min={1000}
          max={30000}
          onChange={setValorPropiedad}
          value={valorPropiedad}
          step={100}
          tipFormatter={null}
        />
        <div id="calculator-slider-legends">
          <p>5 años</p>
          <p id="current-slider-value">{mortageDuration} años</p>
          <p>30 años</p>
        </div>
        <Slider
          className={'antdslider'}
          min={5}
          max={30}
          onChange={setMortageDuration}
          value={mortageDuration}
          step={1}
          tipFormatter={null}
        />
      </div>
      <div id="calculator-left-form">
        <Form>
          <Form.Item>
            <p>Precio de la propiedad (UF)</p>
            <InputNumber
              min={1000}
              max={100000}
              step={100}
              value={valorPropiedad ? valorPropiedad.toFixed() : 0}
              onChange={setValorPropiedad}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              size="small"
              onClick={(e) => e.target.select()}
            />
          </Form.Item>

          <div id="calculator-pie-inputs">
            <Form.Item>
              <p>Pie (UF)</p>
              <InputNumber
                style={{ width: '50%' }}
                min={0}
                max={valorPropiedad}
                value={pie ? pie.toFixed() : 0}
                formatter={(value) => toPesos(value)}
                parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                onChange={(val) =>
                  changePie(val, 'total', setPie, setPiePercentage, valorPropiedad)
                }
                onClick={(e) => e.target.select()}
                size="small"
              />
              <InputNumber
                min={0}
                max={100}
                precision={2}
                value={100 * piePercentage || 0}
                formatter={(value) => (pieFocus ? value : `${toPesos(value, 2)}%`)}
                parser={(value) =>
                  value.replace(',', '.').replace(/^(0.)|([^0-9.])/, '')
                }
                onChange={(val) =>
                  changePie(
                    val,
                    'percentage',
                    setPie,
                    setPiePercentage,
                    valorPropiedad
                  )
                }
                onFocus={() => setPieFocus(true)}
                onBlur={() => setPieFocus(false)}
                onClick={(e) => e.target.select()}
                size="small"
              />
            </Form.Item>
          </div>

          <Form.Item>
            <p>Tasa de interés</p>
            <InputNumber
              min={0}
              max={99}
              precision={2}
              value={anualInterest || 0}
              formatter={(value) =>
                anualInterestFocus ? value : `${toPesos(value, 2)}%`
              }
              parser={(value) =>
                value.replace(',', '.').replace(/^(0.)|([^0-9.])/, '')
              }
              onChange={setAnualInterest}
              onFocus={() => setAnualInterestFocus(true)}
              onBlur={() => setAnualInterestFocus(false)}
              size="small"
              onClick={(e) => e.target.select()}
            />
          </Form.Item>

          <p id="dividendo-label">DIVIDENDO</p>
          {loading ? (
            'Cargando....'
          ) : (
            <p className="result">CLP {toPesos(monthlyRate)}*</p>
          )}
          <p>*Valor estimado, no incluye seguros.</p>
        </Form>
      </div>
    </div>
  );
};

export default MortageCalculator;
