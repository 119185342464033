// Importar librerías
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Skeleton } from 'antd';

// Importar hook de firebase y su context
import { useCollection } from 'react-firebase-hooks/firestore';
import { FirebaseContext } from './../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const FinantialCard = ({ article }) => {
  // Dar una estructura base en caso de que no haya información sobre el artículo
  if (!article) {
    return (
      <div id={'finantial-card'}>
        <div id="img-div"></div>
        <div id="info-div">
          <Skeleton />
        </div>
      </div>
    );
  }

  // Aplicar destructuración al objeto de entrada
  const { photoUrl, createdAt, title, summary, pathName } = article;

  // Renderizar componente
  return (
    <div id={'finantial-card'}>
      <div id="img-div" style={{ backgroundImage: `url(${photoUrl})` }}></div>
      <div id="info-div">
        <p id="date">{createdAt.toDate().toLocaleDateString('es-CL')}</p>
        <p id="title">{title}</p>
        <div id="summary">
          <p>{summary}</p>
        </div>
        <Link to={`/educacion/${pathName}`}>Ver más</Link>
      </div>
    </div>
  );
};

const FinancialEducation = () => {
  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Obtener información de la BD
  const [articles, loading, error] = useCollection(
    firebase.db
      .collection('financialEducation')
      .orderBy('createdAt', 'desc')
      .limit(12),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Definir state
  const [groupedArticles, setGroupedArticles] = useState([
    [undefined, undefined, undefined],
    [undefined, undefined, undefined],
  ]);

  // Aplicar useEffect para almacenar información en el state local
  useEffect(() => {
    if (!loading && !error) {
      const group = [];
      articles.docs.forEach((art) => {
        group.push({ ...art.data(), pathName: art.id });
      });

      const groupAux = [];
      for (
        var i = 0;
        i <=
        (group.length - 1) / (windowWidth < 768 ? 1 : windowWidth < 992 ? 2 : 3);
        i++
      ) {
        groupAux.push(
          group.slice(
            i * (windowWidth < 768 ? 1 : windowWidth < 992 ? 2 : 3),
            i * (windowWidth < 768 ? 1 : windowWidth < 992 ? 2 : 3) +
              (windowWidth < 768 ? 1 : windowWidth < 992 ? 2 : 3)
          )
        );
      }

      setGroupedArticles(groupAux);
    }
  }, [articles, loading, error, windowWidth]);

  // Renderizar componente
  return (
    <div id="financial-education-container">
      <p id="financial-education-title">Educación Financiera</p>
      <Carousel>
        {groupedArticles.map((articles, i) => {
          return (
            <div id="carousel-div" key={i}>
              {articles.map((article, j) => {
                return <FinantialCard article={article} key={j} />;
              })}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default FinancialEducation;
