// Importar librerías
import React from 'react';
import { Layout, Typography } from 'antd';

// Importar otros componentes
import MortgageApplicationForm from '../MortgageApplicationForm';

// Importar subcomponentes Content y Title
const { Content } = Layout;
const { Title } = Typography;

const ProfileMortgageApplications = () => {
  // Renderizar componente
  return (
    <Content id="profile-application-form" className="with-shadow">
      <div className={'profile-application-title'}>
        <Title>Nueva Cotización Hipotecaria</Title>
      </div>
      <MortgageApplicationForm />
    </Content>
  );
};

export default ProfileMortgageApplications;
