// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Badge, Layout, Tabs, Tooltip, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// Importar otros componentes
import Loading from '../Loading';
import ProfileShowMortgageApplicationData from './ProfileShowMortgageApplicationData';
import ProfileShowConsumeApplicationData from './ProfileShowConsumeApplicationData';
import ProfileShowAutomotiveCreditAppData from './ProfileShowAutomotiveCreditAppData';
import ProfileShowApplicationChat from './ProfileShowApplicationChat';
import ProfileShowApplicationRequirements from './ProfileShowApplicationRequirements';
import ProfileShowMortgageApplicationResult from './ProfileShowMortgageApplicationResult';
import ProfileShowConsumeApplicationResult from './ProfileShowConsumeApplicationResult';
import { renderTag } from './ProfileApplications';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar context del user y de firebase
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Definir time de moment en español
moment.locale('es');

// Importar subcompentes Content, Title y TabPane
const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

// Definir objeto con los tipos de productos que hay
const products = {
  consumo: 'CRÉDITO DE CONSUMO',
  hipotecario: 'CRÉDITO HIPOTECARIO',
  'portabilidad-consumo': 'PORTABILIDAD CRÉDITO DE CONSUMO',
  'portabilidad-hipotecario': 'PORTABILIDAD CRÉDITO HIPOTECARIO',
  automotriz: 'CRÉDITO AUTOMOTRIZ',
};

/**
 *
 * @param {*} endDate
 * Recibe un string con formato de fecha y lo desgloza en días, horas, minutos y segundos.
 */
const getRemainingTime = (endDate) => {
  const now = moment();
  const momentEndDate = moment(endDate.toDate());

  const days = momentEndDate.diff(now, 'days');
  const hours = momentEndDate.diff(now, 'hours') - days * 24;
  const minutes = momentEndDate.diff(now, 'minutes') - days * 24 * 60 - hours * 60;
  const seconds =
    momentEndDate.diff(now, 'seconds') -
    days * 24 * 60 * 60 -
    hours * 60 * 60 -
    minutes * 60;
  return { days, hours, minutes, seconds };
};

const ProfileShowApplication = () => {
  // Definir nuevas instancias de route e history
  const route = useRouteMatch();
  const history = useHistory();

  // Obtener context
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);

  // Obtener id de la url actual
  const applicationUid = route.params.id;

  // Obtener información de la BD en función del id anterior
  const [value] = useDocument(firebase.applicationLogic.application(applicationUid));

  // Definir state
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openApplication, setOpenApplication] = useState(true);
  const [acceptedApplication, setAcceptedApplication] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [offeredApplication, setOfferedApplication] = useState(false);
  const [remainingTime, setRemainingTime] = useState({});

  // Definir effect para almacenar el tiempo restante de una cotización
  useEffect(() => {
    if (data && data.endDate && data.status === 'open')
      setInterval(() => {
        setRemainingTime(getRemainingTime(data.endDate));
      }, 1000);
  }, [data]);

  // Definir effect para setear el valor de la cotización según la información
  // proveniente de la BD
  useEffect(() => {
    if (value && value.data()) {
      setData(value.data());

      if (moment(value.data().endDate.toDate()) < moment()) {
        setOpenApplication(false);
        setShowChat(true);
      }
      if (
        value.data().status === 'noOffer' ||
        value.data().status === 'userRejected' ||
        _.isEmpty(value.data().answers.filter((a) => a.status !== 'noOffer'))
      ) {
        setShowChat(false);
      }
      if (value.data().status === 'accepted') {
        setAcceptedApplication(true);
      }
      if (!_.isEmpty(value.data().answers.filter((a) => a.status !== 'noOffer'))) {
        setOfferedApplication(true);
      }

      setLoading(false);
    }
  }, [value]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Redireccionar en caso de que el usuario no esté autenticado o el id dado no
  // sea válido
  if (
    !authUser ||
    !authUser.applications ||
    !authUser.applications.includes(route.params.id)
  )
    history.push(ROUTES.PROFILE_APPLICATIONS);

  // Renderizar componente de Loading en caso de que no se haya cargado la información
  if (loading) return <Loading />;

  // Renderizar componente que muestre el tiempo de respuesta de una cotización
  const ApplicationTimer = () => {
    return (
      <div id="main-info">
        <div className="time-limit">
          <span className="bold">Plazo de respuesta abierto</span>
          <span className="remaining">
            Quedan {remainingTime.days}d {remainingTime.hours}h{' '}
            {remainingTime.minutes}m {remainingTime.seconds}s
          </span>
        </div>
      </div>
    );
  };

  // Renderizar pestaña extra que será utilizada por el componente Tabs
  const renderTabExtra = (
    <>
      {renderTag(data)}
      <Badge
        count={data.idCostQuote || applicationUid}
        style={{
          backgroundColor: 'rgba(5, 3, 88, 0.1)',
          color: '#050358',
          fontWeight: 'bold',
          padding: '2px 10px',
          height: '24px',
        }}
      />
    </>
  );

  // Renderizar
  return (
    <Content id="profile-application-show-content" className="with-shadow">
      <div className={'profile-application-type'}>
        <h1>{products[data.type]}</h1>
      </div>
      <div className={'profile-application-title'}>
        <div className="phone-container-left">
          <Title>{`${data.nombre}`}</Title>
        </div>
        <Link to={ROUTES.PROFILE_APPLICATIONS}>
          <ArrowLeftOutlined /> Volver{' '}
          {windowWidth < 768 ? '' : 'a mis cotizaciones'}
        </Link>
      </div>

      <div style={{ textAlign: 'end' }}>{windowWidth < 768 && renderTabExtra}</div>

      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={windowWidth >= 768 && renderTabExtra}
      >
        <TabPane tab="Solicitud" key="1">
          {(data.type === 'hipotecario' ||
            data.type === 'portabilidad-hipotecario') && (
            <ProfileShowMortgageApplicationData />
          )}
          {(data.type === 'consumo' || data.type === 'portabilidad-consumo') && (
            <ProfileShowConsumeApplicationData />
          )}
          {data.type === 'automotriz' && <ProfileShowAutomotiveCreditAppData />}
        </TabPane>
        <TabPane tab="Oferta" key="2">
          {openApplication && <ApplicationTimer />}
          {!openApplication && !offeredApplication && (
            <div className="time-left">
              <p>Su cotización no recibió ninguna oferta</p>
            </div>
          )}
          {!openApplication &&
            offeredApplication &&
            (data.type === 'hipotecario' ||
              data.type === 'portabilidad-hipotecario') && (
              <ProfileShowMortgageApplicationResult />
            )}
          {!openApplication &&
            offeredApplication &&
            (data.type === 'consumo' ||
              data.type === 'portabilidad-consumo' ||
              data.type === 'automotriz') && <ProfileShowConsumeApplicationResult />}
        </TabPane>
        <TabPane
          tab={
            <Tooltip
              title={
                acceptedApplication || showChat
                  ? ''
                  : 'Debes esperar tu oferta para ingresar al chat'
              }
            >
              Chat
            </Tooltip>
          }
          key="3"
          disabled={!acceptedApplication && !showChat}
        >
          <ProfileShowApplicationChat />
        </TabPane>
        <TabPane
          tab={
            <Tooltip
              title={
                acceptedApplication
                  ? ''
                  : 'Debes esperar tu oferta y aceptarla para ingresar a los requerimientos'
              }
            >
              Requerimientos
            </Tooltip>
          }
          key="4"
          disabled={!acceptedApplication}
        >
          <ProfileShowApplicationRequirements />
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default ProfileShowApplication;
