import dotenv from 'dotenv';

// Cargamos las variables de entorno
dotenv.config();

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};

const stageConfig = {
  apiKey: process.env.REACT_APP_STAGE_API_KEY,
  authDomain: process.env.REACT_APP_STAGE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_STAGE_DATABASE_URL,
  projectId: process.env.REACT_APP_STAGE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STAGE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_STAGE_MESSAGING_SENDER_ID,
};

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
};

let config;

switch (process.env.REACT_APP_ENV) {
  case 'dev':
    config = devConfig;
    break;
  case 'staging':
    config = stageConfig;

    break;
  case 'prod':
    config = prodConfig;
    break;

  default:
    break;
}

export default config;
