// Importar librerías
import React, { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Button, Menu } from 'antd';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import { ReactComponent as FlitLogo } from './icons/flit-logo.svg';

// Importar context de user y firebase
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponente SubMenu
const { SubMenu } = Menu;

const Navigation = (props) => {
  // Definir context de firebase y user
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Crear nueva instancia de location
  const location = useLocation();

  // Definir state
  const [showMenu, setShowMenu] = useState(false);
  const [showLoginOptions, setLoginOptions] = useState(false);
  const [showUserOptions, setUserOptions] = useState(false);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Obtener booleanos según la ruta actual
  const inHome = location.pathname === '/';
  const inForget = location.pathname === '/pw-forget';

  // Renderizar componente del logo
  const menuLogoItem = (
    <NavLink
      to={{ pathname: ROUTES.HOME, scrollTo: 'landing' }}
      className={windowWidth < 992 ? 'logo-vertical' : 'logo-wrapper'}
    >
      <FlitLogo className={windowWidth >= 992 ? 'navbar-logo' : ''} />
    </NavLink>
  );

  // Definir arreglo con los elementos del menú de navegación
  const menuItems = [
    {
      component: (
        <NavLink
          to={{ pathname: ROUTES.HOME, scrollTo: 'what-we-do' }}
          className="nav-text"
        >
          Qué hacemos
        </NavLink>
      ),
    },
    {
      component: (
        <NavLink
          to={{ pathname: ROUTES.HOME, scrollTo: 'calculator' }}
          className="nav-text"
        >
          Simula tu crédito
        </NavLink>
      ),
    },
    {
      component: (
        <NavLink
          to={{ pathname: ROUTES.HOME, scrollTo: 'interest-data' }}
          className="nav-text"
        >
          Actualidad
        </NavLink>
      ),
    },
    {
      component: (
        <NavLink
          to={{ pathname: ROUTES.HOME, scrollTo: 'finantial-education' }}
          className="nav-text"
        >
          Educación Financiera
        </NavLink>
      ),
    },
    {
      component: (
        <NavLink
          to={{ pathname: ROUTES.HOME, scrollTo: 'contact-us' }}
          className="nav-text"
        >
          Contáctanos
        </NavLink>
      ),
    },
  ];

  // Definir arreglo con los elementos del menú de navegación del usuario
  const userItems = [
    {
      component: (
        <NavLink
          to={ROUTES.PROFILE_HOME}
          className="nav-text"
          onClick={() => expandUserOptions()}
        >
          Mi perfil
        </NavLink>
      ),
    },
    {
      component: (
        <NavLink
          to={ROUTES.PROFILE_APPLICATIONS}
          className="nav-text"
          onClick={() => expandUserOptions()}
        >
          Cotizaciones
        </NavLink>
      ),
    },
  ];

  // Definir arreglo con los elementos del menú de navegación del botón login
  const menuLoginItems = [
    {
      component: (
        <NavLink to={ROUTES.SIGN_IN} className="nav-text">
          Usuarios
        </NavLink>
      ),
    },
    {
      component: (
        <a href="https://bank.flit.cl/" className="nav-text">
          Ejecutivos
        </a>
      ),
    },
  ];

  /**
   * Muestra u oculta las opciones del menú de navegación para dispositivos pequeños.
   */
  const expandVerticalMenu = () => {
    setUserOptions(false);
    setLoginOptions(false);

    if (windowWidth < 992) {
      setShowMenu(!showMenu);
    } else {
      setShowMenu(false);
    }
  };

  /**
   * Muestra u oculta las opciones del menú del botón login para dispositivos pequeños.
   */
  const expandLoginOptions = () => {
    if (windowWidth < 992) {
      setLoginOptions(!showLoginOptions);
    } else {
      setLoginOptions(false);
    }
  };

  /**
   * Muestra u oculta las opciones del menú del usuario para dispositivos pequeños.
   */
  const expandUserOptions = () => {
    if (windowWidth < 992) {
      setUserOptions(!showUserOptions);
    } else {
      setUserOptions(false);
    }
  };

  // Renderizar componente
  return (
    <>
      {windowWidth < 992 ? (
        <>
          <div className="phone-container">
            <div className="first-elements">
              {menuLogoItem}
              {inHome && <MenuOutlined onClick={() => expandVerticalMenu()} />}
              {inForget && (
                <Button
                  type="primary"
                  className="small-button"
                  onClick={() => expandLoginOptions()}
                >
                  INICIAR SESIÓN
                </Button>
              )}
            </div>
            {(showMenu || showLoginOptions || showUserOptions) && (
              <Menu mode="vertical" theme="dark" className="menu" selectable={false}>
                {inHome &&
                  menuItems.map((item, index) => (
                    <Menu.Item key={index}>{item.component}</Menu.Item>
                  ))}
                {inHome && authUser && (
                  <Menu.Item>
                    <Button
                      type="primary"
                      className="small-button"
                      onClick={() => expandUserOptions()}
                    >
                      <span type="primary" className="small-span">
                        {authUser.firstName
                          ? authUser.firstName.toUpperCase()
                          : 'MI FLIT'}
                      </span>
                    </Button>
                  </Menu.Item>
                )}
                {!inForget && !authUser && (
                  <Menu.Item>
                    <Button
                      type="primary"
                      className="small-button"
                      onClick={() => expandLoginOptions()}
                    >
                      INICIAR SESIÓN
                    </Button>
                  </Menu.Item>
                )}
                {showLoginOptions &&
                  menuLoginItems.map((item, index) => (
                    <Menu.Item className="vertical-submenu" key={index}>
                      {item.component}
                    </Menu.Item>
                  ))}
                {showUserOptions &&
                  userItems.map((item, index) => (
                    <Menu.Item className="vertical-submenu" key={index}>
                      {item.component}
                    </Menu.Item>
                  ))}
                {showUserOptions && (
                  <Menu.Item
                    className="vertical-submenu"
                    onClick={firebase.authLogic.doSignOut}
                  >
                    <NavLink
                      to={ROUTES.HOME}
                      className="nav-text"
                      onClick={() => expandUserOptions()}
                    >
                      Cerrar sesión
                    </NavLink>
                  </Menu.Item>
                )}
              </Menu>
            )}
          </div>
        </>
      ) : (
        <Menu mode="horizontal" theme="dark" className="menu" selectable={false}>
          <Menu.Item className="menu-item-logo">{menuLogoItem}</Menu.Item>
          {inHome &&
            menuItems.map((item, index) => (
              <Menu.Item key={index + 1}>{item.component}</Menu.Item>
            ))}
          {authUser ? (
            <SubMenu
              title={
                <span type="primary" className="small-span">
                  {authUser.firstName ? authUser.firstName.toUpperCase() : 'MI FLIT'}
                  <DownOutlined />
                </span>
              }
            >
              {userItems.map((userItem, index) => (
                <Menu.Item key={index} onClick={() => setUserOptions(false)}>
                  {userItem.component}
                </Menu.Item>
              ))}
              <Menu.Item onClick={firebase.authLogic.doSignOut}>
                Cerrar sesión
              </Menu.Item>
            </SubMenu>
          ) : (
            <Menu.Item key={ROUTES.SIGN_IN}>
              <Button
                type="primary"
                className="small-button"
                onClick={props.openLogin}
              >
                INICIAR SESIÓN
              </Button>
            </Menu.Item>
          )}
        </Menu>
      )}
    </>
  );
};

export default Navigation;
