import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Layout, List } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { FirebaseContext } from './../../context';
import * as ROUTES from '../../constants/routes';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Content } = Layout;

const renderArticle = (item) => {
  const { createdAt } = { item };
  const data = item.data();
  const { title, summary } = data;

  const time = moment(createdAt);

  return (
    <div className="education-card">
      <div className="education-card-header">
        <div className="date">{time.format('D [de] MMMM').toUpperCase()}</div>
        <div className="title">{title}</div>
      </div>

      <div className="education-card-summary">{summary}</div>

      <div className="education-card-button-wrapper">
        <NavLink
          to={{
            pathname: `/educacion/${item.id}`,
          }}
        >
          <ArrowRightOutlined />
        </NavLink>
      </div>
    </div>
  );
};
const ProfileEducation = () => {
  const firebase = useContext(FirebaseContext);
  const [articles, loading] = useCollection(
    firebase.db
      .collection('financialEducation')
      .orderBy('createdAt', 'desc')
      .limit(5),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <Content span={8} id="profile-education-container" className="with-shadow">
      <h1>
        <NavLink to={ROUTES.HOME}>Educación Financiera</NavLink>
      </h1>
      {loading && <div>Loading ...</div>}
      {!loading && (
        <List
          itemLayout="vertical"
          size="large"
          dataSource={articles.docs.slice(0, 5)}
          footer={<div></div>}
          renderItem={renderArticle}
        />
      )}
    </Content>
  );
};

export default ProfileEducation;
