// Iportar librerías
import React, { useState, useEffect, useContext } from 'react';
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Slider,
  Checkbox,
  message,
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ResponsivePie } from '@nivo/pie';
import GoogleMapReact from 'google-map-react';
import config from '../../config/firebase';
import _ from 'lodash';

// Importar otros componentes
import toPesos from '../../utils/toPesos';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar subcomopnente del compoente Select
const { Option } = Select;

// Obtener información de la sesión actual
const { sessionStorage } = window;

// Definir información de select para las propiedades
const propiedadOptions = [
  { value: 'casa', label: 'Casa' },
  { value: 'comercial', label: 'Comercial' },
  { value: 'departamento', label: 'Departamento' },
  { value: 'oficina', label: 'Oficina' },
  { value: 'terreno', label: 'Terreno' },
  { value: 'otro', label: 'Otro' },
];

// Definir información del select para el estado de las propiedades
const propertyConditionOptions = [
  { value: 'nueva', label: 'Nueva' },
  { value: 'usada', label: 'Usada' },
];

// Definir información de los seguros para el checkbox
export const insuranceOptions = [
  { value: 'desgravamen', label: 'Desgravamen', disabled: true },
  { value: 'incendios', label: 'Incendios', disabled: true },
  { value: 'sismos', label: 'Sismos *', disabled: false },
];

// Definir coordenadas y zoon por defecto para el componente GoogleMapReact
const DEFAULT_CENTER = {
  lat: -33.4472,
  lng: -70.6506,
};

const DEFAULT_ZOOM = 10;

const GeneralForm = ({ formInstance, style, uf, sizeWindow }) => {
  // Obtner información del context
  const authUser = useContext(AuthUserContext);

  // Definir state local
  const [adressInputStatus, setAdressInputStatus] = useState('');
  const [adressOptions, setAdressOptions] = useState([]);
  const [map, setMap] = useState({});
  const [maps, setMaps] = useState({});
  const [mapMarker, setMapMarker] = useState();
  const [placesService, setPlacesService] = useState({});
  const [piePercentageFocus, setPiePercentageFocus] = useState(false);
  const [, setPieFocus] = useState(false);
  const [, setValorFocus] = useState(false);
  const [credit, setCredit] = useState();
  const [minValue, setMinValue] = useState(0);
  const [withSubsidio, setWithSubsidio] = useState(false);
  const [isUfValue, setIsUfValue] = useState(true);
  const [isUfPie, setIsUfPie] = useState(true);

  // Definir nueva instancia del servicio de autocompletado de GoogleMap
  const autocompleteService = new window.google.maps.places.AutocompleteService();

  // Definir effect para setear losvalores iniciales del formulario
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglos de campos numéricos y strings
    const numberFields = [
      'valor',
      'piePorcentaje',
      'pie',
      'pieMoneda',
      'plazo',
      'valorMoneda',
      'credito',
      'subsidio',
    ];
    const stringFields = [
      'nombre',
      'propiedad',
      'direccion',
      'propertyCondition',
      'insurance',
    ];

    // Setear valores en caso de que haya información en la variable sessionStorage
    numberFields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item && !_.isNaN(item)) {
        values[f] = Number(item);
      }
    });

    stringFields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item) values[f] = item;
    });

    // Fijar Plazo en caso de que no esté definido
    if (!values.plazo) values.plazo = 30;

    // Actualizar valores de los etsados inputStatus y credit
    if (values['direccion']) setAdressInputStatus('success');
    if (values['credito']) setCredit(values['credito']);

    // Setear valores en el formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Escucha y modifica los valores asociados al valor numérico del formulario.
   */
  const onChangeValue = (changedValue, allValues) => {
    const field = Object.keys(changedValue)[0];
    const { valor, valorMoneda, pie, pieMoneda, piePorcentaje } = allValues;
    let putValue = 0;

    // Cambiar valores según sea el caso
    if (field === 'valorMoneda') {
      if (valor && valorMoneda) {
        formInstance.setFieldsValue({
          valor: valor / valorMoneda / valorMoneda / uf,
        });
      }
      setIsUfValue(!isUfValue);
    } else if (field === 'pieMoneda') {
      if (pie && pieMoneda) {
        putValue = minValue / pieMoneda / pieMoneda / uf;
        setMinValue(putValue);
        formInstance.setFieldsValue({ pie: pie / pieMoneda / pieMoneda / uf });
      }
      setIsUfPie(!isUfPie);
    } else if (field === 'pie') {
      if (pie && pieMoneda && valor && valorMoneda) {
        const truePie = pie * pieMoneda;
        const trueValor = valor * valorMoneda;
        formInstance.setFieldsValue({ piePorcentaje: (truePie / trueValor) * 100 });
      }
    } else if (field === 'piePorcentaje' || field === 'valor') {
      const conversion = valorMoneda / pieMoneda;
      if (piePorcentaje && pieMoneda && valor && valorMoneda) {
        let putValue = (valor * 10 * conversion) / 100;
        setMinValue(putValue);
        formInstance.setFieldsValue({
          pie: (valor * piePorcentaje * conversion) / 100,
        });
      }
    }

    const {
      valor: currValor,
      valorMoneda: currValorMoneda,
      pie: currPie,
      pieMoneda: currPieMoneda,
      subsidio,
      withSubsidio,
    } = formInstance.getFieldsValue();

    if (currValor && currValorMoneda && currPie && currPieMoneda) {
      const truePie = currPie * currPieMoneda;
      const trueValor = currValor * currValorMoneda;
      const trueSubsidio = withSubsidio ? subsidio : 0;
      formInstance.setFieldsValue({ credito: trueValor - truePie - trueSubsidio });
      setCredit(trueValor - truePie - trueSubsidio);
    }
  };

  /**
   * Se utiliza para autocompletar la dirección dada por el usuario.
   */
  const getAdressOptions = () =>
    adressOptions.map((a) => ({
      value: a.description,
    }));

  /**
   * Setea un error en caso de que la información de la dirección no sea correcta.
   */
  const handleAdressBlur = () => {
    if (adressInputStatus !== 'success') setAdressInputStatus('error');
  };

  /**
   *
   * @param {*} newValue
   * Ajusta la dirección en el mapa en caso de que el usuario la haya cambiado.
   */
  // Map functions
  const handleAdressChange = (newValue) => {
    setAdressInputStatus('');
    map.setCenter(DEFAULT_CENTER);
    map.setZoom(DEFAULT_ZOOM);
    if (mapMarker) mapMarker.setMap(null);
    if (newValue === '') return;
    autocompleteService.getPlacePredictions({ input: newValue }, (res) =>
      setAdressOptions(res || [])
    );
  };

  /**
   *
   * @param {*} e
   * Escucha por la dirección seleccionada de la lista de sugerencias y hace
   * los ajustes y actualizaciones correspondientes.
   */
  const handleAdressSelect = (e) => {
    setAdressInputStatus('success');
    var placeId = '';
    adressOptions.forEach((a) => {
      if (a.description === e) {
        placeId = a.place_id;
      }
    });
    placesService.getDetails(
      {
        placeId: placeId,
      },
      getPlaceDetailsCallback
    );
  };

  /**
   *
   * @param {*} details
   * Establece un marcador en el mapa según la longitud y latitud de una dirección dada.
   */
  const getPlaceDetailsCallback = (details) => {
    setMapMarker(
      new maps.Marker({
        position: {
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        },
        map,
      })
    );
    map.setZoom(15);
    map.setCenter({
      lat: details.geometry.location.lat(),
      lng: details.geometry.location.lng(),
    });
  };

  /**
   *
   * @param {*} map
   * @param {*} maps
   * Setea la configuración del mapa con la dirección actual al igual que el servicio.
   */
  const handleApiLoaded = (map, maps) => {
    setMap(map);
    setMaps(maps);
    setPlacesService(new window.google.maps.places.PlacesService(map));
  };

  /**
   * Valida si hay una dirección correta y ajusta el estado de la variable
   * addressInputStatus.
   */
  // Check if there is a valid adress on submit
  const handleSubmit = () => {
    formInstance.validateFields();
    if (adressInputStatus === 'success') formInstance.submit();
    else {
      setAdressInputStatus('error');
      message.info('Aún no se ha seleccionado una dirección');
    }
  };

  // Renderiza un componente con la información del financiamiento
  const ProgressText = () => {
    const subsidio = withSubsidio ? formInstance.getFieldValue('subsidio') : 0;
    const pie =
      formInstance.getFieldValue('pie') * formInstance.getFieldValue('pieMoneda');
    const percent = toPesos((100 * credit) / (credit + pie + subsidio));

    return (
      <div className="application-form-progress-text">
        <span>{`${percent}%`}</span>
        <span>FINANCIAMIENTO</span>
      </div>
    );
  };

  /**
   *
   * @param {*} errorFields
   * Muestra un mensaje de error en caso de que haya habido un problema con la información
   * del formulario.
   */
  const onFinishFailed = (errorFields) => {
    console.log(errorFields);
    message.info('Existen campos del formulario que aún no han sido completados');
  };

  // Renderizar
  return (
    <Form
      name="general-form"
      form={formInstance}
      initialValues={{ pieMoneda: 1, valorMoneda: 1 }}
      onValuesChange={onChangeValue}
      onFinishFailed={onFinishFailed}
      id="application-general-form"
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="nombre"
            label="Nombre de la Cotización"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona el nombre de la propiedad',
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 7}>
          <Form.Item
            name="propiedad"
            label="¿Qué tipo de propiedad quieres comprar?"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona un tipo de propiedad',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {propiedadOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 5}>
          <Form.Item
            name="propertyCondition"
            label="Condición de la propiedad"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona la condición de la propiedad',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {propertyConditionOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="direccion"
        label="Dirección"
        hasFeedback
        validateStatus={adressInputStatus}
        rules={[
          {
            required: true,
            message: 'Selecciona una dirección',
          },
        ]}
        help="Selecciona una dirección desde la lista de sugerencias"
      >
        <AutoComplete
          options={getAdressOptions()}
          onBlur={handleAdressBlur}
          onChange={handleAdressChange}
          onSelect={handleAdressSelect}
        ></AutoComplete>
      </Form.Item>

      <div className="application-form-map-wrapper">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.apiKey }}
          defaultCenter={DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      </div>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 992 ? 24 : 14}>
          <Row
            gutter={(0, 12)}
            style={sizeWindow < 992 ? { marginBottom: '20px' } : ''}
          >
            <Col span={sizeWindow < 992 ? 16 : 18}>
              <Form.Item
                name="valor"
                label="Valor"
                hasFeedback
                rules={[
                  {
                    message: 'Selecciona el valor de la propiedad',
                    required: true,
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  step={1000}
                  max={isUfValue ? 9999999 : 999999999999}
                  precision={0}
                  onFocus={() => setValorFocus(true)}
                  onBlur={() => setValorFocus(false)}
                  formatter={(value) => toPesos(value)}
                  parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                  onClick={(e) => e.target.select()}
                />
              </Form.Item>
            </Col>

            <Col span={sizeWindow < 992 ? 8 : 6}>
              <Form.Item name="valorMoneda" label=" ">
                <Radio.Group>
                  <Radio.Button value={1}>UF</Radio.Button>
                  <Radio.Button value={1 / uf}>CLP</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={(0, 12)}
            style={sizeWindow < 992 ? { marginBottom: '20px' } : ''}
          >
            <Col span={sizeWindow < 992 ? 7 : 6}>
              <Form.Item
                name="piePorcentaje"
                label="Pie"
                help="El valor mínimo del pie es del 10%"
                hasFeedback
                initialValue={20}
                rules={[
                  {
                    message: 'Selecciona el pie de la propiedad',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  min={10}
                  max={100}
                  precision={2}
                  type={piePercentageFocus ? 'number' : 'string'}
                  formatter={(value) =>
                    piePercentageFocus
                      ? value
                      : value
                      ? `${toPesos(value, 2)}%`
                      : '%'
                  }
                  parser={(value) =>
                    value.replace(',', '.').replace(/^(0.)|([^0-9.])/, '')
                  }
                  onFocus={() => setPiePercentageFocus(true)}
                  onBlur={() => setPiePercentageFocus(false)}
                  onClick={(e) => e.target.select()}
                />
              </Form.Item>
            </Col>
            <Col span={sizeWindow < 992 ? 9 : 12}>
              <Form.Item
                name="pie"
                label=" "
                hasFeedback
                rules={[
                  {
                    message: 'Selecciona el pie de la propiedad',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  min={minValue}
                  max={isUfPie ? 9999999 : 999999999999}
                  precision={0}
                  onFocus={() => setPieFocus(true)}
                  onBlur={() => setPieFocus(false)}
                  formatter={(value) => toPesos(value)}
                  parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                  onClick={(e) => e.target.select()}
                />
              </Form.Item>
            </Col>
            <Col span={sizeWindow < 992 ? 8 : 6}>
              <Form.Item name="pieMoneda" label=" ">
                <Radio.Group>
                  <Radio.Button value={1}>UF</Radio.Button>
                  <Radio.Button value={1 / uf}>CLP</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col span={24}>
              <Form.Item
                name="insurance"
                label="Seguros"
                help="* No es obligatorio elegir el seguro de sismos"
                initialValue={['desgravamen', 'incendios']}
              >
                <Checkbox.Group options={insuranceOptions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="plazo" label="Plazo (años)">
                <Slider
                  marks={{
                    0: '0 años',
                    15: '15 años',
                    30: '30 años',
                  }}
                  max={30}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="credito"
            style={{ display: 'none' }}
            rules={[
              {
                message: 'Selecciona el valor del crédito',
                required: true,
                type: 'number',
              },
              {
                max: formInstance.getFieldValue('valor'),
                message:
                  'El valor del crédito no puede exceder al valor de la propiedad',
                type: 'number',
              },
            ]}
          ></Form.Item>
          <Row gutter={(0, 12)}>
            <Col span={sizeWindow < 992 ? 12 : 8}>
              <Form.Item
                name="withSubsidio"
                label=""
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox onChange={(e) => setWithSubsidio(e.target.checked)}>
                  ¿Posee subsidio?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={sizeWindow < 992 ? 12 : 16}>
              <Form.Item
                name="subsidio"
                label="Subsidio (UF)"
                hasFeedback
                rules={[
                  {
                    message: 'Selecciona el subsidio',
                    required: true,
                  },
                ]}
                initialValue={0}
                hidden={!withSubsidio}
              >
                <InputNumber
                  min={0}
                  precision={0}
                  type="number"
                  onFocus={() => setPieFocus(true)}
                  onBlur={() => setPieFocus(false)}
                  formatter={(value) => toPesos(value)}
                  parser={(value) => value.replace(/([^0-9])/g, '') || 0}
                  onClick={(e) => e.target.select()}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={sizeWindow < 992 ? 24 : 10}>
          <Row className="application-form-general-summary">
            <Col span={10}>
              <ProgressText />
              <ResponsivePie
                width={sizeWindow < 768 ? 125 : 180}
                height={180}
                colors={['#050358', '#ebebeb', '#1CA9E9', '#0154C8', '#26C1C9']}
                data={[
                  {
                    id: 'financiamiento',
                    label: 'Financiamiento',
                    value: _.round(credit),
                  },
                  {
                    id: 'pie',
                    label: 'Pie',
                    value: _.round(
                      formInstance.getFieldValue('pie') *
                        formInstance.getFieldValue('pieMoneda')
                    ),
                  },
                  {
                    id: 'subsidio',
                    label: 'Subsidio',
                    value: withSubsidio ? formInstance.getFieldValue('subsidio') : 0,
                  },
                ]}
                margin={{ top: 16, bottom: 16, left: sizeWindow < 768 ? 0 : 16 }}
                innerRadius={0.9}
                padAngle={1}
                enableRadialLabels={false}
                sliceLabel={false}
                animate={true}
                tooltipFormat={(value) => `UF ${toPesos(value)}`}
              />
            </Col>
            <Col span={10} offset={4}>
              <div className="application-form-general-legend">
                <div className="legend">
                  <div className="dot"></div>
                  <div className="column">
                    <div className="title">Financiamiento</div>
                    <div className="subtitle">
                      {credit && `UF ${toPesos(credit)}`}
                    </div>
                  </div>
                </div>
                <div className="legend">
                  <div className="dot"></div>
                  <div className="column">
                    <div className="title">Pie</div>
                    <div className="subtitle">
                      {formInstance.getFieldValue('pie') &&
                        `UF ${toPesos(
                          formInstance.getFieldValue('pie') *
                            formInstance.getFieldValue('pieMoneda')
                        )}`}
                    </div>
                  </div>
                </div>
                {withSubsidio && (
                  <div className="legend">
                    <div className="dot"></div>
                    <div className="column">
                      <div className="title">Subsidio</div>
                      <div className="subtitle">
                        {`UF ${toPesos(formInstance.getFieldValue('subsidio'))}`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={sizeWindow < 992 ? 10 : 4} offset={sizeWindow < 992 ? 14 : 20}>
          <div className="application-form-figure">
            <span>Crédito Solicitado</span>
            <span>{credit && `UF ${toPesos(credit)}`}</span>
          </div>
        </Col>
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Col>
          <Button className="application-form-button" onClick={handleSubmit}>
            Siguiente
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default GeneralForm;
