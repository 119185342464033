// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Button, Col, Form, message, Modal, Row, Upload } from 'antd';
import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// Obtener context de firebase
import { FirebaseContext } from '../../context';

// Definir otros componentes
import Loading from '../Loading';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Definir información de la localidad
moment.locale('es');

const ProfileShowApplicationRequirements = () => {
  // Definir nueva instancia de useRouteMatch
  const route = useRouteMatch();

  // Obtener información del context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener id de la cotización
  const applicationUid = route.params.id;

  // Obtener información de la BD
  const [value, ,] = useDocument(
    firebase.applicationLogic.application(applicationUid)
  );

  // Definir state
  const [requirements, setRequirements] = useState([]);
  const [sendChangeLoading, setSendChangeLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalChange, setModalChange] = useState();
  const [downloadUrls, setDownloadURLs] = useState(null);

  // Definir nueva instancias de useForm
  const [form] = Form.useForm();

  // Aplicar effect para setear el valor de los requerimientos
  useEffect(() => {
    if (value) {
      setRequirements(value.data().requirements);
    }
  }, [value]);

  // Aplicar effect para permitir la descarga de todos los archivos que se encuentren adjuntados
  // en los requerimientos
  useEffect(() => {
    const getURLs = async () => {
      setLoading(true);
      const refs = [];

      requirements.forEach((requirement) => {
        if (!_.isEmpty(requirement.answer)) {
          requirement.answer.files.forEach((voucher) => refs.push(voucher));
        }
      });

      const Urls = {};
      const UrlsPromises = [];
      refs.forEach(async (ref) => {
        UrlsPromises.push(
          firebase.getDownloadURL(ref).then((url) => {
            Urls[ref] = {
              url,
              fileName: ref
                .split('/')
                .slice(1)
                .join('/'),
            };
          })
        );
      });

      await Promise.all(UrlsPromises);

      setDownloadURLs(Urls);
      setLoading(false);
    };

    getURLs();
  }, [requirements, firebase]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} e
   * TODO: Validar qué hace esta función.
   */
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  /**
   *
   * @param {*} fileRef
   * Permite la descarga de un archivo adjunto.
   */
  const handleFileClick = async (fileRef) => {
    setLoading(true);
    const url = await firebase.getDownloadURL(fileRef);
    window.open(url);
    setLoading(false);
  };

  /**
   *
   * @param {*} requirement
   * @param {*} i
   * Renderiza la información de los requerimientos nuevos del usuario.
   */
  const pendantChangeCard = (requirement, i) => {
    if (!_.isEmpty(requirement.answer)) return <React.Fragment key={i} />;
    return (
      <div className="change-card with-shadow" key={i}>
        <Row gutter={[0, windowWidth < 768 ? 18 : 0]}>
          <Col span={windowWidth < 768 ? 12 : 8}>
            <p>{_.capitalize(moment(requirement.createdAt.toDate()).calendar())}</p>
            <p>{requirement.title}</p>
          </Col>
          <Col span={windowWidth < 768 ? 12 : 8}>
            <p>{requirement.message}</p>
            {requirement.file && (
              <span
                className="link"
                onClick={() => handleFileClick(requirement.file)}
              >
                {requirement.file
                  .split('/')
                  .slice(1)
                  .join()}
                <DownloadOutlined />
              </span>
            )}
          </Col>
          <Col span={windowWidth < 768 ? 24 : 8}>
            <Button onClick={() => setModalChange(requirement)}>Responder</Button>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   *
   * @param {*} requirement
   * @param {*} i
   * Renderiza la información de los requerimientos viejos del usuario.
   */
  const previousChangeCard = (requirement, i) => {
    if (_.isEmpty(requirement.answer)) return <React.Fragment key={i} />;

    return (
      <div className="change-card with-shadow" key={i}>
        <Row gutter={[0, windowWidth < 768 ? 18 : 0]}>
          <Col span={windowWidth < 768 ? 12 : 8}>
            <p>{_.capitalize(moment(requirement.createdAt.toDate()).calendar())}</p>
            <p>{requirement.title}</p>
          </Col>
          <Col span={windowWidth < 768 ? 12 : 8}>
            <p>{requirement.message}</p>
          </Col>
          <Col span={windowWidth < 768 ? 24 : 8} className="previous-card">
            <div className="section-urls">
              <p>Archivos: </p>
              <ul>
                {requirement.answer.files.map((f) => (
                  <li key={f}>
                    <a
                      href={downloadUrls[f] ? downloadUrls[f].url : '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {downloadUrls[f] ? downloadUrls[f].fileName : ''}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * Envía la información de respuesta generada por parte del usuario.
   */
  const sendAnswer = async () => {
    const { files } = form.getFieldsValue(['files']);

    const storageRef = firebase.storage.ref(applicationUid);

    const hide = message.loading('Enviando respuesta...');
    setSendChangeLoading(true);
    let vouchers = [];
    if (files) {
      vouchers = await Promise.all(
        files.map((f) => {
          const fileStorageRef = storageRef.child(f.name);
          return fileStorageRef
            .put(f.originFileObj)
            .then((snapshot) => snapshot.ref.fullPath);
        })
      );
    }

    try {
      await firebase.applicationLogic.application(applicationUid).update({
        requirements: firebase.firestore.FieldValue.arrayRemove(modalChange),
      });

      const changeObj = modalChange;
      changeObj.answer = {
        files: vouchers,
        createdAt: firebase.firestore.Timestamp.now(),
      };

      await firebase.applicationLogic.application(applicationUid).update({
        requirements: firebase.firestore.FieldValue.arrayUnion(changeObj),
      });
      setSendChangeLoading(false);
      hide();
      setModalChange();
      message.success('Respuesta enviada exitosamente');
      form.resetFields();
    } catch {
      hide();
      message.error('Hubo un error al enviar tu respuesta');
    }
  };

  // Renderizar componente de Loading
  if (loading) return <Loading />;

  // Renderizar componente
  return (
    <div className="application-required-changes">
      <Modal
        visible={modalChange}
        title="Respuesta a cambio solicitado"
        okText="Enviar respuesta"
        onOk={form.submit}
        onCancel={() => setModalChange()}
        confirmLoading={sendChangeLoading}
        width={windowWidth < 768 ? 350 : 520}
      >
        <Form layout="vertical" form={form} onFinish={sendAnswer}>
          <Form.Item
            name="files"
            label="Archivos"
            getValueFromEvent={normFile}
            valuePropName="fileList"
            rules={[
              { required: true, message: 'Debes incluir al menos un archivo' },
            ]}
          >
            <Upload beforeUpload={() => false} multiple={true}>
              <Button>
                <PaperClipOutlined /> Seleccionar Archivos
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <p className="application-required-changes-section-title">
        Requerimientos Pendientes
      </p>
      <div className="pendant-changes-container">
        {requirements.map(pendantChangeCard)}
      </div>
      <p className="application-required-changes-section-title">Anteriores</p>
      <div className="pendant-changes-container">
        {requirements.map(previousChangeCard)}
      </div>
    </div>
  );
};

export default ProfileShowApplicationRequirements;
