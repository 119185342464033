// Importar librerías
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

// Importar otros componentes
import { ReactComponent as PortabilityMortgageCreditIcon } from './icons/portabilityMortgageCredit.svg';
import { ReactComponent as PortabilityConsumeCreditIcon } from './icons/portabilityConsumoCredit.svg';

// Importar rutas
import * as ROUTES from '../../constants/routes';

const NewAppPortabilityModal = ({ visible, setVisible, sizeWindow }) => {
  // Crear nueva instacia de history
  const history = useHistory();

  // Renderizar componente
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={sizeWindow < 768 ? 350 : 600}
    >
      <div id="new-application-modal-content">
        <div
          className="button-card with-shadow with-margin"
          onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_POR_HIP)}
        >
          <PortabilityMortgageCreditIcon />
          PORTABILIDAD CRÉDITO HIPOTECARIO
        </div>
        <div
          className="button-card with-shadow with-margin"
          onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_POR_CON)}
        >
          <PortabilityConsumeCreditIcon />
          PORTABILIDAD CRÉDITO DE CONSUMO
        </div>
      </div>
    </Modal>
  );
};

export default NewAppPortabilityModal;
