// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Radio,
  Row,
  Slider,
  Tooltip,
  Input,
  message,
} from 'antd';
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import _ from 'lodash';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar constantes
import vehicles from '../../constants/vehicles';

// Importar context de user
import { AuthUserContext } from '../../context';

// Definir información del ícono de un sólo pago
const iconMessage =
  'Este es un crédito donde el monto prestado y los intereses mensuales se pagan todo juntos al final del periodo';

// Definir arreglo con las opciones del Radio para las condiciones de pago del crédito
const paymentConditionOptions = [
  { value: 'cuotas', label: 'Cuotas' },
  { value: 'pago unico', label: 'Un Solo Pago' },
];

// Definir arreglo con las opciones para el tipo de vehículo
const vehicleConditionOptions = [
  { value: 'nuevo', label: 'Nuevo' },
  { value: 'usado', label: 'Usado' },
];

// Importar subcomponente Option
const { Option } = Select;

// Obtener información de la sesión
const { sessionStorage } = window;

const CreditInfoForm = (props) => {
  // Definir información del context
  const authUser = useContext(AuthUserContext);

  /// Aplicar destructuración
  const { formInstance, style, sizeWindow } = props;

  // Definir state local
  const [paymentOption, setPaymentOption] = useState('cuotas');
  const [vehicleOption, setVehicleOption] = useState('nuevo');

  // Definir effect para setear los valores del formulario
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};
    // Definir arreglo de campos del formulario
    const fields = [
      'vehicleValue',
      'vehicleType',
      'vehiclePie',
      'vehicleBrand',
      'vehicleModel',
      'credito',
      'plazo',
    ];
    // Setear valores en caso de que exista el campo lastApplicationInfo
    if (authUser && authUser.lastApplicationInfo) {
      // Obtener objeto
      const { lastApplicationInfo } = authUser;
      // Setear valores según sea el caso
      fields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (item && !_.isNaN(item)) {
          if (Number(item)) {
            values[f] = Number(item);
          } else {
            values[f] = item;
          }
        }
      });
    }
    // Setear valores en caso de que haya información en la variable sessionStorage
    fields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item && !_.isNaN(item)) {
        if (Number(item)) {
          values[f] = Number(item);
        } else {
          values[f] = item;
        }
      }
    });

    // Fijar Plazo en caso de que no esté definido
    if (!values.plazo) values.plazo = 12;

    // Fijar valor del crédito
    values.credito = values.vehicleValue - values.vehiclePie || 0;

    // Colocar valores en formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Escucha y modifica los valores asociados al valor numérico del formulario.
   */
  const onChangeValue = (changedValue, allValues) => {
    const field = Object.keys(changedValue)[0];
    const { vehicleValue, vehiclePie } = allValues;

    // Setear valor del crédito
    if (
      (field === 'vehicleValue' || field === 'vehiclePie') &&
      vehicleValue &&
      vehiclePie
    ) {
      formInstance.setFieldsValue({
        credito: vehicleValue - vehiclePie,
      });
    }
  };

  /**
   *
   * @param {*} errorFields
   * Muestra un mensaje de error en caso de que haya habido un problema con la información
   * del formulario.
   */
  const onFinishFailed = (errorFields) => {
    console.log(errorFields);
    message.info(
      'El monto del crédito no es válido o existen campos del formulario que aún no han sido completados'
    );
  };

  // Renderizar componente
  return (
    <Form
      name="creedit-status-form"
      id="credit-status-form"
      onValuesChange={onChangeValue}
      onFinishFailed={onFinishFailed}
      form={formInstance}
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="nombre"
            label="Nombre de la Cotización"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Coloca el nombre de la cotización',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="vehicleValue"
            label="Valor del Vehículo ($)"
            type="number"
            hasFeedback
            rules={[
              {
                message: 'Ingresa el valor del vehículo',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="vehicleType"
            label="Tipo de Vehículo"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona el tipo de vehículo',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {vehicles.map((vehicle) => (
                <Option key={vehicle} value={vehicle}>
                  {vehicle}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="vehiclePie"
            label="Pie ($)"
            type="number"
            hasFeedback
            rules={[
              {
                message: 'Ingresa el pie del vehículo',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="vehicleBrand"
            label="Marca del Vehículo"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Coloca la marca del vehículo',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="credito"
            label="Monto del Crédito ($) *"
            type="number"
            help="* El monto del crédito es la diferencia entre el valor del vehículo y el pie"
            rules={[
              {
                min: 1,
                message: 'El valor del crédito debe ser un valor positivo',
                type: 'number',
              },
            ]}
          >
            <InputNumber
              max={999999999999}
              formatter={(value) => toPesos(value)}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="vehicleModel"
            label="Modelo del Vehículo"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Coloca el modelo del vehículo',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={18}>
                  <Form.Item
                    name="paymentCondition"
                    label={'¿Cómo te gustaría pagar tu crédito?'}
                    rules={[
                      {
                        required: true,
                        message: 'Selecciona el parametro',
                      },
                    ]}
                    style={{ alignItems: 'center' }}
                    initialValue={'cuotas'}
                  >
                    <Radio.Group
                      options={paymentConditionOptions}
                      onChange={(e) => setPaymentOption(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                  }}
                >
                  {paymentOption === 'pago unico' && (
                    <Form.Item>
                      <Tooltip
                        placement="top"
                        title={iconMessage}
                        style={{ width: sizeWindow < 768 ? '50px' : '70px' }}
                        color="#e81687"
                      >
                        <ExclamationCircleOutlined className="info-icon-style" />
                      </Tooltip>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24} style={sizeWindow < 768 ? null : { marginTop: '15px' }}>
              {paymentOption === 'cuotas' && (
                <Form.Item
                  name="fechaPrimerPago"
                  label="Fecha Primer Pago"
                  type="date"
                  hidden={paymentOption !== 'cuotas'}
                  hasFeedback
                  rules={[
                    {
                      message: 'Ingresa la fecha de tu primer pago',
                      required: true,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} locale={locale} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Row>
            <Col
              span={sizeWindow < 768 ? 16 : 12}
              style={
                sizeWindow < 768
                  ? { marginTop: '20px' }
                  : paymentOption === 'cuotas'
                  ? { marginTop: '-55px' }
                  : null
              }
            >
              <Form.Item
                name="vehicleCondition"
                label="Estado del Vehículo"
                rules={[
                  {
                    required: true,
                    message: 'Selecciona el parametro',
                  },
                ]}
                initialValue={'nuevo'}
              >
                <Radio.Group
                  options={vehicleConditionOptions}
                  onChange={(e) => setVehicleOption(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              span={sizeWindow < 768 ? 8 : 12}
              style={
                sizeWindow < 768
                  ? { marginTop: '20px' }
                  : paymentOption === 'cuotas'
                  ? { marginTop: '-55px' }
                  : null
              }
            >
              {vehicleOption === 'usado' && (
                <Form.Item
                  name="vehicleYear"
                  label="Año"
                  hidden={vehicleOption !== 'usado'}
                  hasFeedback
                  rules={[
                    {
                      message: 'Ingresa el año del vehículo',
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={sizeWindow < 768 ? 24 : 12}
          style={
            sizeWindow < 768
              ? { marginTop: '15px' }
              : paymentOption === 'cuotas'
              ? { marginTop: '10px' }
              : null
          }
        >
          <Form.Item name="plazo" label="Plazo (meses)">
            <Slider
              marks={{
                1: '1 mes',
                30: '30 meses',
                60: '60 meses',
              }}
              min={1}
              max={60}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ justifyContent: 'flex-end' }}>
        <Col>
          <Button className="application-form-button" htmlType="submit">
            Siguiente
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditInfoForm;
