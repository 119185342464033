import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ArticleItem from './ArticleItem';

import * as ROUTES from '../../constants/routes';

const ArticlesPage = () => (
  <Router>
    <div>
      <Switch>
        <Route exact path={ROUTES.ARTICLE_PAGE} component={ArticleItem} />
      </Switch>
    </div>
  </Router>
);

export default ArticlesPage;
