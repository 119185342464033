// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Result,
  Modal,
  Divider,
  Radio,
  Checkbox,
  message,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import toPesos from '../../utils/toPesos';

// Importar context de user
import { AuthUserContext } from '../../context';

// Importar constantes
import regions from '../../constants/regions';
const { validate, format } = require('rut.js');

// Importar subcomponente Option
const { Option } = Select;

// Obtener información de la sesión actual
const { sessionStorage } = window;

// Definir información del ícono de un sólo pago
const iconMessage =
  'Documento necesario para realizar la portabilidad. Contiene todos los productos que tiene un banco o institución financiera con su cliente. Debe ser entregado de forma gratuita por la entidad al ser solicitado por el usuario para esta operación de portabilidad.';

// Definir arreglo con las opciones del Radio para la mejor respuesta
const bestAnswerParamOptions = {
  consume: [
    { value: 'cuota', label: 'Menor cuota' },
    { value: 'credito', label: 'Menor costo total de crédito' },
  ],
  portability: [
    { value: 'cuota', label: 'Menor cuota' },
    { value: 'credito', label: 'Menor costo total de crédito' },
  ],
  automotive: [
    { value: 'cuota', label: 'Menor cuota mensual' },
    { value: 'credito', label: 'Menor costo final de mi vehículo' },
  ],
};

// Definir constante con las opciones del selector del Estado laboral
export const estadoLaboralOptions = [
  { value: 'empleado', label: 'Empleado' },
  { value: 'independiente', label: 'Independiente' },
  { value: 'sin-empleo', label: 'Sin Empleo' },
  { value: 'otro', label: 'Otro' },
];

const UserInfoForm = (props) => {
  // Obtener información del context
  const authUser = useContext(AuthUserContext);

  /// Aplicar destructuración
  const {
    formInstance,
    style,
    handlePreviousButtonClick,
    createStatus,
    sizeWindow,
    contentFor,
  } = props;

  // Definir state
  const [regionComunas, setRegionComunas] = useState([]);

  // Definir effect para setear valores de los formularios
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglos de campos numéricos y strings
    const numberFields = ['ingresoFijo', 'ingresoVariable'];
    const stringFields = ['rut', 'estadoLaboral', 'region', 'comuna'];

    // Setear valores en caso de que exista el campo lastApplicationInfo
    if (authUser && authUser.lastApplicationInfo) {
      // Obtener objeto
      const { lastApplicationInfo } = authUser;

      // Setear valores numéricos
      numberFields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (!_.isNaN(item)) {
          values[f] = Number(item);
        }
      });

      // Setear cadenas
      stringFields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (item) values[f] = item;
      });
    }

    // Setear valores en caso de que haya información en la variable sessionStorage
    numberFields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item && !_.isNaN(item)) {
        values[f] = Number(item);
      }
    });

    stringFields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item) values[f] = item;
    });

    // Setear Valores en el formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  // Definir effect para habilitar comunas cuando se reselecciona la opción por defecto

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Escucha y modifica los valores asociados al valor numérico del formulario.
   */
  const onChangeValue = (changedValue, allValues) => {
    const field = Object.keys(changedValue)[0];
    if (field === 'rut') {
      const { rut } = allValues;
      if (!rut || rut === '-') formInstance.setFieldsValue({ rut: '' });
      else formInstance.setFieldsValue({ rut: format(rut) });
    }
  };

  /**
   *
   * @param {*} value
   * Reinicializa el valor de la comuna para cualquier valor de la región seleccionada
   * inclusive si está es reelgida múltiples veces.
   */
  const reSelectedItem = (value) => {
    setRegionComunas(regions[value]);
    formInstance.setFieldsValue({ comuna: null });
  };

  // Renderizar información del modal
  const ModalContent = () => {
    let resultProps;
    if (createStatus === 'loading') {
      resultProps = {
        icon: <LoadingOutlined />,
        title: 'Creando cotización',
        subTitle: 'Por favor, espere unos segundos...',
      };
    } else if (createStatus === 'success') {
      let hoursLeft = 24;
      if (moment().day() > 5) hoursLeft = 72;
      else if (moment().day() === 5 && moment().hour() >= 12) hoursLeft = 72;

      resultProps = {
        status: 'success',
        title: '¡Cotización creada!',
        subTitle: `Su cotización será respondida en ${hoursLeft} horas`,
        extra: [
          <Link to={ROUTES.PROFILE_APPLICATIONS} key="1">
            <Button>Ir a mis cotizaciones</Button>
          </Link>,
        ],
      };
    } else if (createStatus === 'error') {
      resultProps = {
        status: 'error',
        title: 'Ocurrio un error',
        subTitle:
          'Por favor, intenta nuevamente. Si el error consiste, contáctate con nuestro equipo.',
      };
    }

    return <Result {...resultProps} />;
  };

  /**
   *
   * @param {*} errorFields
   * Muestra un mensaje de error en caso de que haya habido un problema con la información
   * del formulario.
   */
  const onFinishFailed = (errorFields) => {
    console.log(errorFields);
    message.info('Existen campos del formulario que aún no han sido completados');
  };

  // Renderizar componente
  return (
    <Form
      name="user-status-form"
      form={formInstance}
      id="application-general-form"
      layout="vertical"
      onValuesChange={onChangeValue}
      onFinishFailed={onFinishFailed}
      hideRequiredMark
      style={style}
    >
      <Modal
        visible={createStatus}
        footer={false}
        closable={false}
        className="application-result-modal"
        width={sizeWindow < 768 ? 350 : 520}
      >
        <ModalContent />
      </Modal>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="rut"
            label="RUT"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Ingresa tu RUT',
              },
              () => ({
                validator(rule, value) {
                  if (validate(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Ingresa un RUT válido');
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="estadoLaboral"
            label="Estado Laboral"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona tu estado laboral',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {estadoLaboralOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="ingresoFijo"
            label="Ingreso Mensual Fijo (CLP)"
            type="number"
            hasFeedback
            rules={[
              {
                message: 'Coloca tu ingreso mensual fijo',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="ingresoVariable"
            label="Ingreso Mensual Variable (CLP)"
            type="number"
            hasFeedback
            rules={[
              {
                message: 'Coloca tu ingreso mensual vairable',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="region"
            label="Región"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona tu región',
              },
            ]}
          >
            <Select
              placeholder="Selecciona"
              showSearch
              onSelect={(_, selected) => reSelectedItem(selected.value)}
            >
              {Object.keys(regions).map((region) => (
                <Option key={region} value={region}>
                  {region}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="comuna"
            label="Comuna"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona tu comuna',
              },
            ]}
          >
            <Select placeholder="Selecciona" showSearch>
              {regionComunas.map((comuna) => (
                <Option key={comuna} value={comuna}>
                  {comuna}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Form.Item
        name={'bestAnswerParam'}
        key={'bestAnswerParam'}
        label={'¿Cómo te gustaría que se eligiera tu crédito?'}
        rules={[
          {
            required: true,
            message: 'Selecciona el parametro',
          },
        ]}
        style={{ alignItems: 'center' }}
      >
        <Radio.Group options={bestAnswerParamOptions[contentFor]} />
      </Form.Item>

      <Row>
        {sizeWindow > 768 && <Col span={12}></Col>}
        <Col
          span={sizeWindow < 768 ? 24 : 12}
          style={
            contentFor === 'portability' && sizeWindow < 768
              ? { marginTop: '10px' }
              : null
          }
        >
          {contentFor === 'portability' && (
            <Form.Item
              name="license"
              valuePropName="checked"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Debes autorizar la solicitud del certificado de liquidación'
                    );
                  },
                }),
              ]}
              className="flex-start"
            >
              <Checkbox>
                <b>
                  Autorizo la Solicitud del Certificado de Liquidación a la
                  Institución Financiera Actual
                </b>
                <Tooltip
                  placement="top"
                  title={iconMessage}
                  style={{ width: sizeWindow < 768 ? '50px' : '70px' }}
                  color="#e81687"
                >
                  <QuestionCircleOutlined className="info-icon-style" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            name="terms"
            valuePropName="checked"
            rules={[
              () => ({
                validator(rule, value) {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Debes aceptar los términos y condiciones');
                },
              }),
            ]}
            className={
              contentFor === 'portability'
                ? 'flex-start'
                : sizeWindow < 768
                ? 'flex-center'
                : 'flex-end'
            }
          >
            <Checkbox>
              He leído y acepto los{' '}
              <a target="_blank" rel="noopener noreferrer" href={ROUTES.TERMS}>
                Términos y Condiciones
              </a>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <Button
            className="application-form-button application-form-button-secondary"
            onClick={handlePreviousButtonClick}
          >
            <ArrowLeftOutlined />
            Anterior
          </Button>
        </Col>
        <Col>
          <Button className="application-form-button magenta" htmlType="submit">
            Enviar Cotización
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserInfoForm;
