// Importar librerías
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

// Importar otros componentes
import { ReactComponent as MortgageCreditIcon } from './icons/mortgageCredit.svg';
import { ReactComponent as ConsumeCreditIcon } from './icons/consumeCredit.svg';
import { ReactComponent as PortabilityIcon } from './icons/portability.svg';
import { ReactComponent as AutomotiveCreditIcon } from './icons/automotiveCredit.svg';
import NewAppPortabilityModal from './NewAppPortabilityModal';

// Importar rutas
import * as ROUTES from '../../constants/routes';

const NewApplicationModal = ({ visible, setVisible, sizeWindow }) => {
  // Definir state local
  const [openPortabilityModal, setOpenPortabilityModal] = useState(false);

  // Crear nueva instacia de history
  const history = useHistory();

  /**
   * Cierra un modal y abre otro con las opciones de portabilidad
   */
  const changeModal = () => {
    setVisible(false);
    setOpenPortabilityModal(true);
  };

  // Renderizar componente
  return (
    <>
      <NewAppPortabilityModal
        visible={openPortabilityModal}
        setVisible={setOpenPortabilityModal}
        sizeWindow={sizeWindow}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={sizeWindow < 768 ? 350 : 600}
      >
        <div id="new-application-modal-content">
          <div
            className="button-card with-shadow with-margin"
            onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_HIP)}
          >
            <MortgageCreditIcon />
            CRÉDITO HIPOTECARIO
          </div>
          <div
            className="button-card with-shadow with-margin"
            onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_CON)}
          >
            <ConsumeCreditIcon />
            CRÉDITO DE CONSUMO
          </div>
          <div
            className="button-card with-shadow with-margin"
            onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_AUTO_CREDIT)}
          >
            <AutomotiveCreditIcon />
            CRÉDITO AUTOMOTRIZ
          </div>
          <div
            className="button-card with-shadow with-margin"
            onClick={() => changeModal()}
          >
            <PortabilityIcon />
            PORTABILIDAD BANCARIA
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewApplicationModal;
