// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Descriptions, message } from 'antd';
import {
  DownloadOutlined,
  BorderOutlined,
  CheckSquareFilled,
} from '@ant-design/icons';
import _ from 'lodash';

// Importar context
import { FirebaseContext } from '../../context';

// Importar información de los seguros
import { insuranceOptions } from '../MortgageApplicationForm/GeneralForm';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar otros componentes
import Loading from '../Loading';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const ProfileShowMortgageApplicationData = () => {
  // Definir nueva instancia de route
  const route = useRouteMatch();

  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener id de la url actual
  const applicationUid = route.params.id;

  // Hacer consulta a la BD
  const [value, ,] = useDocument(
    firebase.applicationLogic.application(applicationUid)
  );

  // Definir state
  const [data, setData] = useState({});
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Definir effect para setear valores
  useEffect(() => {
    const getURLs = async (refs) => {
      const UrlPromises = refs.map(async (ref) => {
        const url = await firebase.getDownloadURL(ref);
        return {
          url,
          fileName: ref
            .split('/')
            .slice(1)
            .join('/'),
        };
      });

      const Urls = await Promise.all(UrlPromises);
      setDownloadURLs(Urls);
    };

    if (value) {
      // Setear información de la aplicación
      setData(value.data());

      // Obtener información del último elemento del chat, del id del usuario
      // y del estado de la solicitud
      const chatLastItem =
        value.get('chat').length >= 1
          ? value.get('chat')[value.get('chat').length - 1]
          : null;
      const status = value.get('status');
      const userId = value.get('user').id;

      // Mostrar mensaje si se cumple las condiciones
      if (
        status !== 'accepted' &&
        status !== 'userRejected' &&
        chatLastItem &&
        chatLastItem.senderId !== userId
      ) {
        message.info('En el chat, tienes nuevos mensajes de tu ejecutivo asociado');
      }

      // Obtener documentos asociado a la aplciación
      const downloadRefs = value
        .data()
        .certificadoAFP.concat(value.data().cedula)
        .concat(value.data().liquidaciones);
      getURLs(downloadRefs);

      setLoading(false);
    }
  }, [value, firebase]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente Loading en caso de que no se haya cargado la información
  if (loading) return <Loading />;

  // Renderizar componente
  return (
    <>
      <Descriptions
        layout="vertical"
        className="application-show-descriptions application-credit-data application-data"
        column={{ xs: 2, md: data.valor ? 4 : 3 }}
        colon={false}
      >
        <Descriptions.Item label="Tipo de Propiedad">
          {_.capitalize(data.propiedad)}
        </Descriptions.Item>

        <Descriptions.Item label="Condición de la Propiedad">
          {_.capitalize(data.propertyCondition)}
        </Descriptions.Item>

        <Descriptions.Item label="Dirección">{data.direccion}</Descriptions.Item>

        {data.valor && (
          <Descriptions.Item label="Valor de la Propiedad">
            {`${toPesos(data.valor)} UF`}
          </Descriptions.Item>
        )}
      </Descriptions>

      <Descriptions
        layout="vertical"
        className="application-show-descriptions application-credit-data application-data additional-property"
        column={{ xs: 2, md: 4, lg: 12 }}
        colon={false}
      >
        {data.piePorcentaje ? (
          <Descriptions.Item
            label="Pie"
            span={windowWidth < 768 ? 1 : 1}
          >{`${_.round(data.piePorcentaje, 2)}%`}</Descriptions.Item>
        ) : (
          <Descriptions.Item
            label="Banco Actual Crédito"
            span={windowWidth < 768 ? 1 : 3}
          >
            {data.otroBanco || data.banco}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={
            data.type === 'hipotecario'
              ? 'Monto del Crédito'
              : 'Monto Vigente Crédito'
          }
          span={windowWidth < 768 ? 1 : 3}
        >
          {`${toPesos(data.credito)} UF`}
        </Descriptions.Item>

        <Descriptions.Item label="Plazo" span={windowWidth < 768 ? 1 : 2}>{`${
          data.plazo
        } año${data.plazo > 1 ? 's' : ''}`}</Descriptions.Item>

        <Descriptions.Item
          label="Seguros"
          className="insurance-description"
          span={windowWidth < 768 ? 1 : 4}
        >
          {insuranceOptions.map((s) => {
            let Icon = <BorderOutlined />;
            if (data.insurance.includes(s.value)) {
              Icon = <CheckSquareFilled />;
            }
            return (
              <React.Fragment key={s.value}>
                <div className="insurance-items">
                  {Icon}
                  {s.label}
                </div>
              </React.Fragment>
            );
          })}
        </Descriptions.Item>

        {data.aval && (
          <>
            {windowWidth >= 768 && <Descriptions.Item span={1}></Descriptions.Item>}
            <Descriptions.Item
              label="Aval o Codeudor"
              span={windowWidth < 768 ? 1 : 3}
            >
              Si
            </Descriptions.Item>
            <Descriptions.Item label="RUT Codeudor" span={windowWidth < 768 ? 1 : 4}>
              {data.rutCod}
            </Descriptions.Item>
            <Descriptions.Item
              label="Seguro Desgravamen Codeudor"
              span={windowWidth < 768 ? 1 : 3}
            >
              {data.takeInsurance ? 'Si' : 'No'}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>

      <Descriptions
        layout="vertical"
        className="application-show-descriptions application-credit-financial application-financial"
        column={{ xs: 4, sm: 4, md: 5, lg: 5 }}
        colon={false}
      >
        <Descriptions.Item
          label="Ingresos (CLP)"
          span={5}
          className="descriptions-section-title"
        />

        <Descriptions.Item label="Estado Laboral">
          {_.capitalize(data.estadoLaboral)}
        </Descriptions.Item>

        <Descriptions.Item label="Fijos">
          {`${toPesos(data.ingresoFijo)}`}
        </Descriptions.Item>

        <Descriptions.Item label="Variables" span={2}>
          {`${toPesos(data.ingresoVariable)}`}
        </Descriptions.Item>

        {windowWidth < 768 ? (
          <Descriptions.Item
            label="INGRESOS TOTALES"
            className="descriptions-section-result"
            span={4}
          >
            {`${toPesos(data.totalIngresos)}`}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item
            label="INGRESOS TOTALES"
            className="descriptions-section-result"
            span={2}
          >
            {`${toPesos(data.totalIngresos)}`}
          </Descriptions.Item>
        )}

        {data.type === 'hipotecario' && (
          <>
            <Descriptions.Item
              label="Activos (CLP)"
              span={5}
              className="descriptions-section-title"
            />
            <Descriptions.Item label="Bienes Raíces">
              {`${toPesos(data.bienesRaices)}`}
            </Descriptions.Item>

            <Descriptions.Item label="Vehículos">
              {`${toPesos(data.vehiculos)}`}
            </Descriptions.Item>

            <Descriptions.Item label="Inversiones">
              {`${toPesos(data.inversiones)}`}
            </Descriptions.Item>

            <Descriptions.Item label="Otros">
              {`${toPesos(data.otrosActivos)}`}
            </Descriptions.Item>

            <Descriptions.Item
              label="TOTAL ACTIVOS"
              className="descriptions-section-result"
              span={4}
            >
              {`${toPesos(data.totalPatrimonio)}`}
            </Descriptions.Item>

            <Descriptions.Item
              label="Gastos Mensuales (CLP)"
              span={5}
              className="descriptions-section-title"
            />

            <Descriptions.Item label="Dividendo o Arriendo">
              {`${toPesos(data.hipotecario)}`}
            </Descriptions.Item>

            <Descriptions.Item label="Tarjetas y Líneas de Crédito">
              {`${toPesos(data.tarjetasLineasCredito)}`}
            </Descriptions.Item>

            <Descriptions.Item label="Créditos de Consumo">
              {`${toPesos(data.creditosConsumo)}`}
            </Descriptions.Item>

            <Descriptions.Item label="Otros">
              {`${toPesos(data.otrosGastos)}`}
            </Descriptions.Item>

            <Descriptions.Item
              label="TOTAL DEUDAS"
              className="descriptions-section-result"
            >
              {`${toPesos(data.totalDeudas)}`}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>

      {data.type === 'hipotecario' && (
        <div className="application-data-downloads">
          <p>Archivos Adjuntos</p>
          {downloadURLs.map((v, i) => (
            <a href={v.url} key={i}>
              {v.fileName}
              <DownloadOutlined />
            </a>
          ))}
        </div>
      )}
    </>
  );
};

export default ProfileShowMortgageApplicationData;
