// Importar librerías
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Importar otros componentes
import { ReactComponent as MortgageCreditIcon } from '../Profile/icons/mortgageCredit.svg';
import { ReactComponent as ConsumeCreditIcon } from '../Profile/icons/consumeCredit.svg';
import { ReactComponent as PortabilityIcon } from '../Profile/icons/portability.svg';
import { ReactComponent as AutomotiveCreditIcon } from '../Profile/icons/automotiveCredit.svg';
import NewAppPortabilityModal from '../Profile/NewAppPortabilityModal';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const Landing = () => {
  // Definir state local
  const [openPortabilityModal, setOpenPortabilityModal] = useState(false);

  // Definir nueva instancia de history
  const history = useHistory();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <>
      <NewAppPortabilityModal
        visible={openPortabilityModal}
        setVisible={setOpenPortabilityModal}
        sizeWindow={windowWidth}
      />
      <div id="landing-container">
        <div className="section-content">
          <p id="landing-title">Encuentra tu crédito con un solo click</p>
          <p id="landing-subtitle">
            Consigue la mejor oferta de crédito, en un solo lugar y 100% gratis
          </p>
          <div id="new-application-modal-content">
            <div
              className="button-card"
              onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_HIP)}
            >
              <MortgageCreditIcon />
              <p>CRÉDITO HIPOTECARIO</p>
            </div>
            <div
              className="button-card"
              onClick={() => history.push(ROUTES.PROFILE_APPLICATION_FORM_CON)}
            >
              <ConsumeCreditIcon />
              <p>CRÉDITO DE CONSUMO</p>
            </div>
            <div
              className="button-card"
              onClick={() =>
                history.push(ROUTES.PROFILE_APPLICATION_FORM_AUTO_CREDIT)
              }
            >
              <AutomotiveCreditIcon />
              <p>CRÉDITO AUTOMOTRIZ</p>
            </div>
            <div
              className="button-card"
              onClick={() => setOpenPortabilityModal(true)}
            >
              <PortabilityIcon />
              <p>PORTABILIDAD BANCARIA</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
