class authenticationLogic {
  constructor(app, auth, db, userLogic) {
    this.auth = auth;
    this.db = db;
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.userLogic = userLogic;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = async (
    email,
    firstName,
    lastName,
    roles,
    password
  ) => {
    const authUser = await this.auth.createUserWithEmailAndPassword(email, password);
    await this.userLogic.newUser(authUser.user.uid, {
      firstName,
      lastName,
      email,
      roles,
    });

    return this.doSendEmailVerification();
  };

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
    let url_redirect;
    switch (process.env.REACT_APP_ENV) {
      case 'dev':
        url_redirect = process.env.REACT_APP_DEV_CONFIRMATION_EMAIL_REDIRECT;
        break;
      case 'staging':
        url_redirect = process.env.REACT_APP_STAGE_CONFIRMATION_EMAIL_REDIRECT;

        break;
      case 'prod':
        url_redirect = process.env.REACT_APP_PROD_CONFIRMATION_EMAIL_REDIRECT;
        break;

      default:
        break;
    }

    return this.auth.currentUser.sendEmailVerification({
      url: url_redirect,
    });
  };

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);
}

export default authenticationLogic;
