// Importar librerías
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

// Importar rutas
import * as ROUTES from '../constants/routes';

// Importar context de user
import { AuthUserContext } from '../context';
import _ from 'lodash';

const PrivateRoute = ({ component: Component, loged = true, ...rest }) => {
  // Obtener context del usuario
  const authUser = useContext(AuthUserContext);

  // Renderizar componente
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          authUser &&
          !_.isEmpty(authUser.roles) &&
          _.isUndefined(authUser.roles.NORMAL)
        ) {
          return <Redirect to={ROUTES.HOME} />;
        }

        if ((authUser !== null) === loged) {
          return <Component {...props} />;
        } else {
          return <Redirect to={ROUTES.SIGN_IN} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
