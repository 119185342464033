import React, { useContext } from 'react';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';

import { FirebaseContext } from './../../context';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

const DATE_FORMAT = 'HH:mm DD/MM/YYYY';

const ArticleItem = () => {
  const location = useLocation();
  const firebase = useContext(FirebaseContext);
  const articleId = location.pathname.split('/')[2];
  const [article, loading, error] = useDocumentDataOnce(
    firebase.articleLogic.article(articleId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) return <Spin></Spin>;
  if (error) return <p>Error</p>;

  if (article.type === 'full') {
    return (
      <div id="articles-content">
        <ReactMarkdown
          className={'markdown-container'}
          escapeHtml={false}
          source={article.article_content.replace(/_n/gm, '\n')}
        />
      </div>
    );
  }

  return (
    <div id="articles-content">
      <h1>{article.title}</h1>
      <p className="summary">{article.summary}</p>
      <p className={'date'}>
        {moment(article.createdAt.toDate()).format(DATE_FORMAT)}
      </p>
      <div>
        <img classname="link" src={article.photoUrl} alt={'Imagen de la noticia'} />
      </div>
      <a className="link" href={article.link}>
        Ver la noticia entera
      </a>
    </div>
  );
};

export default ArticleItem;
