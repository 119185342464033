// Importar librerías
import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Layout, Form, Input, Row, Col, Button, message } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

// Importar otros componentes
import Navigation from '../Navigation';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Obtener subcomponente Header
const { Header } = Layout;

// Definir posibles errores de autenticación
const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

function FullHeader() {
  // Definir instancias de location e history
  const location = useLocation();
  const history = useHistory();

  // Definir context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [headerClass, setHeaderClass] = useState('header');
  const [loginState, setLoginState] = useState(false);
  const [form] = Form.useForm();

  // Definir effect para el evento del Scroll
  useEffect(() => {
    listenScrollEvent();

    window.addEventListener('scroll', listenScrollEvent);

    // Limpiar evento
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * Asigna el valor de una clase al componente principal según sea
   * el valor del scroll vertical que tenga la venta actual.
   */
  const listenScrollEvent = () => {
    if (location.pathname !== '/') {
      setHeaderClass('header');
    } else if (window.scrollY > 40) {
      setHeaderClass('header scrolled-home-header');
    } else {
      setHeaderClass('header home-header');
    }
  };

  /**
   *
   * @param {*} e
   * Muestra u oculpa el minicomponente login.
   */
  const openLogin = (e) => {
    setLoginState(!loginState);
  };

  /**
   *
   * @param {*} values
   * Verifica que el correo y la contraseñas dadas por el
   * usuario sean correcta para poder ingresar a la página.
   */
  const onFinish = async (values) => {
    // Hacer consultar y validar la información aportada
    try {
      // Aplicar destructuración
      const { email, password } = values;
      await firebase.authLogic.doSignInWithEmailAndPassword(email, password);
      setLoginState(false);
      history.push(ROUTES.PROFILE_HOME);
    } catch (error) {
      message.error('Email o contraseña incorrectos');
      history.push(ROUTES.SIGN_IN);
    }
  };

  /**
   * Permite al usuario loguearse usando su cuenta de gmail.
   */
  const googleSignIn = async () => {
    // Obtener información de las credenciales de google
    const socialAuthUser = await firebase.authLogic.doSignInWithGoogle();

    // Intentar logueo usando las credenciales provenientes del google
    try {
      await firebase.userLogic.user(socialAuthUser.user.uid).set(
        {
          firstName: socialAuthUser.additionalUserInfo.profile.given_name,
          lastName: socialAuthUser.additionalUserInfo.profile.family_name,
          email: socialAuthUser.user.email,
          roles: {},
        },
        { merge: true }
      );
      setLoginState(false);
      history.push(ROUTES.PROFILE_HOME);
    } catch (error) {
      // TODO: Validar que esta parte de los errores funcione
      // Create a user in your Firebase Realtime Database too
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        message.error('Tu correo de google ya tiene una cuenta asociada');
      }
      message.error('Ha ocurrido un error al intentar iniciar sesión con Google');
    }
  };

  // Renderizar componente
  return (
    <Header className={headerClass}>
      <Navigation openLogin={openLogin} />
      {loginState && (
        <div className="login-nav-div">
          <div className="title">
            <p>Iniciar Sesión</p>
            <a href="https://bank.flit.cl/">o ir al portal de bancos</a>
          </div>

          <Form form={form} onFinish={onFinish}>
            <p>Email</p>
            <Form.Item name="email">
              <Input />
            </Form.Item>
            <p>Contraseña</p>
            <Form.Item name="password">
              <Input type="password" />
            </Form.Item>
            <Link to={ROUTES.PASSWORD_FORGET}>
              <p className="center-text">¿Olvidaste tu Contraseña?</p>
            </Link>
            <Row gutter={(12, 6)}>
              <Col span={12}>
                <Link to={ROUTES.SIGN_IN}>
                  <Button htmlType="submit" className="button-login-nav">
                    Crear una Cuenta
                  </Button>
                </Link>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-login-nav"
                >
                  Iniciar Sesión
                </Button>
              </Col>
            </Row>

            <Button
              icon={<GoogleOutlined />}
              type="primary"
              onClick={googleSignIn}
              block
            >
              Iniciar Sesión con Google
            </Button>
          </Form>
        </div>
      )}
    </Header>
  );
}
export default FullHeader;
