// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import moment from 'moment';
import { Button, ConfigProvider, Layout, Table } from 'antd';

// Importar otros componentes
import NewApplicationModal from './NewApplicationModal';
import { ReactComponent as EmptyIcon } from './icons/emptyIcon.svg';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar context de firebase y user
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponente Content
const { Content } = Layout;

/**
 *
 * @param {*} word
 * Retorna una cadena con la primera letra en mayúscula.
 */
const capitalize = (word) => {
  let newWord = '';
  word.split(' ').forEach((item) => {
    newWord += item[0].toUpperCase() + item.slice(1) + ' ';
  });
  return newWord;
};

const ProfileHomeApplications = () => {
  // Definir context
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);

  // Obtener información de la BD
  const [values, loading] = useCollection(
    firebase.applicationLogic.applications().where('user.id', '==', authUser.uid)
  );

  // Definir state
  const [data, setData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  // Definir effect para guardar la información de la BD una vez se tengan datos
  useEffect(() => {
    if (values) setData(values.docs.map((doc) => ({ ...doc.data(), uid: doc.id })));
  }, [values]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   *
   * @param {*} text
   * @param {*} row
   * Se encarga de definir la acciones que serán ejecutadas en la tabla de cotizaciones
   * del usuario.
   */
  const renderActions = (text, row) => {
    return (
      <Link to={`${ROUTES.PROFILE_APPLICATIONS}/${row.uid}`} disabled={!row.endDate}>
        Ver
      </Link>
    );
  };

  // Definir información de las columnas que serán presentadas en la tabla a graficar
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'name',
      show: true,
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'date',
      show: true,
      render: (text) => (text ? moment(text.toDate()).format('DD[/]MM[/]YYYY') : ''),
    },
    {
      title: 'Hora',
      dataIndex: 'createdAt',
      key: 'hour',
      show: windowWidth < 768 ? false : true,
      render: (text) => (text ? moment(text.toDate()).format('HH[:]mm [hrs]') : ''),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      show: true,
      render: (text) => capitalize(text.replace('-', ' ')),
    },
    {
      title: 'Última Actualización',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      show: windowWidth < 768 ? false : true,
      render: (text) =>
        text ? moment(text.toDate()).format('DD[/]MM[/]YYYY - HH[:]mm [hrs]') : '',
    },
    {
      title: 'Acciones',
      key: 'actions',
      show: true,
      render: renderActions,
    },
  ];

  /**
   * Renderiza un componente loalpara cuando el usuario no tiene información de
   * cotizaciones activas.
   */
  const EmptyData = () => {
    if (loading) return <div id="applications-empty" />;
    return (
      <div id="applications-empty">
        <EmptyIcon />
        <div className="title">Tus Cotizaciones</div>
        <div className="subtitle">No Tienes Cotizaciones</div>
      </div>
    );
  };

  // Renderizar componente
  return (
    <Content className="with-shadow">
      <NewApplicationModal
        visible={modalVisible}
        setVisible={setModalVisible}
        sizeWindow={windowWidth}
      />
      <div className={'table-header'} style={{ padding: 0 }}>
        <h1 style={{ marginBottom: 0 }}>Cotizaciones Activas</h1>
        <Button
          className={'table-header-button'}
          onClick={() => setModalVisible(true)}
        >
          + Nueva Cotización
        </Button>
      </div>
      <ConfigProvider renderEmpty={EmptyData}>
        <Table
          columns={columns.filter((column) => column.show === true)}
          dataSource={data}
          loading={loading}
          rowKey="uid"
          className="applications-table"
          size={windowWidth < 768 ? 'middle' : 'large'}
        />
      </ConfigProvider>
    </Content>
  );
};

export default ProfileHomeApplications;
