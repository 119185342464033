import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import config from './../../config/firebase';
import authenticationLogic from './authentication';
import userLogic from './user';
import articleLogic from './article';
import applicationLogic from './applications';
import financialDataLogic from './financialData';

/**Lo que esta en la clase Firebase, puede ser accedido en cualquier lugar. Para usarlos hay que hacer 2 cosas
1. Poner a lo mas arriba del arbol <FirebaseContext.Provider value=new Firebase()> (Ya esta)
2. Cuando queramos acceder a algun elemento de la clase firebase usar 
<FirebaseContext.Consumer>
  {firebase => {
    return <div>I've access to Firebase and render something.</div>;
  }}
</FirebaseContext.Consumer> 
*/
class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.functions = app.functions();

    // Funciones de colecciones de usuario
    this.userLogic = new userLogic(this.db, app.firestore.Timestamp);

    // Funciones de authenticacion
    this.authLogic = new authenticationLogic(
      app,
      this.auth,
      this.db,
      this.userLogic
    );

    // Funciones de coleeccion de articulos
    this.articleLogic = new articleLogic(this.db, this.fieldValue);
    // Funciones de colecciones de mensajes
    this.applicationLogic = new applicationLogic(
      this.db,
      this.storage,
      this.fieldValue,
      app.firestore.Timestamp
    );
    // Funciones de colleccion de información financiera
    this.financialDataLogic = new financialDataLogic(this.db);

    this.firestore = app.firestore;
  }

  getDownloadURL = (filePath) => {
    const storageRef = this.storage.ref();
    const fileRef = storageRef.child(filePath);

    return fileRef.getDownloadURL();
  };

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.userLogic.user(authUser.uid).onSnapshot(async (snapshot) => {
          const dbUser = await snapshot.data();

          // default empty roles
          if (dbUser && !dbUser.roles) {
            dbUser.roles = {};
          }

          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          };

          next(authUser);
        });
      } else {
        fallback();
      }
    });
}

export default Firebase;
