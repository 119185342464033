// Importar librerías
import React from 'react';
import { Col, Row } from 'antd';

// Importar otros componentes
import MortageCalculator from './MortageCalculator';
import ConsumeCalculator from './ConsumeCalculator';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const Calculators = () => {
  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <div id="calculators-container">
      <p className="section-title">¡Simula tu crédito ya!</p>
      <Row gutter={(0, 24)}>
        <Col span={windowWidth < 768 ? 24 : 12}>
          <ConsumeCalculator />
        </Col>
        <Col span={windowWidth < 768 ? 24 : 12}>
          <MortageCalculator />
        </Col>
      </Row>
    </div>
  );
};

export default Calculators;
