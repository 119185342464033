import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Col, Descriptions, Row, message } from 'antd';
import _ from 'lodash';
import moment from 'moment';

// Importar context de firebase
import { FirebaseContext } from '../../context';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Cargar otros componentes
import Loading from '../Loading';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

const ProfileShowAutomotiveCreditAppData = () => {
  // Definir nueva instancia de route
  const route = useRouteMatch();

  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  // Obtener id de la url actual
  const applicationUid = route.params.id;

  // Obtener información de la BD
  const [value] = useDocument(firebase.applicationLogic.application(applicationUid));

  // Definir state local
  const [data, setData] = useState({});

  // Definir effect para setear la información de la BD
  useEffect(() => {
    if (value) {
      // Setear información de la aplicación
      setData(value.data());

      // Obtener información del último elemento del chat, del id del usuario
      // y del estado de la solicitud
      const chatLastItem =
        value.get('chat').length >= 1
          ? value.get('chat')[value.get('chat').length - 1]
          : null;
      const status = value.get('status');
      const userId = value.get('user').id;

      // Mostrar mensaje si se cumple las condiciones
      if (
        status !== 'accepted' &&
        status !== 'userRejected' &&
        chatLastItem &&
        chatLastItem.senderId !== userId
      ) {
        message.info('En el chat, tienes nuevos mensajes de tu ejecutivo asociado');
      }
    }
  }, [value]);

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente Loading si no hay información
  if (_.isEmpty(data)) return <Loading />;

  // Renderizar componente
  return (
    <>
      <Descriptions
        layout="vertical"
        className="application-show-descriptions application-data application-credit-data"
        column={{ xs: 2, md: 4 }}
        colon={false}
      >
        <Descriptions.Item label="Tipo de Vehículo">
          {data.vehicleType}
        </Descriptions.Item>
        <Descriptions.Item label="Marca">{data.vehicleBrand}</Descriptions.Item>
        <Descriptions.Item label="Estado">
          {data.vehicleCondition === 'nuevo'
            ? _.capitalize(data.vehicleCondition)
            : _.capitalize(data.vehicleCondition) + ', ' + data.vehicleYear}
        </Descriptions.Item>
        <Descriptions.Item label="Valor del Vehículo">
          ${toPesos(data.vehicleValue)}
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={(12, 12)}>
        <Col
          span={windowWidth < 768 ? 24 : 22}
          offset={windowWidth < 768 ? null : 1}
        >
          <div className="gradient-right-pink" />
          <Descriptions
            className="application-show-descriptions application-consume-description"
            column={1}
            colon={false}
          >
            <Descriptions.Item label="Pie">
              ${toPesos(data.vehiclePie)}
            </Descriptions.Item>
            <Descriptions.Item label="Monto del Crédito">
              {`$${toPesos(data.credito)}`}
            </Descriptions.Item>
            <Descriptions.Item label="Número de Cuotas">
              {data.plazo}
            </Descriptions.Item>
            {data.fechaPrimerPago ? (
              <Descriptions.Item label="Fecha Primer Pago">
                {moment(data.fechaPrimerPago.toDate()).format('L')}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="Crédito Bullet">
                Un Solo Pago Final
              </Descriptions.Item>
            )}
          </Descriptions>
          <div className="gradient-left-pink" />
        </Col>
      </Row>
    </>
  );
};

export default ProfileShowAutomotiveCreditAppData;
