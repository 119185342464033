// *** User API ***

class userLogic {
  constructor(db, Timestamp) {
    this.db = db;
    this.Timestamp = Timestamp;
  }

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  newUser = (uid, data) => {
    const { firstName, lastName, email, roles } = data;
    return this.user(uid).set(
      {
        firstName,
        lastName,
        email,
        roles,
        createdAt: this.Timestamp.now(),
      },
      { merge: true }
    );
  };
}

export default userLogic;
