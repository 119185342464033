const banks = [
  'Banco BICE',
  'BCI',
  'Banco Consorcio',
  'Banco de Chile',
  'Banco Estado',
  'Banco Falabella',
  'Banco Internacional',
  'Banco Security',
  'Cooperativa de Ahorro y Crédito',
  'Itaú',
  'Santander',
  'Scotiabank',
  'Otro',
];

export default banks;
