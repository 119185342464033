// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Result,
  Row,
  Select,
  Tooltip,
  Upload,
  message,
} from 'antd';
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Importar otros componentes
import toPesos from '../../utils/toPesos';

// Importar context del usuario
import { AuthUserContext } from '../../context';

// Importar constantes
import regions from '../../constants/regions';
const { validate, format } = require('rut.js');

// Definir subcomponente del componente Select
const { Option } = Select;

// Definir información del ícono de un sólo pago
const iconMessage =
  'Documento necesario para realizar la portabilidad. Contiene todos los productos que tiene un banco o institución financiera con su cliente. Debe ser entregado de forma gratuita por la entidad al ser solicitado por el usuario para esta operación de portabilidad.';

// Definir constante con las opciones del Select de Estado Laboral
export const estadoLaboralOptions = [
  { value: 'empleado', label: 'Empleado' },
  { value: 'independiente', label: 'Independiente' },
  { value: 'sin-empleo', label: 'Sin empleo' },
  { value: 'otro', label: 'Otro' },
];

// Defini constante con las opciones asociadas al radioButton
export const bestAnswerParamOptions = [
  { value: 'tasa', label: 'Tasa Anual' },
  { value: 'dividendoTotalUF1', label: 'Dividendo Mensual o CAE' },
];

// Obtener información de la sesión actual
const { sessionStorage } = window;

const UserStatusForm = (props) => {
  // Definir información del context
  const authUser = useContext(AuthUserContext);

  // Aplicar destructuración
  const {
    formInstance,
    style,
    sizeWindow,
    handlePreviousButtonClick,
    createStatus,
    portability,
  } = props;

  // Definir state
  const [totalIngresos, setTotalIngresos] = useState(0);
  const [fileNames, setFileNames] = useState({
    cedula: null,
    liquidaciones: null,
  });
  const [regionComunas, setRegionComunas] = useState([]);
  const [withCodeudor, setWithCodeudor] = useState(false);

  /**
   *
   * @param {*} changedValue
   * @param {*} allValues
   * Escucha y modifica los valores asociados al valor numérico del formulario.
   */
  const onChangeValue = (changedValue, allValues) => {
    const field = Object.keys(changedValue)[0];
    if (field === 'ingresoFijo' || field === 'ingresoVariable') {
      const { ingresoFijo, ingresoVariable } = allValues;
      const newIngresos = ingresoFijo + ingresoVariable;
      if (!isNaN(newIngresos)) {
        formInstance.setFieldsValue({
          totalIngresos: ingresoFijo + ingresoVariable,
        });
        setTotalIngresos(ingresoFijo + ingresoVariable);
      }
    }
    if (Object.keys(fileNames).includes(field)) {
      const names = allValues[field].map((f) => f.name).join(', ');
      setFileNames({ ...fileNames, [`${field}`]: names });
    }
    if (field === 'rut') {
      const { rut } = allValues;
      if (!rut || rut === '-') formInstance.setFieldsValue({ rut: '' });
      else formInstance.setFieldsValue({ rut: format(rut) });
    }
    if (field === 'rutCod') {
      const { rutCod } = allValues;
      if (!rutCod || rutCod === '-') formInstance.setFieldsValue({ rutCod: '' });
      else formInstance.setFieldsValue({ rutCod: format(rutCod) });
    }
  };

  /**
   *
   * @param {*} value
   * Reinicializa el valor de la comuna para cualquier valor de la región seleccionada
   * inclusive si está es reelgida múltiples veces.
   */
  const reSelectedItem = (value) => {
    setRegionComunas(regions[value]);
    formInstance.setFieldsValue({ comuna: null });
  };

  // TODO: Validar qué hacen estas funciones.
  const cedulaFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const liquidacionesFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // Definir useEffect para setear los valores provenientes de la última sesión del usuario
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglo de campos del formulario
    const fields = [
      'rut',
      'estadoLaboral',
      'ingresoFijo',
      'ingresoVariable',
      'region',
      'comuna',
      'aval',
      'totalIngresos',
    ];

    // Setear valores en caso de que exista el campo lastApplicationInfo
    if (authUser && authUser.lastApplicationInfo) {
      // Obtener objeto
      const { lastApplicationInfo } = authUser;

      // Setear valores según sea el caso
      fields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (item && !_.isNaN(item)) {
          if (Number(item)) {
            values[f] = Number(item);
          } else {
            values[f] = item;
          }
        }
      });

      // Colocar el total de ingresos
      values['totalIngresos'] =
        (values['ingresoFijo'] || 0) + (values['ingresoVariable'] || 0);
    }

    // Setear valores en caso de que haya información en la variable sessionStorage
    fields.forEach((f) => {
      if (sessionStorage.getItem(f) && !_.isNaN(sessionStorage.getItem(f))) {
        if (Number(sessionStorage.getItem(f))) {
          values[f] = Number(sessionStorage.getItem(f));
        } else {
          values[f] = sessionStorage.getItem(f);
        }
      }
    });

    // Setear totalIngresos
    setTotalIngresos(values['totalIngresos'] || 0);

    // Setear valores en formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  // Renderizar información del modal
  const ModalContent = () => {
    let resultProps;
    if (createStatus === 'loading') {
      resultProps = {
        icon: <LoadingOutlined />,
        title: 'Creando cotización',
        subTitle: 'Por favor, espere unos segundos...',
      };
    } else if (createStatus === 'success') {
      let hoursLeft = 24;
      if (moment().day() === 6 || (moment().day() === 0 && moment().hour() < 9))
        hoursLeft = 72;
      else if (
        (moment().day() === 4 && moment().hour() >= 12) ||
        moment().day() === 5
      )
        hoursLeft = 72;

      resultProps = {
        status: 'success',
        title: '¡Cotización creada!',
        subTitle: `Su cotización será respondida en ${hoursLeft} horas`,
        extra: [
          <Link to={ROUTES.PROFILE_APPLICATIONS} key="1">
            <Button>Ir a mis cotizaciones</Button>
          </Link>,
        ],
      };
    } else if (createStatus === 'error') {
      resultProps = {
        status: 'error',
        title: 'Ocurrio un error',
        subTitle:
          'Por favor, intenta nuevamente. Si el error consiste, contáctate con nuestro equipo.',
      };
    }

    return <Result {...resultProps} />;
  };

  /**
   *
   * @param {*} errorFields
   * Muestra un mensaje de error en caso de que haya habido un problema con la información
   * del formulario.
   */
  const onFinishFailed = (errorFields) => {
    console.log(errorFields);
    message.info('Existen campos del formulario que aún no han sido completados');
  };

  // Renderizar
  return (
    <Form
      name="user-status-form"
      id="user-status-form"
      form={formInstance}
      onValuesChange={onChangeValue}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <Modal
        visible={createStatus}
        footer={false}
        closable={false}
        className="application-result-modal"
        width={sizeWindow < 768 ? 350 : 520}
      >
        <ModalContent />
      </Modal>
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="rut"
            label="RUT"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Ingresa tu RUT',
              },
              () => ({
                validator(_, value) {
                  if (validate(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Ingresa un RUT válido');
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="estadoLaboral"
            label="Estado Laboral"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona tu estado laboral',
              },
            ]}
          >
            <Select placeholder="Selecciona">
              {estadoLaboralOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="ingresoFijo"
            label="Ingresos Fijos (CLP)"
            type="number"
            initialValue={0}
            hasFeedback
            rules={[
              {
                message: 'Selecciona el valor de tu ingreso fijo',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="ingresoVariable"
            label="Ingresos Variables (CLP)"
            type="number"
            initialValue={0}
            hasFeedback
            rules={[
              {
                message: 'Selecciona el valor de tu ingreso fijo',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="region"
            label="Región"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona tu región',
              },
            ]}
          >
            <Select
              placeholder="Selecciona"
              showSearch
              onSelect={(_, selected) => reSelectedItem(selected.value)}
            >
              {Object.keys(regions).map((region) => (
                <Option key={region} value={region}>
                  {region}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="comuna"
            label="Comuna"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Selecciona tu comuna',
              },
            ]}
          >
            <Select placeholder="Selecciona" showSearch>
              {regionComunas.map((comuna) => (
                <Option key={comuna} value={comuna}>
                  {comuna}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name={'bestAnswerParam'}
        key={'bestAnswerParam'}
        label={'Parámetro a considerar para elegir la mejor respuesta de un banco'}
        rules={[
          {
            required: true,
            message: 'Selecciona el parámetro',
          },
        ]}
        style={{ alignItems: 'center' }}
      >
        <Radio.Group>
          {bestAnswerParamOptions.map((t, index) => (
            <Radio
              key={index}
              value={t.value}
              style={sizeWindow < 768 ? { marginRight: '-1px' } : { marginRight: 0 }}
            >
              {t.label === 'Tasa Anual' ? (
                t.label
              ) : (
                <>
                  {t.label}
                  <Tooltip
                    title={'El CAE es el costo anual equivalente de un crédito'}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              )}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name={'aval'}
        key={'aval'}
        label={'¿Posee aval o codeudor?'}
        rules={[
          {
            required: true,
            message: 'Selecciona si posees aval o codeudor',
          },
        ]}
        style={{ alignItems: 'center' }}
      >
        <Radio.Group onChange={(e) => setWithCodeudor(e.target.value)}>
          <Radio value={true}>Sí</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      {withCodeudor && (
        <Row gutter={(0, 12)}>
          <Col span={sizeWindow < 768 ? 24 : 12}>
            <Form.Item
              name="rutCod"
              label="RUT Codeudor"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Ingresa el RUT del Codeudor',
                },
                () => ({
                  validator(_, value) {
                    if (validate(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Ingresa un RUT válido');
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={sizeWindow < 768 ? 24 : 12}>
            <Form.Item
              name="takeInsurance"
              label={'¿Quiere tomar el seguro Desgravament para su codeudor? *'}
              help="* No es obligatorio tomar el seguro"
              style={{ alignItems: 'center' }}
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Divider />

      {!portability && (
        <>
          <Row gutter={(0, 12)} className="application-form-upload-row">
            <Col span={sizeWindow < 768 ? 24 : 12}>
              <Form.Item label="Cédula de identidad *">
                <Form.Item
                  name="cedula"
                  valuePropName="fileList"
                  getValueFromEvent={cedulaFile}
                >
                  <Upload
                    name="files"
                    multiple={true}
                    beforeUpload={(file, fileList) => false}
                  >
                    <Button>
                      <PaperClipOutlined />
                      Adjuntar archivo
                    </Button>
                  </Upload>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={sizeWindow < 768 ? 24 : 12}>
              <Form.Item label="Últimas 3 liquidaciones o declaración de impuestos *">
                <Form.Item
                  name="liquidaciones"
                  valuePropName="fileList"
                  getValueFromEvent={liquidacionesFile}
                >
                  <Upload
                    name="files"
                    multiple={true}
                    beforeUpload={(file, fileList) => false}
                  >
                    <Button>
                      <PaperClipOutlined />
                      Adjuntar archivo
                    </Button>
                  </Upload>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <p className="small-letter">
            * Los documentos adjuntos no son obligatorios.
          </p>
        </>
      )}

      <Row>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <div
            className={
              portability && sizeWindow < 768
                ? 'application-form-figure flex-center'
                : 'application-form-figure'
            }
          >
            <span>Total Ingresos</span>
            <span>{`CLP ${toPesos(totalIngresos)}`}</span>
          </div>
        </Col>
        <Col
          span={sizeWindow < 768 ? 24 : 12}
          style={portability && sizeWindow < 768 ? { marginTop: '10px' } : null}
        >
          {portability && (
            <Form.Item
              name="license"
              valuePropName="checked"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'Debes autorizar la solicitud del certificado de liquidación'
                    );
                  },
                }),
              ]}
              className={'flex-start'}
            >
              <Checkbox>
                <b>
                  Autorizo la Solicitud del Certificado de Liquidación a la
                  Institución Financiera Actual
                </b>
                <Tooltip
                  placement="top"
                  title={iconMessage}
                  style={{ width: sizeWindow < 768 ? '50px' : '70px' }}
                  color="#e81687"
                >
                  <QuestionCircleOutlined className="info-icon-style" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            name="terms"
            valuePropName="checked"
            rules={[
              () => ({
                validator(rule, value) {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Debes aceptar los términos y condiciones');
                },
              }),
            ]}
            className={
              portability
                ? 'flex-start'
                : sizeWindow < 768
                ? 'flex-center'
                : 'flex-end'
            }
          >
            <Checkbox>
              He leído y acepto los{' '}
              <a target="_blank" rel="noopener noreferrer" href={ROUTES.TERMS}>
                Términos y Condiciones
              </a>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="totalIngresos" style={{ display: 'none' }} />

      <Row style={{ justifyContent: 'space-between' }}>
        <Col>
          <Button
            className="application-form-button application-form-button-secondary"
            onClick={handlePreviousButtonClick}
          >
            <ArrowLeftOutlined />
            Anterior
          </Button>
        </Col>
        <Col>
          <Button className="application-form-button magenta" htmlType="submit">
            Enviar Cotización
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserStatusForm;
