// Importar librerías
import React, { useEffect } from 'react';
import { animateScroll, Element, scroller } from 'react-scroll';

// Importar otros componentes
import Landing from './Landing';
import LandingExtraInfo from './LandingExtraInfo';
import WhatWeDo from './WhatWeDo';
import Calculators from './Calculators';
import InterestData from './InterestData';
import FeaturedArticlesList from './FeaturedArticlesList';
import FinancialEducation from './FinancialEducation';
import ContactUs from './ContactUs';
import Sponsors from './Sponsors';

const Home = ({ location }) => {
  // Definir effect para manejar scrollBar
  useEffect(() => {
    const { scrollTo } = location;
    if (scrollTo) {
      if (scrollTo === 'landing') animateScroll.scrollToTop();
      else {
        scroller.scrollTo(scrollTo, {
          smooth: true,
          offset: -window.innerHeight * 0.1,
        });
      }
    }
  });

  // Renderizar
  return (
    <div id="home-wrapper">
      <Landing />
      <Element name="what-we-do">
        <WhatWeDo />
      </Element>
      <div id="container-background">
        <LandingExtraInfo />
        <Element name="calculator">
          <Calculators />
        </Element>
      </div>
      <div id="container-background-2">
        <Element name="interest-data">
          <div id="interest-data-container">
            <InterestData />
            <FeaturedArticlesList />
          </div>
        </Element>
        <Element name="finantial-education">
          <FinancialEducation />
        </Element>
      </div>
      <div id="container-background-3">
        <Element name="contact-us">
          <ContactUs />
        </Element>
        <Sponsors />
      </div>
    </div>
  );
};

export default Home;
