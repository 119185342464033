import React from 'react';
import { Button } from 'antd';

import { AuthUserContext } from './../context';
import withFirebase from './withFirebase';

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData.map((provider) => provider.providerId).includes('password');

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase.authLogic
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <div style={{ padding: '16px' }}>
                {this.state.isSent ? (
                  <p style={{ fontFamily: 'Roboto' }}>
                    Correo de confirmación enviado: Busca en tu buzón de correo
                    (incluida la carpeta de spam). Una vez confirmado, actualiza esta
                    página.
                  </p>
                ) : (
                  <p>
                    Vertifica tu correo: Busca en tu buzón (incluida la carpeta de
                    spam) el correo de confirmación.
                  </p>
                )}

                <Button
                  type="Button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Enviar correo de confirmación
                </Button>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
