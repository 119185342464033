// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Badge, Descriptions, Form, InputNumber, Layout, message } from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';

// Importar context de user y firebase
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar otros componentes
import toPesos from '../../utils/toPesos';
import { ReactComponent as EditIcon } from './icons/edit.svg';

// Importar subcomponente Content
const { Content } = Layout;

// Renderizar componente para manejar input
const InputPesos = () => (
  <InputNumber
    min={0}
    max={999999999999}
    style={{ width: '100%' }}
    precision={0}
    formatter={toPesos}
    parser={(value) => (value ? value.replace(/^(0)|([^0-9])/g, '') : 0)}
    onClick={(e) => e.target.select()}
  />
);

// Definir campos para los ingresos
const ingresosFields = [
  {
    name: 'ingresoFijo',
    label: 'Fijos',
    render: InputPesos(),
  },
  {
    name: 'ingresoVariable',
    label: 'Variables',
    render: InputPesos(),
  },
];

// Definir campos para los activos
const activosFields = [
  {
    name: 'bienesRaices',
    label: 'Bienes Raíces',
    render: InputPesos(),
  },
  {
    name: 'vehiculos',
    label: 'Vehículos',
    render: InputPesos(),
  },
  {
    name: 'inversiones',
    label: 'Inversiones',
    render: InputPesos(),
  },
  {
    name: 'otrosActivos',
    label: 'Otros',
    render: InputPesos(),
  },
];

// Definir campos para las deudas
const deudasFields = [
  {
    name: 'hipotecario',
    label: 'Dividendo o Arriendo',
    render: InputPesos(),
  },
  {
    name: 'tarjetasLineasCredito',
    label: 'Tarjetas y Líneas de Crédito',
    render: InputPesos(),
  },
  {
    name: 'creditosConsumo',
    label: 'Créditos de Consumo',
    render: InputPesos(),
  },
  {
    name: 'otrosGastos',
    label: 'Otros',
    render: InputPesos(),
  },
];

// Definir estado inicial global
const initialData = {
  bienesRaices: '-',
  estadoLaboral: 'Sin información',
  hipotecario: '-',
  vehiculos: '-',
  ingresoVariable: '-',
  tarjetasLineasCredito: '-',
  inversiones: '-',
  creditosConsumo: '-',
  ingresoFijo: '-',
  otrosActivos: '-',
};

const ProfileFinancialInfo = () => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [data, setData] = useState(initialData);
  const [form] = Form.useForm();
  const [editing, setEditing] = useState({
    activosFields: false,
    ingresosFields: false,
    deudasFields: false,
  });
  const [loading, setLoading] = useState(false);
  const [uf, setUf] = useState();

  // Definir effect para obtener información de una API externa
  useEffect(() => {
    axios
      .get('https://mindicador.cl/api')
      .then((response) => {
        setUf(response.data.uf.valor);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Definir effect para obtener la última información maneja por el usuario
  useEffect(() => {
    if (authUser.lastApplicationInfo && uf) {
      if (
        authUser.lastApplicationInfo.valorMoneda &&
        authUser.lastApplicationInfo.valorMoneda === 1
      ) {
        setData({
          ...authUser.lastApplicationInfo,
          bienesRaices: Math.round(authUser.lastApplicationInfo.bienesRaices * uf),
        });
      } else {
        setData(authUser.lastApplicationInfo);
      }
    }
  }, [authUser, uf]);

  /**
   *
   * @param {*} title
   * @param {*} key
   * Renderiza un tipo de ícono según sea el valor del key como parámetro de entrada.
   */
  const financialInfoTitle = (title, key) => {
    let icon;
    if (loading) {
      icon = (
        <Badge
          count={
            <>
              Guardando... <LoadingOutlined />
            </>
          }
        />
      );
    } else if (!editing[key]) {
      icon = (
        <Badge
          className="clickable"
          onClick={() => {
            setEditing({ ...editing, [key]: true });
          }}
          count={
            <>
              Editar <EditIcon />
            </>
          }
        />
      );
    } else if (editing[key]) {
      icon = (
        <Badge
          className="clickable"
          onClick={form.submit}
          count={
            <>
              Guardar <CheckOutlined />
            </>
          }
        />
      );
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{title}</span>
        <div id="profile-info-title-icons">{icon}</div>
      </div>
    );
  };

  // Actualiza la información en la BD
  const onFinish = (values) => {
    Object.entries(values).forEach(([k, v]) => {
      if (v === undefined) delete values[k];
    });
    setLoading(true);
    const hide = message.loading('Actualizando información...', 0);
    firebase.db
      .doc(`users/${authUser.uid}`)
      .set({ lastApplicationInfo: values }, { merge: true })
      .then(() => {
        hide();
        message.success('¡Información actualizada exitosamente!');
        setEditing(false);
      })
      .catch(() => {
        hide();
        message.error('Hubo un problema al actualizar tu información');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   *
   * @param {*} param0
   * Edita un campo del formulario financiero según sea el valor del field.
   */
  const DescriptionsField = ({ field, isEditing }) => {
    if (isEditing) {
      return (
        <Form.Item name={field.name} key={field.name} {...field.formProps}>
          {field.render}
        </Form.Item>
      );
    }
    if (field.name === 'estadoLaboral') return _.capitalize(data[field.name]);
    return toPesos(data[field.name]);
  };

  /**
   * Obtiene un valor númerico que representa la cantidad de activos adquiridos.
   */
  const totalActivos = activosFields.reduce((prev, curr) => {
    const sum = data[curr.name] || 0;
    return prev + sum;
  }, 0);

  /**
   * Obtiene un valor númerico que representa la cantidad total de ingresos.
   */
  const totalIngresos = ingresosFields.reduce((prev, curr) => {
    const sum = data[curr.name] || 0;
    return prev + sum;
  }, 0);

  /**
   * Obtiene un valor númerico que representa la cantidad total de deudas.
   */
  const totalDeudas = deudasFields.reduce((prev, curr) => {
    const sum = data[curr.name] || 0;
    return prev + sum;
  }, 0);

  // Renderizar componente
  return (
    <Content id="profile-financial-info-content">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={authUser.lastApplicationInfo}
      >
        <Descriptions
          title={financialInfoTitle('Ingresos (CLP)', 'ingresosFields')}
          layout="vertical"
          className="financial-info-descriptions with-shadow"
          column={{ xs: 2, sm: 2, md: ingresosFields.length + 1 }}
          colon={false}
        >
          {ingresosFields.map((f) => (
            <Descriptions label={`${f.label}`} key={f.label}>
              <DescriptionsField field={f} isEditing={editing.ingresosFields} />
            </Descriptions>
          ))}
          <Descriptions
            label={'INGRESOS TOTALES'}
            key={'total-ingresos'}
            className="total"
          >
            {toPesos(totalIngresos)}
          </Descriptions>
        </Descriptions>

        <Descriptions
          title={financialInfoTitle('Activos (CLP)', 'activosFields')}
          layout="vertical"
          className="financial-info-descriptions with-shadow"
          column={{ xs: 4, sm: 4, md: activosFields.length + 1 }}
          colon={false}
        >
          {activosFields.map((f) => (
            <Descriptions label={`${f.label}`} key={f.label}>
              <DescriptionsField field={f} isEditing={editing.activosFields} />
            </Descriptions>
          ))}
          <Descriptions
            label={'TOTAL ACTIVOS'}
            key={'total-activos'}
            className="total"
          >
            {toPesos(totalActivos)}
          </Descriptions>
        </Descriptions>

        <Descriptions
          title={financialInfoTitle('Gastos Mensuales (CLP)', 'deudasFields')}
          layout="vertical"
          className="financial-info-descriptions with-shadow"
          column={{ xs: 4, sm: 4, md: deudasFields.length + 1 }}
          colon={false}
        >
          {deudasFields.map((f) => (
            <Descriptions
              label={`${f.label}`}
              key={f.label}
              className={f.name === 'tarjetasLineasCredito' ? 'limit-text' : ''}
            >
              <DescriptionsField field={f} isEditing={editing.deudasFields} />
            </Descriptions>
          ))}
          <Descriptions
            label={'TOTAL DEUDAS'}
            key={'total-deudas'}
            className="total"
          >
            {toPesos(totalDeudas)}
          </Descriptions>
        </Descriptions>
      </Form>
    </Content>
  );
};

export default ProfileFinancialInfo;
