import React, { useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';

import { withFirebase } from './../../utils';
import * as ROUTES from './../../constants/routes';
import { FirebaseContext } from '../../context';

const SignUpPage = () => (
  <div>
    <h1 className="center">SignUp</h1>
    <SignUpForm />
  </div>
);

const SignUpForm2 = () => {
  const firebase = React.useContext(FirebaseContext);
  const [err, setError] = useState();
  const history = useHistory();

  const onFinish = (values) => {
    const { firstName, lastName, email, passwordOne } = values;
    const roles = { NORMAL: 'NORMAL' };

    firebase.authLogic
      .doCreateUserWithEmailAndPassword(
        email,
        firstName,
        lastName,
        roles,
        passwordOne
      )
      .then(() => {
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        console.log(error);
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setError({ error });
        message.error(error.message);
      });
  };

  return (
    <Form
      name="register"
      layout="vertical"
      onFinish={onFinish}
      hideRequiredMark={true}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
        error: null,
      }}
      scrollToFirstError
    >
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su nombre',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Nombre" />
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su apellido',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Apellido" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: 'El email no es válido',
          },
          {
            required: true,
            message: 'Por favor escriba su email',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="passwordOne"
        rules={[
          {
            required: true,
            message: 'Por favor escriba su password',
          },
        ]}
        hasFeedback
      >
        <Input type="password" placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="passwordTwo"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Por favor confirme su password',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('passwordOne') === value) {
                return Promise.resolve();
              }

              return Promise.reject('Las contraseñas no son iguales');
            },
          }),
        ]}
      >
        <Input type="password" placeholder="Repetir Password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="sign-up-button" block>
          Registrarse
        </Button>
      </Form.Item>
      {err && <p>{err.message}</p>}
    </Form>
  );
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const SignUpLink = () => (
  <p>
    No tiene una cuenta? <Link to={ROUTES.SIGN_UP}>Registrese </Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpForm2));

export default SignUpPage;

export { SignUpForm, SignUpLink };
