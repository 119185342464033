// Importar librerías
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Input, Button, Tabs, Row, Col } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

// Importar otros componentes
import { SignUpForm } from './signUp';

// Importar utilidades
import { withFirebase } from './../../utils';

// Importar rutas
import * as ROUTES from './../../constants/routes';

// Importar otros componentes
import { ReactComponent as BigLogo } from './icons/big-logo.svg';
import signInImage from './images/signInImage.png';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponentes
const { TabPane } = Tabs;

const SignInPage = () => {
  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  // Renderizar componente
  return (
    <Row id="sign-in-container">
      {windowWidth < 992 ? (
        <Col span={24}>
          <div className="sign-in-div">
            <div className="mini-phone-background">
              <a className={'flit-logo'} href="/">
                <BigLogo />
              </a>
              <div className={'sign-in-form'}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Iniciar Sesión" key="1">
                    <SignInForm />
                  </TabPane>
                  <TabPane tab="Crear cuenta" key="2">
                    <SignUpForm />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Col>
      ) : (
        <>
          <Col span={12}>
            <div id="side-image-wrapper">
              <img alt="signIn" src={signInImage} />
            </div>
          </Col>
          <Col span={12}>
            <div className="sign-in-div">
              <a className={'flit-logo'} href="/">
                <BigLogo />
              </a>
              <div className={'sign-in-form'}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Iniciar Sesión" key="1">
                    <SignInForm />
                  </TabPane>
                  <TabPane tab="Crear cuenta" key="2">
                    <SignUpForm />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

// Definir state inicial
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

// Definir mensajes para posibles errores
const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = async (values) => {
    try {
      const { email, password } = values;

      await this.props.firebase.authLogic.doSignInWithEmailAndPassword(
        email,
        password
      );

      this.setState({ ...INITIAL_STATE });

      setTimeout(() => {
        this.props.history.push(ROUTES.PROFILE_HOME);
      }, 2000);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={this.onSubmit}
        hideRequiredMark={true}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu email',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa tu password',
            },
          ]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <Link className="link" to={ROUTES.PASSWORD_FORGET}>
          ¿Olvidaste tu Contraseña?
        </Link>
        <div className={'form-actions'}>
          <Button
            type="primary"
            htmlType="submit"
            className="button login-form-botton"
            style={{ width: '100%', marginBottom: '8px' }}
            block
          >
            Iniciar Sesión
          </Button>
          <SignInGoogle />
        </div>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = async (event) => {
    event.preventDefault();

    const socialAuthUser = await this.props.firebase.authLogic.doSignInWithGoogle();

    try {
      await this.props.firebase.userLogic.user(socialAuthUser.user.uid).set(
        {
          firstName: socialAuthUser.additionalUserInfo.profile.given_name,
          lastName: socialAuthUser.additionalUserInfo.profile.family_name,
          email: socialAuthUser.user.email,
          roles: { NORMAL: 'NORMAL' },
          createdAt: this.props.firebase.firestore.Timestamp.now(),
        },
        { merge: true }
      );

      this.setState({ error: null });
      this.props.history.push(ROUTES.PROFILE_HOME);
    } catch (error) {
      // Create a user in your Firebase Realtime Database too
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
    }
  };

  render() {
    const { error } = this.state;

    return (
      <div>
        <Button
          icon={<GoogleOutlined />}
          type="primary"
          onClick={this.onSubmit}
          className="google-button"
          block
        >
          Iniciar Sesión con Google
        </Button>
        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

export default SignInPage;

export { SignInForm, SignInGoogle };
