// Importar librerías
import React, { useState, useContext } from 'react';
import { animateScroll } from 'react-scroll';
import { Divider, Form, Steps } from 'antd';
import shortid from 'shortid';

// Importar context de firebase
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar otros componentes
import UserInfoForm from './UserInfoForm';
import CreditInfoForm from './CreditInfoForm';

// Importar hooks
import useWindowSize from '../../hooks/useWindowSize';

// Importar subcomponente el componente Steps
const { Step } = Steps;

// Obtener información de la sesión
const { sessionStorage } = window;

const ApplicationForm = () => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state local
  const [currentStep, setCurrentStep] = useState(0);
  const [createStatus, setCreateStatus] = useState();

  // Crear instancias de useForm
  const [userInfoFormInstance] = Form.useForm();
  const [creditInfoFormInstance] = Form.useForm();

  // Obtener tamaño de la ventana
  const windowWidth = useWindowSize();

  /**
   * Almacena la información de los formularios en la BD.
   */
  const handleFormFinish = async () => {
    if (currentStep < 1) {
      animateScroll.scrollToTop();
      setCurrentStep(currentStep + 1);
    } else {
      const userInfoFormInfo = userInfoFormInstance.getFieldsValue();
      const creditInfoFormInfo = creditInfoFormInstance.getFieldsValue();
      const data = {
        ...userInfoFormInfo,
        ...creditInfoFormInfo,
        user: {
          id: authUser.uid,
          email: authUser.email,
          firstName: authUser.firstName,
          lastName: authUser.lastName,
        },
        answers: [],
        executives: [],
        requirements: [],
        chat: [],
        type: 'consumo',
        fechaPrimerPago: creditInfoFormInfo.fechaPrimerPago
          ? creditInfoFormInfo.fechaPrimerPago.toDate()
          : '',
        status: 'open',
        idCostQuote: shortid.generate(),
      };
      try {
        setCreateStatus('loading');
        await firebase.applicationLogic.applications().add(data);
        setCreateStatus('success');
        sessionStorage.clear();
      } catch (e) {
        console.log(e);
        setCreateStatus('error');
      }
    }
  };
  /**
   * Permite regresar al formulario anterior.
   */
  const handlePreviousButtonClick = () => {
    setCurrentStep(currentStep - 1);
  };

  /**
   *
   * @param {*} formName
   * @param {*} info
   * Escucha por los cambios hechos en los formularios y los almacena en la sessionStorage.
   */
  const handleFormChange = (formName, info) => {
    Object.values(info.forms).forEach((f) => {
      Object.entries(f.getFieldsValue()).forEach(([k, v]) => {
        if (v || v === 0) sessionStorage.setItem(k, v);
      });
    });
  };

  // Renderizar componente
  return (
    <Form.Provider onFormFinish={handleFormFinish} onFormChange={handleFormChange}>
      <Steps
        current={currentStep}
        id="application-form-steps"
        direction={windowWidth < 768 ? 'vertical' : 'horizontal'}
        size={windowWidth < 768 ? 'small' : 'default'}
      >
        <Step title="Información Crédito" />
        <Step title="Información Personal" />
      </Steps>
      <Divider style={{ marginTop: windowWidth < 768 ? '0' : '24px', opacity: 1 }} />
      <CreditInfoForm
        formInstance={creditInfoFormInstance}
        style={{ display: currentStep === 0 ? 'block' : 'none' }}
        sizeWindow={windowWidth}
      />
      <UserInfoForm
        formInstance={userInfoFormInstance}
        style={{ display: currentStep === 1 ? 'block' : 'none' }}
        handlePreviousButtonClick={handlePreviousButtonClick}
        createStatus={createStatus}
        sizeWindow={windowWidth}
        contentFor={'consume'}
      />
    </Form.Provider>
  );
};

export default ApplicationForm;
