// Importar librerías
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { List } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

// Importar utilidades
import { withFirebase } from '../../utils';

// Importar rutas
import * as ROUTES from '../../constants/routes';

// Definir idioma de localidad
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const renderArticle = (item) => {
  // Aplicar destructuración
  const { userRef, createdAt, ...rest } = item;

  // Definir tiempo
  const time = moment(createdAt.toDate());

  // Renderizar componente
  return (
    <div className="news-card">
      <img alt="news" src={item.photoUrl} />
      <div className="news-card-info">
        <p className="date">{time.format('D [de] MMMM').toUpperCase()}</p>
        <p className="title">{item.title}</p>
        <p className="subtitle">{item.summary}</p>

        {rest.type === 'link' ? (
          <a href={rest.link} target="_blank" rel="noopener noreferrer">
            <ArrowRightOutlined />
          </a>
        ) : (
          <NavLink
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: `${ROUTES.ARTICLES_PAGE}/${rest.uid}`,
              state: { article: rest },
            }}
          >
            <ArrowRightOutlined />
          </NavLink>
        )}
      </div>
    </div>
  );
};

class FeaturedArticleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      articles: [],
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase.articleLogic
      .articles()
      .orderBy('createdAt', 'desc')
      .limit(4)
      .onSnapshot((snapshot) => {
        let articles = [];
        let counter = 0;
        snapshot.forEach((doc) => {
          let article = { ...doc.data(), uid: doc.id, key: counter++ };
          article.userRef.onSnapshot((snap) => {
            const userData = snap.data();
            article.userData = userData;
          });

          articles.push(article);
        });

        this.setState({
          articles,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { articles, loading } = this.state;

    return (
      <div span={8} id="news-container">
        {loading && <div>Loading ...</div>}
        <List
          itemLayout="vertical"
          size="large"
          dataSource={articles.slice(0, 4)}
          footer={<div></div>}
          renderItem={renderArticle}
        />
      </div>
    );
  }
}

export default withFirebase(FeaturedArticleList);
