// Importar librerías
import React, { useEffect, useState } from 'react';
import { Statistic } from 'antd';
const axios = require('axios');

// Definir arreglos con los unidades a utilizar
const exchangeLabels = ['dolar', 'euro'];
const financialLabels = ['uf', 'utm', 'ipc'];

const TradeData = () => {
  // Definir state
  const [exchangeData, setExchangeData] = useState({});
  const [financialData, setFinancialData] = useState({});
  const [loadingState, setLoadingState] = useState({ loading: true, error: '' });

  // Definir effect para hacer consulta a un API externa
  useEffect(() => {
    axios
      .get('https://mindicador.cl/api')
      .then((response) => {
        const exchangeFiltered = Object.entries(response.data).filter((entry) =>
          exchangeLabels.includes(entry[0])
        );
        const financialFiltered = Object.entries(response.data).filter((entry) =>
          financialLabels.includes(entry[0])
        );
        setExchangeData(exchangeFiltered);
        setFinancialData(financialFiltered);
        setLoadingState({ loading: false, error: '' });
      })
      .catch((error) => {
        setLoadingState({ loading: false, error: error.message });
      });
  }, []);

  // Renderizar componente
  return (
    <div id="trade-data-preview">
      {loadingState.loading && <div>Loading ...</div>}
      {!loadingState.loading && (
        <div id="trade-data-content">
          {exchangeData.map((data) => (
            <Statistic
              title={data[1].codigo.toUpperCase()}
              value={data[1].valor}
              precision={2}
              decimalSeparator=","
              groupSeparator="."
              key={data[0]}
              prefix="$"
            />
          ))}

          {financialData.map((data) => {
            if (data[0] === 'ipc') {
              return (
                <Statistic
                  title={data[1].codigo.toUpperCase()}
                  value={data[1].valor}
                  precision={2}
                  decimalSeparator=","
                  groupSeparator="."
                  key={data[0]}
                  suffix="%"
                />
              );
            } else {
              return (
                <Statistic
                  title={data[1].codigo.toUpperCase()}
                  value={data[1].valor}
                  precision={2}
                  decimalSeparator=","
                  groupSeparator="."
                  key={data[0]}
                  prefix="$"
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default TradeData;
