// Importar librerías
import React, { useState, useContext, useEffect } from 'react';
import { DatePicker, Descriptions, Form, Input, message, Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import _ from 'lodash';

// Importar context de usuario y firebase
import { AuthUserContext, FirebaseContext } from '../../context';

// Importar otros componentes
import { ReactComponent as EditIcon } from './icons/edit.svg';
import Loading from '../Loading';

// Importar otras variables
import regions from '../../constants/regions';

const { format } = require('rut.js');

// Obtener subcomoponente Option
const { Option } = Select;

// Definir constante que representa los campos a completar en el perfil del usuario
export const fields = [
  {
    name: 'firstName',
    label: 'Nombre(s)',
    required: true,
    render: () => <Input />,
  },
  {
    name: 'lastName',
    label: 'Apellido(s)',
    required: true,
    render: () => <Input />,
  },
  {
    name: 'birthday',
    label: 'Fecha de nacimiento',
    render: () => <DatePicker style={{ width: '183px' }} locale={locale} />,
  },
  {
    name: 'lastApplicationInfo.rut',
    label: 'RUT',
    render: () => <Input />,
  },
  {
    name: 'email',
    label: 'Email',
    required: true,
    render: () => <Input />,
  },
  {
    name: 'phone',
    label: 'Teléfono',
    render: () => <Input />,
  },
  {
    name: 'lastApplicationInfo.region',
    label: 'Región',
    render: () => (
      <Select placeholder="Selecciona">
        {Object.keys(regions).map((region) => (
          <Option key={region} value={region}>
            {region}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    name: 'lastApplicationInfo.comuna',
    label: 'Comuna',
    render: (region) =>
      region ? (
        <Select placeholder="Selecciona">
          {regions[region].map((comuna) => (
            <Option key={comuna} value={comuna}>
              {comuna}
            </Option>
          ))}
        </Select>
      ) : (
        <p style={{ fontStyle: 'italic', fontSize: '12px' }}>
          Selecciona una comuna primero
        </p>
      ),
  },
];

const ProfilePersonalInfo = () => {
  // Obtener información del context
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  // Definir state
  const [form] = Form.useForm();
  const [editing, setEditing] = useState({});
  const [loading, setLoading] = useState(false);

  // Definir effect para escuchar por la edición de algún campo
  useEffect(() => {
    const newEditing = {};
    fields.forEach((f) => {
      newEditing[f.name] = false;
    });
    setEditing(newEditing);
  }, []);

  /**
   *
   * @param {*} values
   * Actualiza la información del usuario según sea el caso.
   */
  const onFinish = (values) => {
    Object.entries(values).forEach(([k, v]) => {
      if (moment.isMoment(v)) values[k] = moment(v).toDate();
      else if (!v || v.length === 0 || !v.trim()) {
        values[k] = '';
      }
    });

    if (Object.keys(values).includes('lastApplicationInfo.region')) {
      values['lastApplicationInfo.comuna'] = null;
    }

    if (Object.keys(values).includes('lastApplicationInfo.rut')) {
      if (
        !_.isEmpty(values['lastApplicationInfo.rut']) &&
        !values['lastApplicationInfo.rut'].includes('-')
      ) {
        values['lastApplicationInfo.rut'] = format(
          values['lastApplicationInfo.rut']
        );
      }
    }

    setLoading(true);
    const hide = message.loading('Actualizando información...', 0);
    firebase.db
      .doc(`users/${authUser.uid}`)
      .update(values)
      .then(() => {
        hide();
        message.success('¡Información actualizada exitosamente!');
        setEditing(false);
      })
      .catch(() => {
        hide();
        message.error('Hubo un problema al actualizar tu información');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   *
   * @param {*} param0
   * Renderiza un componente con el campo a editar en el perfil del usuario.
   */
  const DescriptionsField = ({ field }) => {
    if (editing[field.name]) {
      return (
        <>
          <Form.Item
            name={field.name}
            key={field.name}
            rules={[
              {
                required: field.required,
                message: `Por favor ingresa tu ${field.label.toLowerCase()}`,
              },
            ]}
            {...field.formProps}
          >
            {field.render(
              authUser.lastApplicationInfo
                ? authUser.lastApplicationInfo.region
                : null
            )}
          </Form.Item>
          <CheckOutlined className="edit-icon" onClick={() => form.submit()} />
        </>
      );
    } else if (_.get(authUser, field.name) && _.get(authUser, field.name).seconds) {
      const m = moment(_.get(authUser, field.name).toDate());
      return (
        <>
          {m.format('DD[/]MM[/]YYYY')}
          <EditIcon
            className="edit-icon"
            onClick={() => setEditing({ ...editing, [field.name]: true })}
          />
        </>
      );
    }
    return (
      <>
        {_.get(authUser, field.name) ? _.get(authUser, field.name) : ''}
        <EditIcon
          className="edit-icon"
          onClick={() => setEditing({ ...editing, [field.name]: true })}
        />
      </>
    );
  };

  // Renderizar componente Loading en caso de que no haya información
  if (loading) return <Loading />;

  // Renderizar componente
  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={{
        ...authUser,
        birthday: authUser.birthday
          ? moment(authUser.birthday.toDate())
          : moment().year(1990),
        'lastApplicationInfo.rut': authUser.lastApplicationInfo
          ? authUser.lastApplicationInfo.rut
          : '',
        'lastApplicationInfo.comuna': authUser.lastApplicationInfo
          ? authUser.lastApplicationInfo.comuna
          : '',
        'lastApplicationInfo.region': authUser.lastApplicationInfo
          ? authUser.lastApplicationInfo.region
          : '',
      }}
    >
      <Descriptions
        layout="vertical"
        className="personal-info-descriptions"
        column={{ xs: 1, sm: 1, md: 2 }}
        colon={false}
      >
        {fields.map((f) => (
          <Descriptions label={f.label} key={f.label} className="editable-field">
            <DescriptionsField field={f} />
          </Descriptions>
        ))}
      </Descriptions>
    </Form>
  );
};

export default ProfilePersonalInfo;
