// Importar librerías
import React from 'react';

// Importar otros componentes
import { ReactComponent as LaptopIcon } from './icons/laptop.svg';
import { ReactComponent as PaperIcon } from './icons/paper.svg';
import { ReactComponent as ClockIcon } from './icons/clock.svg';
import { ReactComponent as CoinsIcon } from './icons/coins.svg';

// Definir contenido a mostrar
const content = [
  {
    icon: <LaptopIcon />,
    text: 'Sube tu información en un solo lugar',
  },
  {
    icon: <PaperIcon />,
    text: 'Simplifica el trámite',
  },
  {
    icon: <ClockIcon />,
    text: 'Reduce el tiempo de tu cotización',
  },
  {
    icon: <CoinsIcon />,
    text: 'Aumenta tu oferta',
  },
];

const WhatWeDo = () => {
  // Renderizar componente
  return (
    <div id="what-we-do-container">
      <div className="section-content">
        <p id="what-we-do-title">Lo que hacemos</p>
        <div id="what-we-do-box">
          {content.map((c, index) => (
            <div className="what-we-do-item" key={index}>
              {c.icon}
              <p>{c.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
