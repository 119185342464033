// Importar librerías
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Select,
  InputNumber,
  Radio,
  Row,
  Slider,
  Tooltip,
  Input,
  message,
} from 'antd';
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import _ from 'lodash';

// Importar otros componentes
import { ReactComponent as BancoBiceIcon } from './icons/bancobice.svg';
import { ReactComponent as BCIIcon } from './icons/bci.svg';
import { ReactComponent as BancoConsorcioIcon } from './icons/bancoconsorcio.svg';
import { ReactComponent as BancoChileIcon } from './icons/bancochile.svg';
import { ReactComponent as BancoEstadoIcon } from './icons/bancoestado.svg';
import { ReactComponent as BancoFalabellaIcon } from './icons/bancofalabella.svg';
import { ReactComponent as BancoInternacionalIcon } from './icons/bancointernacional.svg';
import { ReactComponent as BancoSecurityIcon } from './icons/bancosecurity.svg';
import { ReactComponent as CooperativaAhorroCreditoIcon } from './icons/cooperativaahorroycredito.svg';
import { ReactComponent as ItauIcon } from './icons/itau.svg';
import { ReactComponent as SantanderIcon } from './icons/santander.svg';
import { ReactComponent as ScotiabankIcon } from './icons/scotiabank.svg';
import { ReactComponent as OtroIcon } from './icons/otro.svg';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar constantes
import banks from '../../constants/banks';

// Importar context de user
import { AuthUserContext } from '../../context';

// Definir información del ícono de un sólo pago
const iconMessage =
  'Este es un crédito donde el monto prestado y los intereses mensuales se pagan todo juntos al final del periodo';

// Definir arreglo con las opciones del Checkbox para el manejo de los seguros
export const segurosOptions = [
  { value: 'desgravamen', label: 'Desgravamen' },
  { value: 'cesantia', label: 'Cesantía' },
  { value: 'vida', label: 'Vida + Hospitalización' },
];

// Definir arreglo con las opciones del Radio para las condiciones de pago del crédito
const paymentConditionOptions = [
  { value: 'cuotas', label: 'Cuotas' },
  { value: 'pago unico', label: 'Un Solo Pago' },
];

// Importar subcomponente Option
const { Option } = Select;

// Obtener información de la sesión
const { sessionStorage } = window;

const CreditInfoForm = (props) => {
  // Definir información del context
  const authUser = useContext(AuthUserContext);

  /// Aplicar destructuración
  const { formInstance, style, sizeWindow } = props;

  // Definir state local
  const [paymentOption, setPaymentOption] = useState('cuotas');
  const [bankSmallNameFlit, setBankSmallNameFlit] = useState(null);
  const [bankBigNameFlit, setBankBigNameFlit] = useState(null);

  // Definir effect para setear los valores del formulario
  useEffect(() => {
    // Definir objeto de valores iniciales
    let values = {};

    // Definir arreglo de campos del formulario
    const fields = ['banco', 'seguros', 'plazo', 'bestAnswerParam'];

    // Setear valores en caso de que exista el campo lastApplicationInfo
    if (authUser && authUser.lastApplicationInfo) {
      // Obtener objeto
      const { lastApplicationInfo } = authUser;

      // Setear valores según sea el caso
      fields.forEach((f) => {
        const item = lastApplicationInfo[f];
        if (item && !_.isNaN(item)) {
          if (Number(item)) {
            values[f] = Number(item);
          }
        }
      });
    }

    // Setear valores en caso de que haya información en la variable sessionStorage
    fields.forEach((f) => {
      const item = sessionStorage.getItem(f);
      if (item && !_.isNaN(item)) {
        if (Number(item)) {
          values[f] = Number(item);
        }
      }
    });

    // Fijar Plazo en caso de que no esté definido
    if (!values.plazo) values.plazo = 12;

    // Colocar valores en formulario
    formInstance.setFieldsValue(values);
  }, [formInstance, authUser]);

  // Definir componente a renderizar
  const CardBanksComponent = () => {
    return (
      <div className="container-card-bank">
        <div className="card-bank">
          <BancoBiceIcon />
          <Radio value="Banco BICE" />
        </div>
        <div className="card-bank">
          <BCIIcon />
          <Radio value="BCI" />
        </div>
        <div className="card-bank">
          <BancoConsorcioIcon />
          <Radio value="Banco Consorcio" />
        </div>
        <div className="card-bank">
          <BancoChileIcon />
          <Radio value="Banco de Chile" />
        </div>
        <div className="card-bank">
          <BancoEstadoIcon />
          <Radio value="Banco Estado" />
        </div>
        <div className="card-bank">
          <BancoFalabellaIcon />
          <Radio value="Banco Falabella" />
        </div>
        <div className="card-bank">
          <BancoInternacionalIcon />
          <Radio value="Banco Internacional" />
        </div>
        <div className="card-bank">
          <BancoSecurityIcon />
          <Radio value="Banco Security" />
        </div>
        <div className="card-bank">
          <CooperativaAhorroCreditoIcon />
          <Radio value="Cooperativa de Ahorro y Crédito" />
        </div>
        <div className="card-bank">
          <ItauIcon />
          <Radio value="Itaú" />
        </div>
        <div className="card-bank">
          <SantanderIcon />
          <Radio value="Santander" />
        </div>
        <div className="card-bank">
          <ScotiabankIcon />
          <Radio value="Scotiabank" />
        </div>
        <div className="card-bank">
          <OtroIcon />
          <p>
            <b>Otro Banco</b>
          </p>
          <Radio value="Otro" />
        </div>
      </div>
    );
  };

  /**
   *
   * @param {*} errorFields
   * Muestra un mensaje de error en caso de que haya habido un problema con la información
   * del formulario.
   */
  const onFinishFailed = (errorFields) => {
    console.log(errorFields);
    message.info('Existen campos del formulario que aún no han sido completados');
  };

  // Renderizar componente
  return (
    <Form
      name="creedit-status-form"
      id="credit-status-form"
      initialValues={{ banco: 'Banco BICE' }}
      onFinishFailed={onFinishFailed}
      form={formInstance}
      layout="vertical"
      hideRequiredMark
      style={style}
    >
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="nombre"
            label="Nombre de la Cotización"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Coloca el nombre de la cotización',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Form.Item
            name="credito"
            label="Monto Vigente del Crédito (CLP)"
            type="number"
            hasFeedback
            rules={[
              {
                message: 'Ingresa el monto de tu crédito',
                required: true,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999999999999}
              step={1000000}
              style={{ width: '100%' }}
              precision={0}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              onClick={(e) => e.target.select()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={(0, 12)}>
        {sizeWindow < 768 && (
          <>
            <Col span={24}>
              <Form.Item
                name="banco"
                label="Banco Actual del Crédito"
                hasFeedback
                rules={[
                  {
                    message: 'Selecciona el banco actual del crédito',
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Selecciona"
                  onSelect={(value) => setBankSmallNameFlit(value)}
                >
                  {banks.map((bank) => (
                    <Option key={bank} value={bank}>
                      {bank}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {bankSmallNameFlit === 'Otro' && (
              <Col span={24}>
                <Form.Item
                  name="otroBanco"
                  label="Nombre del Banco"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Coloca el nombre del banco',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </>
        )}

        {sizeWindow >= 768 && (
          <>
            <Col span={24}>
              <Form.Item name="banco" label="Banco Actual del Crédito">
                <Radio.Group onChange={(e) => setBankBigNameFlit(e.target.value)}>
                  <CardBanksComponent />
                </Radio.Group>
              </Form.Item>
            </Col>
            {bankBigNameFlit === 'Otro' && (
              <Col span={24}>
                <Form.Item
                  name="otroBanco"
                  label="Nombre del Banco"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Coloca el nombre del banco',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>
      <Row gutter={(0, 12)}>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Row>
            <Col span={24} style={sizeWindow < 768 ? null : { marginTop: '20px' }}>
              <Form.Item
                name="paymentCondition"
                label={'¿Cómo te gustaría pagar tu crédito?'}
                rules={[
                  {
                    required: true,
                    message: 'Selecciona el parametro',
                  },
                ]}
                style={{ alignItems: 'center' }}
                initialValue={'cuotas'}
              >
                <Radio.Group
                  options={paymentConditionOptions}
                  onChange={(e) => setPaymentOption(e.target.value)}
                />
              </Form.Item>
            </Col>
            {paymentOption === 'pago unico' && (
              <Col
                span={24}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Form.Item>
                  <Tooltip
                    placement="top"
                    title={iconMessage}
                    style={{ width: sizeWindow < 768 ? '50px' : '70px' }}
                    color="#e81687"
                  >
                    <ExclamationCircleOutlined className="info-icon-style" />
                  </Tooltip>
                </Form.Item>
              </Col>
            )}
            <Col span={24} style={sizeWindow < 768 ? { marginTop: '10px' } : null}>
              {paymentOption === 'cuotas' && (
                <Form.Item
                  name="fechaPrimerPago"
                  label="Fecha Primer Pago"
                  type="date"
                  hidden={paymentOption !== 'cuotas'}
                  hasFeedback
                  rules={[
                    {
                      message: 'Ingresa la fecha de tu primer pago',
                      required: true,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} locale={locale} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={sizeWindow < 768 ? 24 : 12}>
          <Row style={sizeWindow < 768 ? { marginTop: '15px' } : null}>
            <Col span={24}>
              <Form.Item name="plazo" label="Plazo (meses)">
                <Slider
                  marks={{
                    1: '1 mes',
                    30: '30 meses',
                    60: '60 meses',
                  }}
                  min={1}
                  max={60}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginTop: '10px' }}>
              <Form.Item
                name="seguros"
                label="Seguros *"
                help="* No es obligatorio elegir seguros en los créditos de consumo"
                initialValue={[]}
              >
                <Checkbox.Group options={segurosOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Col>
          <Button className="application-form-button" htmlType="submit">
            Siguiente
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditInfoForm;
