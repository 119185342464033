// Importar librerías
import React, { useState } from 'react';
import { Form, InputNumber, Slider } from 'antd';
import Bounce from 'react-reveal/Bounce';

// Importar utilidades
import toPesos from '../../utils/toPesos';

// Importar otros componentes
import { ReactComponent as Consume1 } from './icons/consumo1.svg';
import { ReactComponent as Consume2 } from './icons/consumo2.svg';
import { ReactComponent as Consume3 } from './icons/consumo3.svg';
import { ReactComponent as Consume4 } from './icons/consumo4.svg';

// Inicializar valores
const initialValues = {
  valorCredito: 2000000,
  mortageDuration: 12,
  anualInterest: 0.05,
};

/**
 *
 * @param {*} rate
 * @param {*} nper
 * @param {*} pv
 * Actualiza el valor de la barra de tiempo según el usuario vaya ajustando el valor.
 */
const pmt = (rate, nper, pv) => {
  let arriba = pv * rate;
  let abajo = 1 - Math.pow(1 / (1 + rate), nper);
  if (rate === 0) {
    arriba = pv;
    abajo = nper;
  }
  return isNaN(arriba / abajo) ? '-' : arriba / abajo;
};

const MortageCalculator = ({ shadow = false }) => {
  // Definir state
  const [valorCredito, setValorCredito] = useState(initialValues.valorCredito);

  const [mortageDuration, setMortageDuration] = useState(
    initialValues.mortageDuration
  );

  const [anualInterest, setAnualInterest] = useState(
    initialValues.anualInterest * 100
  );
  const [anualInterestFocus, setAnualInterestFocus] = useState(false);

  // Obtener otros valores a mostrar en el simulador de consumo
  const monthlyInterest = Math.pow(1 + anualInterest * 0.01, 1 / 12) - 1;

  const monthlyRate = pmt(monthlyInterest, mortageDuration, valorCredito);

  const consume1Display = valorCredito < 1000000 ? true : false;
  const consume2Display =
    valorCredito >= 1000000 && valorCredito < 5000000 ? true : false;
  const consume3Display =
    valorCredito >= 5000000 && valorCredito < 10000000 ? true : false;
  const consume4Display = valorCredito >= 10000000 ? true : false;

  // Renderizar componente
  return (
    <div id="calculator-container" className={shadow ? 'with-shadow' : ''}>
      <div id="calculator-center-form">
        <p className="calculator-title">Crédito de Consumo</p>
        <div id="calculator-house-wrapper">
          <Bounce right opposite when={consume1Display}>
            <Consume1 />
          </Bounce>
          <Bounce right opposite when={consume2Display}>
            <Consume2 />
          </Bounce>
          <Bounce right opposite when={consume3Display}>
            <Consume3 />
          </Bounce>
          <Bounce right opposite when={consume4Display}>
            <Consume4 />
          </Bounce>
        </div>
        <div id="calculator-slider-legends">
          <p>CLP 100.000</p>
          <p id="current-slider-value">CLP {toPesos(valorCredito)}</p>
          <p>CLP 30.000.000</p>
        </div>
        <Slider
          className={'antdslider'}
          min={100000}
          max={30000000}
          onChange={setValorCredito}
          value={valorCredito}
          step={100}
          tipFormatter={null}
        />
        <div id="calculator-slider-legends">
          <p>1 mes</p>
          <p id="current-slider-value">{mortageDuration} meses</p>
          <p>60 meses</p>
        </div>
        <Slider
          className={'antdslider'}
          min={1}
          max={60}
          onChange={setMortageDuration}
          value={mortageDuration}
          step={1}
          tipFormatter={null}
        />
      </div>
      <div id="calculator-left-form" className="consume-calculator">
        <Form>
          <Form.Item>
            <p>Monto</p>
            <InputNumber
              min={100000}
              max={30000000}
              step={10000}
              value={valorCredito ? valorCredito.toFixed() : 0}
              onChange={setValorCredito}
              formatter={(value) => toPesos(value)}
              parser={(value) => value.replace(/([^0-9])/g, '') || 0}
              size="small"
              onClick={(e) => e.target.select()}
            />
          </Form.Item>

          <Form.Item>
            <p>Tasa de interés</p>
            <InputNumber
              min={0}
              max={99}
              precision={2}
              value={anualInterest || 0}
              formatter={(value) =>
                anualInterestFocus ? value : `${toPesos(value, 2)}%`
              }
              parser={(value) =>
                value.replace(',', '.').replace(/^(0.)|([^0-9.])/, '')
              }
              onChange={setAnualInterest}
              onFocus={() => setAnualInterestFocus(true)}
              onBlur={() => setAnualInterestFocus(false)}
              size="small"
              onClick={(e) => e.target.select()}
            />
          </Form.Item>

          <p id="dividendo-label" className="gray">
            MONTO TOTAL
          </p>
          <p className="result gray">CLP {toPesos(monthlyRate * mortageDuration)}</p>
          <p id="dividendo-label">CUOTA MENSUAL</p>
          <p className="result">CLP {toPesos(monthlyRate)}*</p>
          <p>*Valor estimado, no incluye seguros.</p>
        </Form>
      </div>
    </div>
  );
};

export default MortageCalculator;
