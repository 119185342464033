import _ from 'lodash';

const toPesos = (numberValue, decimals = 0) => {
  // Return number formated in pesos decimal
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('de-DE');

  if (numberValue === '-') return '-';
  if (!numberValue) return 0;
  return formatter.format(_.round(numberValue, decimals));
};

export default toPesos;
