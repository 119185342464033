export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PROFILE = '/perfil';
export const PROFILE_HOME = '/perfil/home';
export const PROFILE_INFO = '/perfil/info';
export const PROFILE_APPLICATIONS = '/perfil/cotizaciones';
export const PROFILE_APPLICATION_FORM_HIP = '/perfil/crear-cotizacion-hipotecaria';
export const PROFILE_APPLICATION_FORM_CON = '/perfil/crear-cotizacion-consumo';
export const PROFILE_APPLICATION_FORM_POR_HIP =
  '/perfil/crear-cotizacion-portabilidad-hipotecaria';
export const PROFILE_APPLICATION_FORM_POR_CON =
  '/perfil/crear-cotizacion-portabilidad-consumo';
export const PROFILE_APPLICATION_FORM_AUTO_CREDIT =
  '/perfil/crear-cotizacion-automotriz';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const USERS_PAGE = '/users';
export const USER_PAGE = '/users/:id';
export const ARTICLES_PAGE = '/noticias';
export const ARTICLE_PAGE = '/noticias/:id';
export const FINANCIAL_EDUCATION_PAGE = '/educacion/:id';
export const TERMS = '/TerminosYCondiciones.pdf';
