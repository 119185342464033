// Importar librerías
import React, { useContext, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

// Importar context
import { FirebaseContext } from './../../context';

// Definir objeto de meses
const monthNumbers = {
  Enero: 'Ene',
  Febrero: 'Feb',
  Marzo: 'Mar',
  Abril: 'Abr',
  Mayo: 'May',
  Junio: 'Jun',
  Julio: 'Jul',
  Agosto: 'Ago',
  Septiembre: 'Sep',
  Octubre: 'Oct',
  Noviembre: 'Nov',
  Diciembre: 'Dic',
};

const IndicatorsCharts = () => {
  // Obtener context de firebase
  const firebase = useContext(FirebaseContext);

  // Definir state
  const [dataChartRate, setDataChartRate] = useState(null);

  // Obtener información a graficar
  const [interestRateData, loading, error] = useCollection(
    firebase.db.collection('interestRate').orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Aplicar effect para manejar la información de la cosulta
  // una vez se hayan obtenido los resultados
  useEffect(() => {
    if (interestRateData) {
      // Definir arreglo de datos a graficar
      let rateMonthlyData = [],
        data = null;
      interestRateData.docs.forEach((interestData) => {
        if (rateMonthlyData.length < 24) {
          // Obtener mes, año y semana
          const month = monthNumbers[interestData.get('month')];
          const year = interestData.get('year');
          const week = interestData.get('week');

          // Buscar objeto dentro del arreglo
          const filteredItem = rateMonthlyData.find(
            (item) => item.month === month && item.year === year
          );

          // Modificar último valor almacenado
          if (filteredItem) {
            rateMonthlyData[0].month = '';
            rateMonthlyData[0].year = '';
          }

          // Definir data y agregarla al arreglo correspondiente
          data = {
            consumeRate: interestData.get('consumeRate'),
            mortgageRate: interestData.get('mortgageRate'),
            date: week + ' ' + interestData.get('month'),
            month,
            year,
          };
          rateMonthlyData.unshift(data);
        }
      });

      // Setear valor
      setDataChartRate(rateMonthlyData);
    }
  }, [interestRateData]);

  // Definir Tooltip a rendrerizar
  const CustomTooltip = ({ active, payload, color, type }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label" style={{ color: `${color}` }}>
            {payload[0].payload.date}
          </p>
          <p className="intro" style={{ color: `${color}` }}>
            Tasa Promedio:{' '}
            {type === 'consume'
              ? payload[0].payload.consumeRate
              : payload[0].payload.mortgageRate}
            %
          </p>
        </div>
      );
    }
    return null;
  };

  // Renderizar componente
  return (
    <div id="actualidad-charts-container">
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Collection: Loading...</span>}
      {dataChartRate && (
        <>
          <LineChart
            data={dataChartRate}
            height={350}
            margin={{
              top: 7,
              right: 7,
              bottom: 10,
            }}
            width={478}
            fontWeight="bold"
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                angle: -90,
                position: 'insideLeft',
                fontWeight: 'bold',
              }}
            />
            <Tooltip
              content={<CustomTooltip color={'#027aff'} type={'consume'} />}
            />
            <Legend wrapperStyle={{ bottom: 0, fontWeight: 'bold' }} />
            <Line
              dataKey="consumeRate"
              fill="#027aff"
              name="Tasa Promedio Anual C. Consumo"
              activeDot={{ r: 8 }}
              fontWeight="bold"
              stroke="#027aff"
              strokeWidth="2"
            />
          </LineChart>
          <LineChart
            data={dataChartRate}
            height={350}
            margin={{
              top: 7,
              right: 7,
              bottom: 10,
            }}
            width={478}
            fontWeight="bold"
          >
            <XAxis dataKey="month" />
            <YAxis
              label={{
                angle: -90,
                position: 'insideLeft',
                fontWeight: 'bold',
              }}
            />
            <Tooltip
              content={<CustomTooltip color={'#E81687'} type={'mortgage'} />}
            />
            <Legend wrapperStyle={{ bottom: 0, fontWeight: 'bold' }} />
            <Line
              dataKey="mortgageRate"
              fill="#E81687"
              name="Tasa Promedio Anual C. Hipotecario"
              activeDot={{ r: 8 }}
              fontWeight="bold"
              stroke="#E81687"
              strokeWidth="2"
            />
          </LineChart>
        </>
      )}
    </div>
  );
};

export default IndicatorsCharts;
