// Importar librerías
import React from 'react';

// Importar otros componentes
import { ReactComponent as CorfoLogo } from './icons/corfo-logo.svg';
import { ReactComponent as VeneturesLogo } from './icons/ventures-logo.svg';

const Sponsors = () => {
  // Renderizar
  return (
    <div id="sponsors-section">
      <CorfoLogo />
      <VeneturesLogo className="veneture-logo" />
    </div>
  );
};

export default Sponsors;
