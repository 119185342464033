// *** Article API ***

class articleLogic {
  constructor(db, fieldValue) {
    this.db = db;
    this.fieldValue = fieldValue;
  }

  article = (uid) => this.db.doc(`articles/${uid}`);

  articles = () => this.db.collection('articles');
}

export default articleLogic;
